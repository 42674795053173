import React from 'react';
import ContentPage from './PracticeScreenComponent';
import { useNavigation } from "@react-navigation/native";

const FeelingsScreenOne = () => {
  const navigation = useNavigation();
  const headerText = "Talking about feelings";
  const paragraphs = [
    "When we talk about our feelings, they become less scary and overwhelming.",
    "Talking with your child about feelings can help your child know they are not alone, and that you experience the same emotions!",
    "Find opportunities to notice and talk about feelings – yours, your child’s, friends or family members', or characters from books and TV shows!",
    "Don’t be afraid to talk about feeling sad or mad – it's important to talk about all feelings! Frustration, sadness, happiness, excitement, fear, anger – all are important.",
    "Use this time to tell your child you will love and care for them no matter what they're feeling. It's important for children to feel loved even when they make mistakes or have hard feelings.",
    "Here are some resources to guide you in talking to your child about feelings!"
  ];
  const buttons = {
    "Modeling + Labeling Feelings": "selfCareLink",
    "Validating Feelings": "hotButtonsLink",
  };
  const onPracticeButtonPress = () => {
    navigation.navigate("FeelingsScreenTwo")
    // Navigate to the next screen or perform an action
    console.log("Practice button pressed");
  };

  return (
    <ContentPage
      headerText={headerText}
      paragraphs={paragraphs}
      buttons={buttons}
      onPracticeButtonPress={onPracticeButtonPress}
    />
  );
};

export default FeelingsScreenOne;