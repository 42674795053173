// import React, { useState, useEffect, useContext } from "react";
// import {
//   View,
//   Text,
//   StyleSheet,
//   ScrollView,
//   TouchableOpacity,
//   ActivityIndicator, 
//   Dimensions, StatusBar
// } from "react-native";
// import { SafeAreaView } from 'react-native-safe-area-context';
// import { AppContext } from "../../App";
// import { Ionicons } from "@expo/vector-icons";
// import TopNav from '../components/TopNav'; 
// import BottomNav from '../components/BottomNav';

// const { width } = Dimensions.get("window"); // Get screen width

// const { height: screenHeight } = Dimensions.get("window") //Get height of the screen

// // Sample data for BehaviorSupportPlan component
// const behaviorSupportPlanData = {
//     "success": true,
//     "result": {
//       "childId": "6593bbc265e63b8aec72872c",
//       "behavioralSupportPlanId": "706617af-bc47-49e4-8258-26745a18892a",
//       "childName": "Annie Leannhart",
//       "behaviorDescription": "My toddler constantly hits me. How can I get him to understand that isn't okay?",
//       "sections": [
//         {
//           "title": "WHAT HAPPENED BEFORE THE INCIDENT",
//           "description": "The child had to wait for something (activity or object)",
//           "solutions": [
//             {
//               "title": "Give Choices",
//               "resources": [
//                 {
//                   "type": "video",
//                   "url": "https://drive.google.com/file/d/1JITdiA6jMruRLSvFETPUtf6_QfirmerqW/view"
//                 },
//                 {
//                   "type": "illustration",
//                   "url": "https://drive.google.com/file/d/1xWtW8Jl5iuDULIeirA9j/view"
//                 }
//               ]
//             },
//             {
//               "title": "Transition warnings",
//               "resources": [
//                 {
//                   "type": "video",
//                   "url": "https://drive.google.com/file/d/1YOB8xBVQh_wZQ6jme8DOdUj3IbPJrtzQ/view"
//                 },
//                 {
//                   "type": "illustration",
//                   "url": "https://drive.google.com/file/d/1JJKzu5IMxxL0CffKP7fF6-utyB1rBoY4/view"
//                 }
//               ]
//             }
//           ]
//         },
//         {
//           "title": "WHAT WAS THE BEHAVIOR",
//           "description": "My toddler constantly hits me. How can I get him to understand that isn't okay?",
//           "solutions": [
//             {
//               "title": "Ask to be done",
//               "active": true,
//               "resources": [
//                 {
//                   "type": "video",
//                   "url": "https://drive.google.com/file/d/1PUVN_wDVK_5wHAipYEPJrnp4qik8_2nj/view"
//                 },
//                 {
//                   "type": "illustration",
//                   "url": "https://drive.google.com/file/d/1JWZl-E4zHUK76P/view"
//                 }
//               ]
//             },
//             {
//               "title": "Ask for something",
//               "active": false,
//               "resources": []
//             }
//           ]
//         },
//         {
//           "title": "WHAT HAPPENED AFTER THE BEHAVIOR",
//           "description": "The child was given attention for the behavior",
//           "solutions": [
//             {
//               "title": "Redirect attention",
//               "active": true,
//               "resources": [
//                 {
//                   "type": "video",
//                   "url": "https://drive.google.com/file/d/1PUVN_wDVK_5wHAipYEPJrnp4qik8_2nj/view"
//                 },
//                 {
//                   "type": "illustration",
//                   "url": "https://drive.google.com/file/d/1JWZl-E4zHUK76P/view"
//                 }
//               ]
//             },
//             {
//               "title": "Provide positive reinforcement",
//               "active": true,
//               "resources": [
//                 {
//                   "type": "video",
//                   "url": "https://drive.google.com/file/d/1uP1mEoVu7mnL5FU7j1FKENrbQWAe3rMK/view"
//                 },
//                 {
//                   "type": "illustration",
//                   "url": "https://drive.google.com/file/d/1bkmKTOl4qb4S1QNnVfNZDgwt_vfX6g5l/view"
//                 }
//               ]
//             }
//           ]
//         }
//       ],
//       "plans": [
//         {
//           "behavior": "Hurting others",
//           "strategies": [
//             {
//               "strategy": "Teach Strategy",
//               "resources": [
//                 "https://drive.google.com/file/d/1JITdiA6jMruRLSvFETPUtf6_QfirmerqW/view",
//                 "https://drive.google.com/file/d/1xWtW8Jl5iuDULIeirA9j/view",
//                 "https://drive.google.com/file/d/1YOB8xBVQh_wZQ6jme8DOdUj3IbPJrtzQ/view",
//                 "https://drive.google.com/file/d/1JJKzu5IMxxL0CffKP7fF6-utyB1rBoY4/view"
//               ]
//             },
//             {
//               "strategy": "Prevent Strategy",
//               "resources": [
//                 "https://drive.google.com/file/d/1PUVN_wDVK_5wHAipYEPJrnp4qik8_2nj/view",
//                 "https://drive.google.com/file/d/1JWZl-E4zHUK76P/view",
//                 "https://drive.google.com/file/d/1uP1mEoVu7mnL5FU7j1FKENrbQWAe3rMK/view",
//                 "https://drive.google.com/file/d/1bkmKTOl4qb4S1QNnVfNZDgwt_vfX6g5l/view"
//               ]
//             }
//           ]
//         }
//       ]
//     }
//   };
  
//   // Define color schemes for alternating sections
//   const colorSchemes = [
//     {
//       // Orange/Peach scheme
//       borderColor: "#F0E0B2",
//       strategyBg: "#FFF9E6",
//       buttonBg: "#F8B195",
//       buttonText: "#FFFFFF",
//       buttonBorder: "#F8B195",
//       buttonTextOutline: "#F8B195"
//     },
//     {
//       // Blue scheme
//       borderColor: "#B2D0F0",
//       strategyBg: "#E6F0FF",
//       buttonBg: "#95B1F8",
//       buttonText: "#FFFFFF",
//       buttonBorder: "#95B1F8",
//       buttonTextOutline: "#95B1F8"
//     },
//     {
//       // Green scheme
//       borderColor: "#B2E0C2",
//       strategyBg: "#E6F9EC",
//       buttonBg: "#7DCCA3",
//       buttonText: "#FFFFFF",
//       buttonBorder: "#7DCCA3",
//       buttonTextOutline: "#7DCCA3"
//     },
//     {
//       // Purple scheme
//       borderColor: "#D0B2E0",
//       strategyBg: "#F2E6F9",
//       buttonBg: "#B995E0",
//       buttonText: "#FFFFFF",
//       buttonBorder: "#B995E0",
//       buttonTextOutline: "#B995E0"
//     }
//   ];
  
//   const BehaviorSupportPlan = ({ navigation }) => {
//     const {currentChild} = useContext(AppContext)
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [childBehaviors, setchildBehaviors] = useState([]);
//     const [childAntecendents, setchildAntecendents] = useState([]);
//     const [childConsequences, setchildConsequences] = useState([]);
//     const [planData, setPlanData] = useState(null);
//     const [createdPlan, setCreatedPlan] = useState(null)
//     // const { child } = useContext(AppContext);
//     const [statusBarHeight, setStatusBarHeight] = useState(0);
//     const [height, setHeight] = useState(100);
  
//     useEffect(() => {
//       // Subtract 20% from the screen height
//       StatusBar.currentHeight && setStatusBarHeight(StatusBar.currentHeight);
//       setHeight(screenHeight);
//     }, []);
  
//     useEffect(() => {
//       // For development, use the sample data instead of fetching from API
//       setTimeout(() => {
//         setPlanData(behaviorSupportPlanData.result);
//         setLoading(false);
//       }, 500); // Simulate loading delay
//     }, []);

//     useEffect(() => {
//         if (currentChild && currentChild.value) {
//             console.log('child is one : ' + currentChild.value + " " + currentChild.label);
//             fetchchildBehaviors(currentChild.value);
//             }
//     }, [currentChild]);
  
//     const createBehaviorSupportPlan = async (childId, behaviors) => {
//         try {
//           setLoading(true);
//           setError(null);
      
//           const response = await fetch(
//             "https://api.childbehaviorcheck.com/api/behavioralSupportPlan",
//             {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/json",
//                 // Add any additional headers if needed
//                 // Authorization: `Bearer ${token}`, // Uncomment if authentication is required
//               },
//               body: JSON.stringify({
//                 child_id: childId,
//                 behaviors: behaviors
//               })
//             }
//           );
      
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }
      
//           const data = await response.json();
//           console.log("BSP created successfully:", data);
      
//           if (data.success) {
//             const createdPlan = data.result; // Contains the full response including the ID
//             console.log("Created BSP details:", createdPlan);
//             return createdPlan; // Return the created plan for further processing
//           } else {
//             throw new Error("Failed to create BSP: " + (data.message || "Unknown error"));
//           }
//         } catch (error) {
//           console.error("Error creating behavior support plan:", error);
//           setError("Failed to create behavior support plan. Please try again later.");
//           throw error; // Re-throw the error if you want calling code to handle it
//         } finally {
//           setLoading(false);
//         }
//       };

//     // const handleCreatePlan = async () => {
//     //     try {
//     //     const childId = "6593bbc265e63b8aec72872c";
//     //     const behaviors = behaviors
        
//     //     const createdPlan = await createBehaviorSupportPlan(childId, behaviors);
        
//     //     // Do something with the created plan
//     //     console.log("Plan created:", createdPlan);
//     //     setCreatedPlan(createdPlan); 
//     //     } catch (error) {
//     //     // Handle error
//     //     }
//     // };

//     const fetchchildBehaviors = async (childId) => {
//         try {
//           setLoading(true);
//           setError(null);
      
//           const response = await fetch(
//             `https://api.childbehaviorcheck.com/api/child-behaviors/${childId}`,
//             {
//               method: "GET",
//               headers: {
//                 "Content-Type": "application/json",
//                 // Add any additional headers if needed
//                 // childid: child, // Uncomment if you need to send child ID in headers
//               },
//             }
//           );
      
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }
      
//           const data = await response.json();
//           console.log("child behaviors response:", data);
          
//           if (data.success && data.behaviors) {
//             setchildBehaviors(data.behaviors); // Save behaviors to state
//             setchildAntecendents(data.antecedents);
//             setchildConsequences(data.consequences);
//             const createdPlan = await createBehaviorSupportPlan(childId, data.behaviors);
        
//             // Do something with the created plan
//             console.log("Plan created:", createdPlan);
//             setCreatedPlan(createdPlan); 
//           } else {
//             throw new Error("Invalid response format");
//           }
//         } catch (error) {
//           console.error("Error fetching child behaviors:", error);
//           setError("Failed to load child behaviors. Please try again later.");
//           setchildBehaviors([]); // Reset to empty array on error
//         } finally {
//           setLoading(false);
//         }
//       };
  
//     const handleWatchVideo = (url) => {
//       // Handle opening video URL
//       console.log("Opening video URL:", url);
//       // You might want to navigate to a video player screen or open a web view
//     };
  
//     const handleSeeIllustration = (url) => {
//       // Handle opening illustration URL
//       console.log("Opening illustration URL:", url);
//       // You might want to navigate to an image viewer screen
//     };
  
//     // Render a solution with the appropriate color scheme
//     const renderSolution = (solution, colorScheme, index) => {
//       const hasResources = solution.resources && solution.resources.length > 0;
      
//       return (
//         <View 
//           key={`solution-${index}`} 
//           style={[
//             styles.strategyContainer,
//             { 
//               backgroundColor: colorScheme.strategyBg,
//               borderColor: colorScheme.borderColor
//             }
//           ]}
//         >
//           <Text style={styles.strategyTitle}>{solution.title}</Text>
          
//           {hasResources && (
//             <View style={styles.actionButtonsContainer}>
//               <TouchableOpacity 
//                 style={[
//                   styles.watchVideoButton,
//                   { backgroundColor: colorScheme.buttonBg }
//                 ]}
//                 onPress={() => handleWatchVideo(solution.resources[0].url)}
//               >
//                 <Text style={styles.watchVideoButtonText}>Watch video</Text>
//               </TouchableOpacity>
              
//               <TouchableOpacity 
//                 style={[
//                   styles.seeIllustrationButton,
//                   { borderColor: colorScheme.buttonBorder }
//                 ]}
//                 onPress={() => handleSeeIllustration(solution.resources[1]?.url)}
//               >
//                 <Text style={{ color: colorScheme.buttonTextOutline, fontWeight: "500" }}>
//                   See illustration
//                 </Text>
//               </TouchableOpacity>
//             </View>
//           )}
//         </View>
//       );
//     };
  
//     // Render a section with the appropriate color scheme
//     const renderSection = (section, index) => {
//       const colorScheme = colorSchemes[index % colorSchemes.length];
      
//       return (
//         <View 
//           key={`section-${index}`} 
//           style={[
//             styles.sectionContainer,
//             { borderColor: colorScheme.borderColor }
//           ]}
//         >
//           <Text style={styles.sectionTitle}>{section.title}</Text>
//           <View 
//             style={[
//               styles.incidentBox,
//               { borderColor: colorScheme.borderColor }
//             ]}
//           >
//             <Text style={styles.incidentText}>{section.description}</Text>
//           </View>
          
//           <Text style={styles.solutionLabel}>What are some solutions you can use?</Text>
          
//           {section.solutions.map((solution, solutionIndex) => 
//             renderSolution(solution, colorScheme, solutionIndex)
//           )}
//         </View>
//       );
//     };
  
//     if (loading) {
//       return (
//         <View style={styles.loadingContainer}>
//           <ActivityIndicator size="large" color="#27AFDE" />
//           <Text style={styles.loadingText}>Loading behavior support plan...</Text>
//         </View>
//       );
//     }
  
//     if (error) {
//       return (
//         <View style={styles.errorContainer}>
//           <Text style={styles.errorText}>{error}</Text>
//           <TouchableOpacity style={styles.retryButton} onPress={fetchchildBehaviors(currentChild.value)}>
//             <Text style={styles.retryButtonText}>Retry</Text>
//           </TouchableOpacity>
//         </View>
//       );
//     }
  
//     if (!planData) {
//       return (
//         <View style={styles.errorContainer}>
//           <Text style={styles.errorText}>No behavior support plan found.</Text>
//         </View>
//       );
//     }
  
//     return (
//       <SafeAreaView style={[
//         styles.container,
//         {
//           marginTop: statusBarHeight,
//           height: height,
//         }
//       ]}>
//         <TopNav />
  
//         <ScrollView style={styles.scrollContainer}>
//           <View style={styles.childInfoContainer}>
//             <Text style={styles.childInfoLabel}>Child name</Text>
//             <Text style={styles.childInfoValue}>{currentChild.label}</Text>
            
//             <Text style={styles.childInfoLabel}>Behavior description</Text>
//             <Text style={styles.childInfoValue}>
//             {childBehaviors.length > 0 ? childBehaviors.join(', ') : 'No behaviors recorded'}
//             </Text>
//           </View>
  
//           {/* Dynamically render all sections with alternating color schemes */}
//           {planData.sections && planData.sections.map((section, index) => 
//             renderSection(section, index)
//           )}
//         </ScrollView>
  
//         {/* Bottom Navigation */}
//         <View style={{width: "100%", position: "absolute", bottom: 0}}>
//           <BottomNav navigation={navigation} />
//         </View>
//       </SafeAreaView>
//     );
//   };
  
//   const styles = StyleSheet.create({
//     container: { 
//       flex: 1, 
//       backgroundColor: "#f5f5f5",
//       position: "absolute",
//     },
//     loadingContainer: {
//       flex: 1,
//       justifyContent: "center",
//       alignItems: "center",
//     },
//     loadingText: {
//       marginTop: 10,
//       fontSize: 16,
//       color: "#666",
//     },
//     errorContainer: {
//       flex: 1,
//       justifyContent: "center",
//       alignItems: "center",
//       padding: 20,
//     },
//     errorText: {
//       fontSize: 16,
//       color: "red",
//       textAlign: "center",
//       marginBottom: 20,
//     },
//     retryButton: {
//       backgroundColor: "#27AFDE",
//       padding: 10,
//       borderRadius: 5,
//       minWidth: 100,
//       alignItems: "center",
//     },
//     retryButtonText: {
//       color: "white",
//       fontSize: 16,
//     },
//     scrollContainer: {
//       flex: 1,
//       marginBottom: 50
//     },
//     childInfoContainer: {
//       padding: 16,
//       backgroundColor: "white",
//     },
//     childInfoLabel: {
//       fontSize: 14,
//       color: "#666",
//       marginBottom: 4,
//     },
//     childInfoValue: {
//       fontSize: 16,
//       fontWeight: "500",
//       marginBottom: 16,
//     },
//     sectionContainer: {
//       marginTop: 8,
//       backgroundColor: "white",
//       borderRadius: 8,
//       borderWidth: 3,
//       marginHorizontal: 12,
//       marginBottom: 16
//     },
//     sectionTitle: {
//       fontSize: 12,
//       fontWeight: "bold",
//       color: "#666",
//       marginBottom: 10,
//       marginTop: 16,
//       marginHorizontal: 16
//     },
//     incidentBox: {
//       padding: 16,
//       borderBottomWidth: 3,
//     },
//     incidentText: {
//       fontSize: 16,
//       fontWeight: "bold",
//     },
//     solutionLabel: {
//       fontSize: 14,
//       color: "#666",
//       marginBottom: 12,
//       paddingLeft: 16,
//       marginTop: 12
//     },
//     strategyContainer: {
//       marginHorizontal: 16,
//       padding: 12,
//       borderRadius: 8,
//       borderWidth: 1,
//       marginBottom: 12,
//     },
//     strategyTitle: {
//       fontSize: 16,
//       fontWeight: "500",
//       marginBottom: 10,
//     },
//     actionButtonsContainer: {
//       flexDirection: "row",
//       justifyContent: "space-between",
//     },
//     watchVideoButton: {
//       paddingVertical: 8,
//       paddingHorizontal: 12,
//       borderRadius: 10,
//       flex: 1,
//       marginRight: 8,
//       alignItems: "center",
//     },
//     watchVideoButtonText: {
//       color: "#fff",
//       fontWeight: "500",
//     },
//     seeIllustrationButton: {
//       backgroundColor: "transparent",
//       paddingVertical: 8,
//       paddingHorizontal: 12,
//       borderRadius: 10,
//       borderWidth: 1,
//       flex: 1,
//       marginLeft: 8,
//       alignItems: "center",
//     }
//   });
  
//   export default BehaviorSupportPlan;


import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator, 
  Dimensions, 
  StatusBar,
  Modal,
  Image,
  Platform
} from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import { AppContext } from "../../App";
import TopNav from '../components/TopNav'; 
import BottomNav from '../components/BottomNav';
import { WebView } from 'react-native-webview';
import { VStack, HStack } from "native-base";
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { zoomPlugin, Zoom } from '@react-pdf-viewer/zoom';

const { width } = Dimensions.get("window");
const { height: screenHeight } = Dimensions.get("window");

// Define color schemes for alternating sections
const colorSchemes = [
  {
    borderColor: "#F0E0B2",
    strategyBg: "#FFF9E6",
    buttonBg: "#F8B195",
    buttonText: "#FFFFFF",
    buttonBorder: "#F8B195",
    buttonTextOutline: "#F8B195"
  },
  {
    borderColor: "#B2D0F0",
    strategyBg: "#E6F0FF",
    buttonBg: "#95B1F8",
    buttonText: "#FFFFFF",
    buttonBorder: "#95B1F8",
    buttonTextOutline: "#95B1F8"
  },
  {
    borderColor: "#B2E0C2",
    strategyBg: "#E6F9EC",
    buttonBg: "#7DCCA3",
    buttonText: "#FFFFFF",
    buttonBorder: "#7DCCA3",
    buttonTextOutline: "#7DCCA3"
  },
  {
    borderColor: "#D0B2E0",
    strategyBg: "#F2E6F9",
    buttonBg: "#B995E0",
    buttonText: "#FFFFFF",
    buttonBorder: "#B995E0",
    buttonTextOutline: "#B995E0"
  }
];

// Video and PDF data from library.js (simplified)
const videoData = [
  { id: "1JITdiA6jMruRLSvFETPUtf6_QfirmerqW", title: "Give Choices", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1PUVN_wDVK_5wHAjyPEJrnp4gik8_2njy" },
  { id: "1YOB8xBVQh_wZQ6jme8DOdUj3IbPJrtzQ", title: "Transition Warnings", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1bkmKTOl4qb4S1QNnVfNZDgwt_vfX6g5l" },
  { id: "1PUVN_wDVK_5wHAipYEPJrnp4qik8_2nj", title: "Ask to be Done", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1JJKzu5IMxxL0CffKP7fF6-utyB1rBoY4" },
  { id: "1uP1mEoVu7mnL5FU7j1FKENrbQWAe3rMK", title: "Provide positive reinforcement", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1EJy5oZq9VL402jsQI3JWZl-E4zHUk76P" },
  { id: "1y3QLYYowwE-lrxrrdBQQg3N_-14y0s9D", title: "Take turns", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1y3QLYYowwE-lrxrrdBQQg3N_-14y0s9D" },
  { id: "1brZHcjjeEKtQfTj8bY4axVM", title: "Play Alone", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1brZHcjjeEKtQfTj8bY4axVMmqcGq9Jam" },
  { id: "110BztfZTRhPD3DnFkPn3EZTgr7tbMhv_", title: "Verbal Reminders", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=110BztfZTRhPD3DnFkPn3EZTgr7tbMhv_" },
  { id: "19Y1ftbCwfCnslanI7AccBahWafqQMcuf", title: "Follow Directions", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1hSuxVRdLm4sm0TVm8Jj6AsK138bgUyWL" },
  { id: "1o2gWLTM7nBwPeW4mfRGW5Yj9AhjBmQ2O", title: "Choices of Demands", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1o2gWLTM7nBwPeW4mfRGW5Yj9AhjBmQ2O" },
  { id: "1zmEI6e-Y9acATaAns4V8MbDSdqbFGpvf", title: "Help When Calm", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1zmEI6e-Y9acATaAns4V8MbDSdqbFGpvf" },
  { id: "16O0Jhzcwh0jBXl6tUtBp9ZUYQWWC11_j", title: "Avoid Attending", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=16O0Jhzcwh0jBXl6tUtBp9ZUYQWWC11_j" },
  { id: "1eab5T5PImF0p2ARKRLpcORCAiIfbXPEJ", title: "Prompt Follow Through", link: "https://cbc-proxy-server.vercel.app/api/proxy/video?id=1eab5T5PImF0p2ARKRLpcORCAiIfbXPEJ" }
];

const pdfData = [
  { id: "1xWtW8Jl5iuDULIeirA9j", title: "Give Choices.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1SMBPFEuro__6vNJ8a6RvESacGoDQ1X9T" },
  { id: "1JJKzu5IMxxL0CffKP7fF6-utyB1rBoY4", title: "Transition Warnings.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=10fkyNj36nM7xeZlXJpAOPJIPLf3dMMwZ" },
  { id: "1JWZl-E4zHUK76P", title: "Ask to be Done.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1EFs2c4s3Q2D1r2kdWET0hJjZVotZcYVT" },
  { id: "1bkmKTOl4qb4S1QNnVfNZDgwt_vfX6g5l", title: "Provide positive reinforcement.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1E61yu58tdvLSGWgaBpH-jXUWrI6uPGEX" },
  { id: "1JJKzu5IMxxL0CffKP7fF6-utyB1rBoY4", title: "Take Turns.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1OeE-8jFkksiH8gEbQ8Mp4M9IKO4cG86t" },
  { id: "1brZHcjjeEKtQfTj8bY4axVM", title: "Play Alone.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1BfRn4GvqoYin0umAMxq0-6i_etT5VvfK" },
  { id: "1JJKzu5IMxxL0CffKP7fF6-utyB1rBoY4", title: "Verbal Reminder.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1u5VhR2heZ2HgRkJHiWNDTiz4ihWpTVg4" },
  { id: "1IftCwCsnlanI7Ac_cBahWafqQMCuf", title: "Follow Directions.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1_mhBfT60dXNY9b75jide5_zhsvF4jcNg" },
  { id: "1o2gWLTM7nBwPeW4mfRGW5Yj9AhjBmQ2O", title: "Choices of Demand.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1Qv3C9JmgvRbtk0YFskh9nBNcw3HP71TN" },
  { id: "1zmEI6e-Y9acATaAns4V8MbDSdqbFGpvf", title: "Help Once Calm.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=13oOjdlie9r0eoYauLMvXlRoN-KFZp4xL" },
  { id: "16O0Jhzcwh0jBXl6tUtBp9ZUYQWWC11_j", title: "Avoid Attending.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1MIe92xeqZUwAsNQzhpLa1NCjUQutTyXy" },
  { id: "1eab5T5PImF0p2ARKRLpcORCAiIfbXPEJ", title: "Prompt Follow Through.pdf", link: "https://cbc-proxy-server.vercel.app/api/proxy/pdf?id=1WBd5sptXoKUnnOei96Rr1Iz4t2ZU0jL2" }
];

const BehaviorSupportPlan = ({ navigation }) => {
  const { currentChild } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [childBehaviors, setChildBehaviors] = useState([]);
  const [childAntecedents, setChildAntecedents] = useState([]);
  const [childConsequences, setChildConsequences] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [createdPlan, setCreatedPlan] = useState(null);
  const [statusBarHeight, setStatusBarHeight] = useState(0);
  const [height, setHeight] = useState(100);
  
  // Video/PDF viewer states
  const [isVideoModalVisible, setVideoModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoTitle, setSelectedVideoTitle] = useState(null);
  const [isPdfModalVisible, setPdfModalVisible] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedPdfTitle, setSelectedPdfTitle] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  useEffect(() => {
    StatusBar.currentHeight && setStatusBarHeight(StatusBar.currentHeight);
    setHeight(screenHeight);
  }, []);

  useEffect(() => {
    if (currentChild && currentChild.value) {
      fetchChildBehaviors(currentChild.value);
    }
  }, [currentChild]);

  const createBehaviorSupportPlan = async (childId, behaviors) => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(
        "https://api.childbehaviorcheck.com/api/behavioralSupportPlan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            child_id: childId,
            behaviors: behaviors
          })
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("BSP created successfully:", data);

      if (data.success) {
        return data.result;
      } else {
        throw new Error("Failed to create BSP: " + (data.message || "Unknown error"));
      }
    } catch (error) {
      console.error("Error creating behavior support plan:", error);
      setError("Failed to create behavior support plan. Please try again later.");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchChildBehaviors = async (childId) => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(
        `https://api.childbehaviorcheck.com/api/child-behaviors/${childId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("child behaviors response:", data);
      
      if (data.success && data.behaviors) {
        setChildBehaviors(data.behaviors);
        setChildAntecedents(data.antecedents);
        setChildConsequences(data.consequences);
        
        const createdPlan = await createBehaviorSupportPlan(childId, data.behaviors);
        console.log("Plan created:", createdPlan);
        setCreatedPlan(createdPlan);
        
        mapApiResponseToPlanData(createdPlan, data.behaviors, data.antecedents);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching child behaviors:", error);
      setError("Failed to load child behaviors. Please try again later.");
      setChildBehaviors([]);
    } finally {
      setLoading(false);
    }
  };

  const extractIdFromUrl = (url) => {
    // Extract ID from Google Drive URL
    const match = url.match(/\/file\/d\/([^/]+)/);
    return match ? match[1] : null;
  };

  const findVideoResource = (id) => {
    if (!id) return null;
    // First try exact match
    let video = videoData.find(v => v.id === id);
    if (!video) {
      // Try partial match (sometimes IDs are truncated)
      video = videoData.find(v => id.includes(v.id) || v.id.includes(id));
    }
    return video;
  };

  const findPdfResource = (id) => {
    if (!id) return null;
    // First try exact match
    let pdf = pdfData.find(p => p.id === id);
    if (!pdf) {
      // Try partial match (sometimes IDs are truncated)
      pdf = pdfData.find(p => id.includes(p.id) || p.id.includes(p.id));
    }
    return pdf;
  };

  const mapApiResponseToPlanData = (apiResponse, behaviors, antecedents) => {
    if (!apiResponse) return;
    
    const { strategies, resources } = apiResponse;
    const sections = [];
    
    // Helper function to split resources into individual URLs
    const splitResources = (resourceString) => {
      return resourceString.split(',').map(url => url.trim()).filter(url => url);
    };
    
    // Helper function to create solution objects
    const createSolutions = (resourceUrls) => {
        const solutions = [];
        const seenTitles = new Set();
        
        resourceUrls.forEach(url => {
          const id = extractIdFromUrl(url);
          if (!id) return;
          
          // Find matching video and PDF resources
          const videoResource = findVideoResource(id);
          const pdfResource = findPdfResource(id);
          
          // Determine the base title (without .mp4 or .pdf)
          const baseTitle = (videoResource?.title || pdfResource?.title || "Strategy Resource")
            .replace('.mp4', '')
            .replace('.pdf', '')
            .trim();
          
          // Skip if we've already seen this title to avoid duplicates
          if (seenTitles.has(baseTitle)) return;
          seenTitles.add(baseTitle);
          
          // Create a single solution with both resources if available
          solutions.push({
            title: baseTitle,
            active: true,
            resources: [
              ...(videoResource ? [{
                type: "video", 
                url: videoResource.link, 
                title: videoResource.title
              }] : []),
              ...(pdfResource ? [{
                type: "illustration", 
                url: pdfResource.link, 
                title: pdfResource.title
              }] : [])
            ]
          });
        });
        
        return solutions;
      };
    
    // Create sections based on strategies
    strategies.forEach((strategy, index) => {
      let sectionTitle, sectionDescription;
      
      if (strategy === "Teach Strategy") {
        sectionTitle = "WHAT HAPPENED BEFORE THE INCIDENT";
        sectionDescription = antecedents.length > 0 ? antecedents.join(', ') : "No antecedents recorded";
      } else if (strategy === "Prevent Strategy") {
        sectionTitle = "WHAT WAS THE BEHAVIOR";
        sectionDescription = behaviors.length > 0 ? behaviors.join(', ') : "No behaviors recorded";
      } else {
        sectionTitle = "HOW TO RESPOND";
        sectionDescription = behaviors.length > 0 ? behaviors.join(', ') : "No behaviors recorded";
      }
      
      const resourceUrls = splitResources(resources[index]);
      const solutions = createSolutions(resourceUrls);
      
      if (solutions.length > 0) {
        sections.push({
          title: sectionTitle,
          description: sectionDescription,
          strategy: strategy,
          solutions: solutions
        });
      }
    });
    
    const mappedData = {
      childId: apiResponse.childId,
      behavioralSupportPlanId: apiResponse.behavioralSupportPlanId,
      childName: currentChild?.label || "Unknown Child",
      behaviorDescription: behaviors.length > 0 ? behaviors.join(', ') : "No behaviors recorded",
      sections: sections,
      plans: []
    };
    
    console.log("Mapped plan data:", mappedData);
    setPlanData(mappedData);
  };

    // Initialize zoomPlugin
    const zoomPluginInstance = zoomPlugin();
    const { ZoomIn, ZoomOut, ZoomPopover } = zoomPluginInstance;

  const openVideo = (videoUrl, videoTitle) => {
    setSelectedVideo(videoUrl);
    setSelectedVideoTitle(videoTitle);
    setIsVideoLoading(true);
    setVideoModalVisible(true);
  };

  const openPdf = (pdfUrl, pdfTitle) => {
    setSelectedPdf(pdfUrl);
    setSelectedPdfTitle(pdfTitle);
    setPdfModalVisible(true);
  };

  const renderSolution = (solution, colorScheme, index) => {
    const videoResource = solution.resources.find(r => r.type === "video");
    const pdfResource = solution.resources.find(r => r.type === "illustration");
    
    return (
      <View 
        key={`solution-${index}`} 
        style={[
          styles.strategyContainer,
          { 
            backgroundColor: colorScheme.strategyBg,
            borderColor: colorScheme.borderColor
          }
        ]}
      >
        <Text style={styles.strategyTitle}>{solution.title}</Text>
        
        <View style={styles.actionButtonsContainer}>
          {videoResource && (
            <TouchableOpacity 
              style={[
                styles.actionButton,
                { 
                  backgroundColor: colorScheme.buttonBg,
                  borderColor: colorScheme.buttonBg
                }
              ]}
              onPress={() => openVideo(videoResource.url, videoResource.title)}
            >
              <Text style={[styles.actionButtonText, { color: "#fff" }]}>
                Watch video
              </Text>
            </TouchableOpacity>
          )}
          
          {pdfResource && (
            <TouchableOpacity 
              style={[
                styles.actionButton,
                { 
                  backgroundColor: "transparent",
                  borderColor: colorScheme.buttonBorder
                }
              ]}
              onPress={() => openPdf(pdfResource.url, pdfResource.title)}
            >
              <Text style={[styles.actionButtonText, { color: colorScheme.buttonTextOutline }]}>
                See illustration
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };

  const renderSection = (section, index) => {
    const colorScheme = colorSchemes[index % colorSchemes.length];
    
    return (
      <View 
        key={`section-${index}`} 
        style={[
          styles.sectionContainer,
          { borderColor: colorScheme.borderColor }
        ]}
      >
        <Text style={styles.sectionTitle}>{section.title}</Text>
        <View 
          style={[
            styles.incidentBox,
            { borderColor: colorScheme.borderColor }
          ]}
        >
          <Text style={styles.incidentText}>{section.description}</Text>
        </View>
        
        <Text style={styles.solutionLabel}>What are some solutions you can use?</Text>
        <Text style={styles.strategyLabel}>Strategy: {section.strategy}</Text>
        {section.solutions.map((solution, solutionIndex) => 
          renderSolution(solution, colorScheme, solutionIndex)
        )}
      </View>
    );
  };

  if (loading) {
    return (
    <SafeAreaView style={[
        styles.container,
        {
            marginTop: statusBarHeight,
            height: height,
            minWidth: width
        }
        ]}>
        <TopNav />
        <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#27AFDE" />
            <Text style={styles.loadingText}>Getting behavior support plan...</Text>
        </View>
        <View style={{width: "100%", position: "absolute", bottom: 0}}>
            <BottomNav navigation={navigation} />
        </View>
    </SafeAreaView>
    );
  }

  if (error) {
    return (
        <SafeAreaView style={[
            styles.container,
            {
                marginTop: statusBarHeight,
                height: height,
                minWidth: width
            }
            ]}>
            <TopNav />
            {error === "Failed to load child behaviors. Please try again later." ? (
                <View style={styles.errorContainer}>
                    <Text style={styles.loadingText}>No Behavior support plan for {currentChild.label} yet. Try adding new behaviors</Text>
                </View>  
            ): (
                <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{error}</Text>
                    <TouchableOpacity style={styles.retryButton} onPress={() => fetchChildBehaviors(currentChild.value)}>
                    <Text style={styles.retryButtonText}>Retry</Text>
                    </TouchableOpacity>
                </View>
            )}
            <View style={{width: "100%", position: "absolute", bottom: 0}}>
             <BottomNav navigation={navigation} />
            </View>
        </SafeAreaView>
    );
  }

  if (!planData) {
    return (
        <SafeAreaView style={[
            styles.container,
            {
                marginTop: statusBarHeight,
                height: height,
                minWidth: width
            }
            ]}>
            <TopNav />
            <View style={styles.errorContainer}>
                <Text style={styles.errorText}>No behavior support plan found.</Text>
            </View>
            <View style={{width: "100%", position: "absolute", bottom: 0}}>
             <BottomNav navigation={navigation} />
            </View>
        </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={[
      styles.container,
      {
        marginTop: statusBarHeight,
        height: height,
        minWidth: width
      }
    ]}>
      <TopNav />

      <ScrollView style={[styles.scrollContainer, {maxHeight: height }]}>
        <View style={styles.childInfoContainer}>
          <Text style={styles.childInfoLabel}>Child name</Text>
          <Text style={styles.childInfoValue}>{currentChild?.label || "Unknown Child"}</Text>
          
          <Text style={styles.childInfoLabel}>Behavior description</Text>
          <Text style={styles.childInfoValue}>
            {childBehaviors.length > 0 ? childBehaviors.join(', ') : 'No behaviors recorded'}
          </Text>
        </View>

        {planData.sections && planData.sections.map((section, index) => 
          renderSection(section, index)
        )}
      </ScrollView>

    {/* Modal */}
    <Modal visible={isPdfModalVisible} animationType="slide" onRequestClose={() => setPdfModalVisible(false)}>
        <VStack style={styles.modalContainer}>
            {/* Header */}
            <View style={styles.modalHeader}>
                <View style={styles.modalHeaderContent}>
                    <TouchableOpacity onPress={() => setPdfModalVisible(false)}>
                        <Image
                            source={require("../../assets/icons/back.png")}
                            style={styles.backButton}
                        />
                    </TouchableOpacity>
                    <Text style={styles.headerTitle}>{selectedPdfTitle}</Text>
                </View>
            </View>

            {/* PDF Viewer */}
            {selectedPdf && (
                <View style={styles.pdfContainer}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={selectedPdf}
                            plugins={[zoomPluginInstance]}
                        />
                    </Worker>
                    {/* Zoom Controls */}
                    <View style={styles.zoomControlsContainer}>
                        <View style={styles.zoomControls}>
                            <ZoomOut />
                            <ZoomPopover />
                            <ZoomIn />
                        </View>
                    </View>
                </View>
            )}
        </VStack>
    </Modal>


    {/* Video Modal */}
    <Modal visible={isVideoModalVisible} animationType="slide" onRequestClose={() => setVideoModalVisible(false)}>
        <VStack style={styles.modalContainer}>
            {/* Video Player Header */}
            <View style={styles.modalHeader}>
                <View style={styles.modalHeaderContent}>
                    <TouchableOpacity onPress={() => setVideoModalVisible(false)}>
                        <Image source={require("../../assets/icons/back.png")} style={styles.backButton} />
                    </TouchableOpacity>
                    <Text style={styles.headerTitle}>{selectedVideoTitle}</Text>
                </View>
            </View>
            {loading && (
                <ActivityIndicator size="large" color="black" style={styles.spinner} />
            )}
            {/* Video Player */}
            {Platform.OS === 'web' ? (
                <iframe
                    src={selectedVideo}
                    style={styles.videoPlayer}
                    frameBorder="0"
                    allow="fullscreen"
                    onLoad={() => setLoading(false)} // Hide spinner once iframe loads
                />
            ) : (
                <WebView
                    source={{ uri: selectedVideo }}
                    style={styles.videoPlayer}
                    allowsFullscreenVideo
                    onLoadEnd={() => setIsVideoLoading(false)}
                />
            )}
        </VStack>
    </Modal>

      <View style={{width: "100%", position: "absolute", bottom: 0}}>
        <BottomNav navigation={navigation} />
      </View>
    </SafeAreaView>
  );
};

  
  const styles = StyleSheet.create({
    container: { 
      flex: 1, 
      backgroundColor: "#f5f5f5",
      position: "absolute",
    },
    loadingContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    loadingText: {
      marginTop: 10,
      fontSize: 16,
      color: "#666",
    },
    errorContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: 20,
    },
    errorText: {
      fontSize: 16,
      color: "red",
      textAlign: "center",
      marginBottom: 20,
    },
    retryButton: {
      backgroundColor: "#27AFDE",
      padding: 10,
      borderRadius: 5,
      minWidth: 100,
      alignItems: "center",
    },
    retryButtonText: {
      color: "white",
      fontSize: 16,
    },
    scrollContainer: {
      flex: 1,
      marginBottom: 80
    },
    childInfoContainer: {
      padding: 16,
      backgroundColor: "white",
    },
    childInfoLabel: {
      fontSize: 14,
      color: "#666",
      marginBottom: 4,
    },
    childInfoValue: {
      fontSize: 16,
      fontWeight: "500",
      marginBottom: 16,
    },
    sectionContainer: {
      marginTop: 8,
      backgroundColor: "white",
      borderRadius: 8,
      borderWidth: 3,
      marginHorizontal: 12,
      marginBottom: 16
    },
    sectionTitle: {
      fontSize: 12,
      fontWeight: "bold",
      color: "#666",
      marginBottom: 10,
      marginTop: 16,
      marginHorizontal: 16
    },
    strategyLabel: {
      fontSize: 12,
      fontWeight: "bold",
      color: "#666",
      marginBottom: 10,
      marginHorizontal: 16
    },
    incidentBox: {
      padding: 16,
      borderBottomWidth: 3,
    },
    incidentText: {
      fontSize: 16,
      fontWeight: "bold",
    },
    solutionLabel: {
      fontSize: 14,
      color: "#666",
      marginBottom: 12,
      paddingLeft: 16,
      marginTop: 12
    },
    strategyContainer: {
      marginHorizontal: 16,
      padding: 12,
      borderRadius: 8,
      borderWidth: 1,
      marginBottom: 12,
    },
    strategyTitle: {
      fontSize: 16,
      fontWeight: "500",
      marginBottom: 10,
    },
    actionButtonsContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    watchVideoButton: {
      paddingVertical: 8,
      paddingHorizontal: 12,
      borderRadius: 10,
      flex: 1,
      marginRight: 8,
      alignItems: "center",
    },
    watchVideoButtonText: {
      color: "#fff",
      fontWeight: "500",
    },
    seeIllustrationButton: {
      backgroundColor: "transparent",
      paddingVertical: 8,
      paddingHorizontal: 12,
      borderRadius: 10,
      borderWidth: 1,
      flex: 1,
      marginLeft: 8,
      alignItems: "center",
    },
    actionButtonsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 8,
      },
      actionButton: {
        paddingVertical: 8,
        paddingHorizontal: 12,
        borderRadius: 10,
        borderWidth: 1,
        flex: 1,
        alignItems: "center",
      },
      actionButtonText: {
        fontWeight: "500",
        fontSize: 14,
      },
      modalContainer: {
        flex: 1,
        backgroundColor: "#fff",
      },
      modalHeader: {
        flexDirection: "row",
        alignItems: "center",
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: "#eee",
      },
      backButton: {
        marginRight: 16,
      },
      modalTitle: {
        fontSize: 18,
        fontWeight: "500",
      },
      webView: {
        flex: 1,
      },
      spinner: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: [{ translateX: -20 }, { translateY: -20 }],
      },
      modalContainer: {
        flex: 1,
        backgroundColor: "white",
        padding: 0,
    },
    modalHeader: {
        width: "100%",
        height: 90,
        backgroundColor: "#F2F6F8",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
    modalHeaderContent: {
        height: 40,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 24,
        marginBottom: 16,
        marginTop: 5,
    },
    backButton: {
        width: 32,
        height: 32,
        marginRight: 20,
    },
    headerTitle: {
        fontSize: 16,
        fontFamily: "Rubik-Medium",
    },
    pdfContainer: {
        marginTop: 4,
        flex: 1, // Take up the remaining space below the header
        width: "100%",
    },
    zoomControlsContainer: {
        position: "absolute",
        bottom: "10%", // Adjust as needed
        right: "15%", // Adjust as needed
        backgroundColor: "white",
        padding: 10,
        borderRadius: 8,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
    },
    zoomControls: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    videoPlayer: { flex: 1, width: "100%", height: "100%" },
    spinner: { position: "absolute", top: "50%", left: "50%", transform: [{ translateX: -20 }, { translateY: -20 }] }
  });
  
  export default BehaviorSupportPlan;