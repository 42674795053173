import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

const BottomNav = () => {
  const navigation = useNavigation();
  const route = useRoute();

  // Map screen names to tab names
  const screenToTabMap = {
    "Support": "Supports",
    "BehaviorSupportPlan": "My Plan",
    "ProgressScreen": "Progress",
    "MessagesScreen": "Messages",
    "HelpScreen": "Help"
  };

  // Get current tab name based on current route
  const activeTab = screenToTabMap[route.name] || "Supports";

  const navItems = [
    {
      name: "Supports",
      screen: "Support",
      activeIcon: require("../../assets/FBSIcons/supportOn.svg"),
      inactiveIcon: require("../../assets/FBSIcons/supportOff.svg"),
    },
    {
      name: "My Plan",
      screen: "BehaviorSupportPlan",
      activeIcon: require("../../assets/FBSIcons/myPlanOn.svg"),
      inactiveIcon: require("../../assets/FBSIcons/myPlanOff.svg"),
    },
    {
      name: "Progress",
      screen: "ProgressScreen",
      activeIcon: require("../../assets/FBSIcons/progressOn.svg"),
      inactiveIcon: require("../../assets/FBSIcons/progressOff.svg"),
    },
    {
      name: "Messages",
      screen: "MessagesScreen",
      activeIcon: require("../../assets/FBSIcons/messageOn.svg"),
      inactiveIcon: require("../../assets/FBSIcons/messageOff.svg"),
    },
    {
      name: "Help",
      screen: "HelpScreen",
      activeIcon: require("../../assets/FBSIcons/helpOn.svg"),
      inactiveIcon: require("../../assets/FBSIcons/helpOff.svg"),
    },
  ];

  return (
    <View style={styles.bottomNav}>
      {navItems.map((item) => (
        <TouchableOpacity
          key={item.name}
          style={styles.navItem}
          onPress={() => navigation.navigate(item.screen)}
        >
          <Image
            source={activeTab === item.name ? item.activeIcon : item.inactiveIcon}
            style={[styles.icon, activeTab === item.name ? styles.activeIcon : styles.inactiveIcon]}
          />
          <Text
            style={[
              styles.navText,
              activeTab === item.name ? styles.activeText : styles.inactiveText,
            ]}
          >
            {item.name}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};



const styles = StyleSheet.create({
  bottomNav: {
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: 10,
    backgroundColor: "#fff",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
    paddingBottom: 25
  },
  navItem: {
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
    marginBottom: 4,
  },
  activeIcon: {
    tint: "#27AFDE"
  },
  inactiveIcon: {
    tint: "#666"
  },
  navText: {
    fontSize: 12,
  },
  activeText: {
    color: "#27AFDE",
  },
  inactiveText: {
    color: "#666",
  },
});

export default BottomNav;
