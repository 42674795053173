import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  Modal,
  TextInput,
  Button,
} from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useNavigation } from "@react-navigation/native";
import { AppContext } from "../../App"
import { useContext, useEffect } from 'react';

const initialActivities = [
  'Personal care routines (bathroom, tooth brushing, bathing)',
  'Meals',
  'Playtime',
  'School or daycare',
  'Transitioning between activities',
  'Create your own',
];

export default function DailyScheduleScreen() {
  const [scheduledItems, setScheduledItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newActivity, setNewActivity] = useState('');
  const [activities, setActivities] = useState(initialActivities);
  const { 
    listOfActivities,
    setListOfActivities,
    hasComplicatedActivity,
    setHasComplicatedActivity, 
    user
  } = useContext(AppContext)
  const handleItemPress = (item) => {
    if (item === 'Create your own') {
      setIsModalVisible(true); // Show the modal
    } else if (!scheduledItems.includes(item)) {
      setScheduledItems([...scheduledItems, item]);
    }
  };
  useEffect(() => {
    
    return () => {
      setListOfActivities(scheduledItems);
    };
  }, [scheduledItems]);
  const navigation = useNavigation();
  const handleAddActivity = () => {
    if (newActivity.trim() !== '') {
      // Insert the new activity just before "Create your own"
      const newActivities = [...activities];
      const createYourOwnIndex = newActivities.indexOf('Create your own');
      newActivities.splice(createYourOwnIndex, 0, newActivity.trim());

      setActivities(newActivities); // Update the activities list
      setNewActivity(''); // Clear the input
      setIsModalVisible(false); // Close the modal
    }
  };
  const handleNoPress = () => {
    navigation.navigate('SetUpTheDayChallengingBehavior')
    setHasComplicatedActivity('No');
  };

  const handleYesPress = () => {
    navigation.navigate('RoutineSetupScreen')
    setHasComplicatedActivity('Yes');
  };

  return (
    <SafeAreaView style={styles.container}>
      <TopNav />

      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>Setting up the day</Text>
      </View>

      <ScrollView style={styles.content}>
        <Text style={styles.instruction}>
          Drag the boxes to make a schedule for your child's typical day:
        </Text>

        {/* Draggable Items */}
        <View style={styles.draggableContainer}>
          {activities.map((item, index) => (
            <TouchableOpacity
              key={index}
              style={styles.draggableItem}
              onPress={() => handleItemPress(item)}
            >
              <Text style={styles.draggableItemText}>{item}</Text>
            </TouchableOpacity>
          ))}
        </View>

        <Text style={styles.scheduleTitle}>Your Child's Daily Schedule</Text>

        {/* Drop Zone or Schedule List */}
        {scheduledItems.length === 0 ? (
          <View style={styles.dropZone}>
            <Text style={styles.dropZoneText}>Drag items here!</Text>
          </View>
        ) : (
          <View style={styles.scheduleList}>
            {scheduledItems.map((item, index) => (
              <Text key={index} style={styles.scheduleItem}>
                {index + 1}. {item}
              </Text>
            ))}
          </View>
        )}

        {/* Feedback Section */}
        <>
          <Text style={styles.feedbackText}>
            Great work! Take a screenshot of this page and post it in a high-traffic area of your home to look at during the day. You can also use this list to draw a picture schedule with your child.
          </Text>

          <Text style={styles.questionText}>
            Is there a longer or more complicated activity you think would be helpful to break into smaller steps?
          </Text>

          <View style={styles.buttonContainer}>
          <TouchableOpacity 
       
  style={styles.button} 
  onPress={handleNoPress}
>
  <Text style={styles.buttonText}>No</Text>
</TouchableOpacity>
       
         
            <TouchableOpacity 
  style={styles.button} 
  onPress={handleYesPress}
>
  <Text style={styles.buttonText}>Yes</Text>
</TouchableOpacity>
          </View>
        </>
      </ScrollView>

      {/* Custom Modal for "Create your own" */}
      <Modal
        visible={isModalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setIsModalVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Create your own</Text>
            <TextInput style={styles.modalInput} value={newActivity} onChangeText={setNewActivity} autoFocus={true} />
            <View style={styles.modalButtonContainer}>
              <TouchableOpacity style={styles.modalButton} onPress={() => setIsModalVisible(false)}>
                <Text style={styles.modalButtonText}>Cancel</Text>
              </TouchableOpacity>
              <View style={styles.modalButtonSeparator} />
              <TouchableOpacity style={styles.modalButton} onPress={handleAddActivity}>
                <Text style={styles.modalButtonText}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <BottomNav />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  headerContainer: {
    backgroundColor: '#f5f5f5',
    padding: 15,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontFamily: 'chewy-regular',
  },
  content: {
    flex: 1,
    padding: 20,
    paddingHorizontal: 40,
  },
  instruction: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    color: '#333',
  },
  draggableContainer: {
    marginBottom: 20,
  },
  draggableItem: {
    backgroundColor: '#5FE0E7',
    padding: 15,
    marginBottom: 10,
    borderRadius: 4,
  },
  draggableItemText: {
    color: '#000',
    fontSize: 16,
    textAlign: 'center',
  },
  scheduleTitle: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 15,
  },
  dropZone: {
    height: 350,
    borderWidth: 2,
    borderColor: '#FFD700',
    borderStyle: 'solid',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  dropZoneText: {
    color: '#999',
    fontSize: 16,
  },
  scheduleList: {
    marginBottom: 300,
  },
  scheduleItem: {
    fontSize: 16,
    marginBottom: 10,
    color: '#333',
  },
  feedbackText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    color: '#333',
    lineHeight: 24,
  },
  questionText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    color: '#333',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
    marginBottom: 30,
  },
  button: {
    backgroundColor: '#64B5F6',
    paddingVertical: 12,
    paddingHorizontal: 30,
    borderRadius: 4,
    width: '45%',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: '600',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  modalContent: {
    width: "55%",
    backgroundColor: "rgba(255, 255, 255, 0.7)", // White with 70% opacity
    backdropFilter: "blur(10px)", // Blur effect
    borderRadius: 20,
    overflow: "hidden",
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 20,
    textAlign: "center",
    color: "#000",
  },
  modalInput: {
    backgroundColor: "#fff",
    borderRadius: 9,
    padding: 15,
    marginHorizontal: 20,
    marginBottom: 20,
    fontSize: 16,
    height: 30,
    borderWidth: 0, // Remove border
    outlineWidth: 0, // Remove outline on focus
  },
  modalButtonContainer: {
    flexDirection: "row",
    borderTopWidth: 0.5,
    borderTopColor: "rgba(0, 0, 0, 0.1)",
    height: 50,
  },
  modalButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalButtonSeparator: {
    width: 0.5,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  modalButtonText: {
    color: "#007AFF",
    fontSize: 17,
    fontWeight: "400",
  },
});