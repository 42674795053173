import React, {useContext} from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { AppContext } from "../../App"

const TopNav = () => {
  const {setMenuOpen, menuOpen } = useContext(AppContext);
  return (
    <View style={styles.topBar}>
      <TouchableOpacity onPress={() => setMenuOpen(true)}>
        <Text style={styles.menuIcon}>☰</Text>
      </TouchableOpacity>
      <Text style={styles.title}>Child Behavior Checkin</Text>
      <Image
          source={require("../../assets/logo40.png")}
          style={styles.logo}
        />
    </View>
  );
};

const styles = StyleSheet.create({
  topBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    backgroundColor: "#0C3948",
  },
  menuIcon: { fontSize: 24, color: "#fff" },
  title: { color: "#fff", fontSize: 18, fontWeight: "bold" },
  logo: { width: 40, height: 40 },
});

export default TopNav;