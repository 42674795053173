import React, { useCallback, createContext, useState, useEffect, useRef } from "react";
import { StatusBar } from "expo-status-bar";
import * as ScreenOrientation from "expo-screen-orientation";
// import * as SplashScreen from "expo-splash-screen";
import { StyleSheet, View, Dimensions, Platform, Button, Text, } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { NativeBaseProvider } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";

import NetInfo from '@react-native-community/netinfo';
import { useFonts } from 'expo-font';
// import { RootSiblingParent } from "react-native-root-siblings";
import SideMenu from './src/components/SideMenu'

// Import your components/screens
// import SideMenu from "./src/components/SideMenu";
import MyComponent from "./src/pages/LandingPage";
import ChatPage from "./src/pages/Chat";
import SignInPage from "./src/pages/Signin";
import SignUpPage from "./src/pages/SignUp";
import Library from "./src/pages/Library";
import SupportScreen from "./src/components/SupportScreen"; // Adjust path if needed
import selfLoveScreenOne from "./src/components/selfLoveScreenOne"; // Adjust path if needed
import SelfLoveScreenTwo from "./src/components/SelfLoveScreenTwo"; // Adjust path if needed
import SelfLoveSupportScreen from "./src/components/SelfLoveSupportScreen"; // Adjust path if needed
import SelfLoveVideoScreen from "./src/components/SelfLoveVideoScreen"; // Adjust path if needed
import SelfLoveChallengingBehaviorScreen from "./src/components/SelfLoveChallengingBehaviorScreen"; // Adjust path if needed
import SelfLoveDataBehaviour from "./src/components/SelfLoveDataBehaviour"; // Adjust path if needed
import AntecedentScreen from "./src/components/AntecedentScreen"; // Adjust path if needed
import BehaviorScreen from "./src/components/BehaviorScreen"; // Adjust path if needed
import ActivityScreen from "./src/components/ActivityScreen"; // Adjust path if needed
import ConsequencesScreen from "./src/components/ConsequencesScreen"; // Adjust path if needed
import SetUpTheDayScreenOne from "./src/components/SetUpTheDayScreenOne"; // Adjust path if needed
import DailyScheduleScreen from "./src/components/DailyScheduleScreen"; // Adjust path if needed
import RoutineSetupScreen from "./src/components/RoutineSetupScreen"; // Adjust path if needed
import SetUpTheDayChallengingBehavior from "./src/components/SetUpTheDayChallengingBehavior"; // Adjust path if needed
import ClearRulesScreenOne from  "./src/components/ClearRulesScreenOne"
import FamilyExpectationsScreen from  "./src/components/FamilyExpectationsScreen"
import ClearRulesChallengingBehaviour from  "./src/components/ClearRulesChallengingBehaviour"
import ClearRulesDataBehaviour from  "./src/components/ClearRulesDataBehaviour"
import StayPositiveScreenOne from  "./src/components/StayPositiveScreenOne"
import StayPositiveScreenTwo from  "./src/components/StayPositiveScreenTwo"
import StayPositiveChallengingBehaviour from  "./src/components/StayPositiveChallengingBehaviour"
import StayPositiveDataBehaviour from  "./src/components/StayPositiveDataBehaviour"
import FeelingsScreenOne from  "./src/components/FeelingsScreenOne"
import FeelingsScreenTwo from  "./src/components/FeelingsScreenTwo"
import FeelingsChallengingBehaviour from  "./src/components/FeelingsChallengingBehaviour"
import FeelingsDataBehaviour from  "./src/components/FeelingsDataBehaviour"
import SetUpTheDayDataBehaviour from  "./src/components/SetUpTheDayDataBehaviour"

import BasicInfoPage from "./src/pages/add_child/BasicInfoScreen";
import MethodsOfCommunicationForm from "./src/pages/add_child/MethodsOfCommunicationScreen";
import MoreInfoScreen from "./src/pages/add_child/MoreInfoScreen";
import AdditionalCaregiver from "./src/pages/add_child/Additional-caregiver";
import ProfessionalInvite from "./src/components/ProfessionalInvite"
import SharedChatNavigator from "./src/components/SharedChatNavigator"
import DeviceDetection from "./src/components/DeviceDetection";
import SharedChatScreen from './src/pages/SharedChatScreen'
import SelectChildScreen from './src/pages/SelectChild'
import BehaviorSupportPlan from './src/pages/BehaviorSupportPlan'
import LoadingScreen from "./src/components/LoadingPage";
import Questionnaire from "./src/components/Questionnaire";
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { useFocusEffect } from '@react-navigation/native';
import { Linking } from 'react-native';
import Modal from 'react-native-modal';
import { registerServiceWorkerAndSubscribe, sendSubscriptionToServer, testSendNotification } from "./notification-test"

// import { useNavigation } from '@react-navigation/native';




// Context creation
export const AppContext = createContext();

const Stack = createStackNavigator();
const { height } = Dimensions.get('window');
// const linking = {
//   prefixes: ['https://childbehaviorcheck.com'],
//   config: {
//     screens: {
//       SharedChat: {
//         path: 'shared/:user?/:chatId',
//         parse: {
//           chatId: (chatId) => `${chatId}`, // Ensure it's parsed as a string
//         },
//       },
//       Landing: '',
//       Chat: 'chat',
//       Signin: 'signin',
//       Signup: 'signup',
//       Library: 'library',
//     },
//   },
// };

const linking = {
  prefixes: ['https://childbehaviorcheck.com'],
  config: {
    screens: {
      SharedChat: {
        path: 'shared/:user?/:chatId',
        parse: {
          chatId: (chatId) => `${chatId}`, // Ensure it's parsed as a string
        },
      },
      ProfessionalInvite: "invite/:encryptedInviteId/:encryptedUserId/:encryptedChildId",
      Landing: '',
      Chat: 'chat',
      Signin: 'signin',
      Signup: 'signup',
      Library: 'library',
      // ProfessionalInvite: {
      //   path: 'invite/:encryptedInviteId/:encryptedUserId/:encryptedChildId',
      //   parse: {
      //     encryptedInviteId: (encryptedInviteId) => `${encryptedInviteId}`,
      //     encryptedUserId: (encryptedUserId) => `${encryptedUserId}`,
      //     encryptedChildId: (encryptedChildId) => `${encryptedChildId}`,
      //   },
      // },
    },
  },
};

// SplashScreen.preventAutoHideAsync();


export default function App() {
  const [data, setData] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [machineId, setMachineId] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [recentChats, setRecentChats] = useState([]);
  const [currentChatSummary, setCurrentChatSummary] = useState(0)
  const [modalVisible, setModalVisible] = useState(false);
  const [archivedChats, setArchivedChats] = useState([])
  const [visible, setVisible] = useState(false);
  const modalizeRef = useRef(null);
  const [currentChatSummaryTitle, setCurrentChatSummaryTitle] = useState("")
  const [childName, setChildName] = useState("");
  const [childRace, setChildRace] = useState("")
  const [childGender, setChildGender] = useState("")
  const [diagnosis, setDiagnosis] = useState(null)
  const [educationPlan, setEducationPlan] = useState(null)
  const [diagnosisDetails, setDiagnosisDetails] = useState("")
  const [otherServices, setOtherServices] = useState(null)
  const [serviceDetails, setServiceDetails] = useState("")
  const [requestingAttention, setRequestingAttention] = useState([]);
  const [refusingActions, setRefusingActions] = useState([]);
  const [simplifiedRequesting, setSimplifiedRequesting] = useState([]);
  const [simplifiedRefusal, setSimplifiedRefusal] = useState([]);
  const [inviteAccepted, setInviteAccepted] = useState(false);
  const navigationRef = useRef(); // Create navigation reference

  //Self-love global states
  const [gratitudeInput, setGratitudeInput] = useState('');
  const [supportTeamInput, setSupportTeamInput] = useState('');
  const [inviteSupport, setinviteSupport] = useState('');
  const [selectedChild, setSelectedChild] = useState(null)
  const [selfLoveId, setSelfLoveId] = useState("")

  //Antecedent States
  const [selectedAntecedentItemsChecklist, setSelectedAntecedentItemsChecklist] = useState([])
  const [selectedAntecedentDirectionsChecklist, setSelectedAntecedentDirectionsChecklist] = useState([])
  const [selectedAntecedentPeopleChecklist, setSelectedAntecedentPeopleChecklist] = useState([])
  const [selectedAntecedentItemsServerFormat, setSelectedAntecedentItemsServerFormat] = useState([]);

  //Behavior states
  const [selectedBehaviors, setSelectedBehaviors] = useState([])

  //Consequences States
  const [selectedConsequencesItemsChecklist, setSelectedConsequencesItemsChecklist] = useState([])
  const [selectedConsequencesDirectionsChecklist, setSelectedConsequencesDirectionsChecklist] = useState([])
  const [selectedConsequencesPeopleChecklist, setSelectedConsequencesPeopleChecklist] = useState([])

  //Activities States
  const [selectedActivities, setSelectedActivities] = useState([])
  const [selectedActivityItemsServerFormat, setSelectedActivityItemsServerFormat] = useState([]);

  //clear rules global states
  const [familyExpectations, setFamilyExpectations] = useState([])
  const [clearRulesId, setClearRulesId] = useState("")

  //Feelings universal support states
    const [recognition, setRecognition] = useState("")
    const [acknowledgment, setAcknowledgment] = useState("")
    const [teaching, setTeaching] = useState("")
    const [feelingsId, setFeelingsId] = useState("")

    // set up the day states 
  const [listOfActivities, setListOfActivities] = useState([""]); 
  const [hasComplicatedActivity, setHasComplicatedActivity] = useState("");
  const [dailyScheduleId, setDailyScheduleId] = useState("");
  const [routineName, setRoutineName] = useState("");
  const [listOfSteps, setListOfSteps] = useState([""]);

  const [childrenData, setChildrenData] = useState([]);
  const [currentChild, setCurrentChild] = useState({});
  // const [permissionStatus, setPermissionStatus] = useState('default');
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  const [permissionStatus, setPermissionStatus] = useState("default")
  const [subscription, setSubscription] = useState(null)
  const [logs, setLogs] = useState([])
  const [testMessage, setTestMessage] = useState("Test notification")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [pushSubscriptionEndpoint, setPushSubscribtionEndpoint] = useState(0)
  const [isQuestionnaireVisible, setIsQuestionnaireVisible] = useState(false);
  
  useEffect(() => {
    registerSW()
  }, [])

  useEffect(() => {

    if ("Notification" in window) {
      if (Notification.permission === 'granted') {
        setNotificationsEnabled(true);
      }
      setPermissionStatus(Notification.permission)
    }

  }, [notificationsEnabled])

  const addLog = (message) => {
    setLogs((prev) => [...prev, `${new Date().toLocaleTimeString()}: ${message}`])
  }

  const requestPermission = async () => {
    setIsLoading(true)
    setError(null)
    setSuccess(null)

    try {
      addLog("Requesting notification permission...")
      const permission = await Notification.requestPermission()
      setPermissionStatus(permission)
      addLog(`Permission status: ${permission}`)

      if (permission === "granted") {
        setSuccess("Notification permission granted!")
        registerSW()
      } else {
        setError("Notification permission denied. Please enable notifications in your browser settings.")
      }
    } catch (err) {
      console.error("Error requesting permission:", err)
      setError(`Error requesting permission: ${err.message}`)
      addLog(`Error: ${err.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  const registerSW = async () => {
    setIsLoading(true)
    setError(null)
    setSuccess(null)

    try {
      addLog("Registering service worker...")
      const sub = await registerServiceWorkerAndSubscribe()
      setSubscription(sub)
      addLog("Service worker registered and subscribed")
      setSuccess("Service worker registered successfully!")

      // Send subscription to server
      addLog("Sending subscription to server...")
      const subResponse = await sendSubscriptionToServer(sub)
      setPushSubscribtionEndpoint(sub.endpoint)
      addLog("Subscription sent to server")
      setSuccess("Service worker registered and subscription sent to server!")
    } catch (err) {
      console.error("Error registering service worker:", err)
      setError(`Error registering service worker: ${err.message}`)
      addLog(`Error: ${err.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  const sendTestNotification = async () => {
    setIsLoading(true)
    setError(null)
    setSuccess(null)

    try {
      addLog(`Sending test notification: "${testMessage}"`)
      await testSendNotification(testMessage)
      addLog("Test notification sent")
      setSuccess("Test notification sent successfully!")
    } catch (err) {
      console.error("Error sending test notification:", err)
      setError(`Error sending test notification: ${err.message}`)
      addLog(`Error: ${err.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  const showLocalNotification = () => {
    setIsLoading(true)
    setError(null)
    setSuccess(null)

    try {
      addLog("Showing local notification...")

      if (Notification.permission === "granted") {
        const notification = new Notification("Local Test", {
          body: testMessage,
          icon: "/assets/adaptive-icon192x192.png",
        })

        notification.onclick = () => {
          addLog("Local notification clicked")
          notification.close()
          window.focus()
        }

        addLog("Local notification shown")
        setSuccess("Local notification shown successfully!")
      } else {
        setError("Notification permission not granted")
        addLog("Cannot show notification: permission not granted")
      }
    } catch (err) {
      console.error("Error showing local notification:", err)
      setError(`Error showing local notification: ${err.message}`)
      addLog(`Error: ${err.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  // const requestNotificationPermission = async () => {
  //   if (Platform.OS === 'web' && 'Notification' in window) {
  //     try {
  //       console.log('Requesting notification permission from button click');
  //       const permission = await Notification.requestPermission();
  //       console.log('Permission after request:', permission);
  //       setPermissionStatus(permission);
        
  //       if (permission === 'granted') {
  //         setNotificationsEnabled(true);
  //       //   // setupWebNotifications();
  //       }
  //     } catch (error) {
  //       console.error('Error requesting permission:', error);
  //     }
  //   }
  // };

  const requestNotificationPermission = async () => {
    if (Platform.OS === 'web' && 'Notification' in window) {
      try {
        console.log('Requesting notification permission from button click');
        const permission = await Notification.requestPermission();
        console.log('Permission after request:', permission);
        setPermissionStatus(permission);
        
        // Update notificationsEnabled based on the actual permission status
        setNotificationsEnabled(permission === 'granted');
        
        
        if (permission === 'granted') {
          // setupWebNotifications(); // Uncomment if you have this function
          registerSW()
        }
      } catch (error) {
        console.error('Error requesting permission:', error);
      }
    }
  };

  //Questionnaire setup begins
  // Function to get the last display time from the Service Worker
  const getLastDisplayTime = async () => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      console.log("[App] Sending GET_LAST_DISPLAY_TIME message to Service Worker")

      return new Promise((resolve) => {
        const messageChannel = new MessageChannel()

        messageChannel.port1.onmessage = (event) => {
          if (event.data.type === "LAST_DISPLAY_TIME") {
            console.log("[App] Received LAST_DISPLAY_TIME:", event.data.lastDisplayTime)
            resolve(event.data.lastDisplayTime)
          }
        }

        navigator.serviceWorker.controller.postMessage({ type: "GET_LAST_DISPLAY_TIME" }, [messageChannel.port2])
      })
    }
    console.log("[App] Service Worker not available")
    return null
  }

  const setLastDisplayTime = async (time) => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      console.log("[App] Sending SET_LAST_DISPLAY_TIME message to Service Worker")
      navigator.serviceWorker.controller.postMessage({
        type: "SET_LAST_DISPLAY_TIME",
        time,
      })
    } else {
      console.log("[App] Service Worker not available")
    }
  }

  // Check if it's time to show the questionnaire
  const checkQuestionnaireTimer = async () => {
    console.log("[App] Checking questionnaire timer");
    const lastDisplayTime = await getLastDisplayTime();
    const now = Date.now();
    const threeMonths = 7776000000; // 3 months in milliseconds (corrected value)
  
    console.log("[App] Last display time:", lastDisplayTime);
    console.log("[App] Current time:", now);
  
    // If no last display time exists, set the initial time (now) but don't show questionnaire
    if (!lastDisplayTime) {
      console.log("[App] Setting initial display time");
      await setLastDisplayTime(now);
      return;
    }
  
    // Only show questionnaire if 3 months have passed since last display
    if (now - lastDisplayTime >= threeMonths) {
      if (user !== null) {
        console.log("[App] Showing questionnaire");
        setIsQuestionnaireVisible(true);
        await setLastDisplayTime(now);
      } else {
        console.log("[App] User not logged in, cannot show questionnaire");
      }
    } else {
      console.log("[App] Questionnaire not due yet");
    }
  }

  useEffect(() => {
    console.log("[App] Setting up questionnaire timer interval")

    // Initial check when component mounts
    checkQuestionnaireTimer()

    // Check every day instead of every minute
    const interval = setInterval(() => {
      checkQuestionnaireTimer();
    }, 24 * 60 * 60 * 1000); // Check every 24 hours

    return () => {
      console.log("[App] Clearing questionnaire timer interval")
      clearInterval(interval)
    }
  }, [user])

  const handleCloseQuestionnaire = () => {
    setIsQuestionnaireVisible(false)
  }

  // useEffect(() => {
  //   const handleDeepLink = (url) => {
  //     if (!url) return;

  //     const routeParts = url.replace(/.*?:\/\//g, '').split('/');
  //     if (routeParts[0] === 'invite') {
  //       const [, encryptedInviteId, encryptedUserId, encryptedChildId] = routeParts;

  //       // Make the GET request to accept the invite
  //       fetch(
  //         `https://api.childbehaviorcheck.com/api/professionals/accept-invite/${encryptedInviteId}/${encryptedUserId}/${encryptedChildId}`
  //       )
  //         .then((response) => {
  //           if (response.ok) {
  //             setInviteAccepted(true); // Show modal
  //             navigationRef.current?.navigate('Landing'); // Use navigationRef to navigate
  //           } else {
  //             console.error("Failed to accept invite:", response.status);
  //           }
  //         })
  //         .catch((error) => console.error("Error in fetch:", error));
  //     }
  //   };

  //   // Handle the initial URL
  //   Linking.getInitialURL()
  //     .then((url) => {
  //       if (url) handleDeepLink(url);
  //     })
  //     .catch((err) => console.error("Error getting initial URL:", err));

  //   // Listen for deep links
  //   const subscription = Linking.addEventListener('url', (event) => {
  //     handleDeepLink(event.url);
  //   });

  //   // Cleanup the listener on unmount
  //   return () => {
  //     subscription.remove();
  //   };
  // }, []);

  const getStoredUserID = async () => {
    try {
      const value = await AsyncStorage.getItem("user_id");
      const valueM = await AsyncStorage.getItem("machine_id");
      console.log("Stored user:" + value);

      if (value !== null && valueM !== null) {
        setUser(value);
        setMachineId(valueM);
        return true;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  useEffect(() => {
    getStoredUserID();
    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const lockOrientation = async () => {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    };

    lockOrientation();
  }, []);

  useEffect(() => {
    console.log("Starting to fetch user children")
    // Fetch children from API
    const fetchUserChildren = async () => {
      try {
        const userId = user;
        console.log(`User id is : ${userId}`)
        if (userId) {
          const response = await fetch(
            `https://api.childbehaviorcheck.com/api/children/user/${userId}`,
            {
              method: "GET",
              // headers: {
              //   userid: userId,
              // },
            }
          );
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
          console.log("user children", data)
  
          // Transform data into childrenData

          const transformedChildrenData = data.children_data.map((child) => ({
            label: child.child_name,
            value: child.child_id,
          }));

          setChildrenData(transformedChildrenData);

          if (transformedChildrenData.length > 0) {
            setCurrentChild(transformedChildrenData[0]);
          }
        

        }
      } catch (error) {
        console.error("Error fetching user children", error);
      }
    };
  
    fetchUserChildren();
  }, [user]);

  useEffect(() => {
    console.log("Starting to fetch chats")
    // Fetch chats from API
    const fetchChats = async () => {
      try {
        const child = currentChild;
        console.log(`child id is : ${child.value}`)
        if (child.value) {
          const response = await fetch(
            `https://api.childbehaviorcheck.com/back/history/active_chats/${child.value}`,
            {
              method: "POST",
              // headers: {
              //   userid: userId,
              // },
            }
          );
  
          if (!response.ok && response.status !== 404) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          if (response.status === 404) {
            setRecentChats([]);
          }
  
          const data = await response.json();
          console.log("user chat summaries:", data)
  
          // Transform data into grouped chats by date
          const groupedChats = groupChatsByDate(data);
          setRecentChats(groupedChats);
        }
      } catch (error) {
        console.error("Error fetching chat summaries:", error);
      }
    };
  
    fetchChats();
  }, [currentChild.value]);

  useEffect(() => {
    console.log("Recent chats:", recentChats);
  }, [recentChats]);

  const groupChatsByDate = (chats) => {
    const grouped = {};
    const today = new Date();
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  
    chats.forEach((chat) => {
      const chatDate = new Date(chat.updated_at);
      let date;
  
      if (isSameDay(chatDate, today)) {
        date = "Today";
      } else if (isSameDay(chatDate, yesterday)) {
        date = "Yesterday";
      } else {
        date = formatChatDate(chatDate);
      }
  
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push({
        id: chat.chat_summary_id,
        title: chat.chat_summary,
      });
    });
  
    return Object.keys(grouped).map((date) => ({
      date,
      chats: grouped[date],
    }));
  };
  
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  
  const formatChatDate = (date) => {
    const day = date.toLocaleString("en-US", { weekday: "short" });
    const month = date.toLocaleString("en-US", { month: "short" });
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
  
    return `${day}. ${month} ${dayOfMonth}, ${year}`;
  }


  const [fontsLoaded, fontError] = useFonts({
    "Rubik-Regular": require("./assets/fonts/Rubik-Regular.ttf"),
    "Rubik-Medium": require("./assets/fonts/Rubik-Medium.ttf"),
    "Rubik-SemiBold": require("./assets/fonts/Rubik-SemiBold.ttf"),
    "Rubik-Bold": require("./assets/fonts/Rubik-Bold.ttf"),
    "Chewy-Regular": require("./assets/fonts/Chewy-Regular.ttf"), // Path to your font file
  });

  // const onLayoutRootView = useCallback(async () => {
  //   if (fontsLoaded || fontError) {
  //     await SplashScreen.hideAsync();
  //   }
  // }, [fontsLoaded, fontError]);

  if (!fontsLoaded && !fontError) {
    return null;
  }

  

  return (
      <NativeBaseProvider>
        <AppContext.Provider
          value={{ 
            data, 
            setData, 
            menuOpen, 
            setMenuOpen, 
            user, 
            setUser, 
            isConnected, 
            machineId, 
            setMachineId, 
            recentChats, 
            currentChatSummary, 
            setCurrentChatSummary, 
            setRecentChats, 
            modalVisible, 
            setModalVisible, 
            archivedChats, 
            setArchivedChats,
            visible, 
            setVisible,
            modalizeRef, 
            currentChatSummaryTitle, 
            setCurrentChatSummaryTitle,
            childName, 
            setChildName,
            childRace,
            setChildRace,
            childGender,
            setChildGender,
            diagnosis,
            setDiagnosis,
            educationPlan,
            setEducationPlan,
            diagnosisDetails,
            setDiagnosisDetails,
            otherServices,
            setOtherServices,
            serviceDetails,
            setServiceDetails,
            requestingAttention,
            setRequestingAttention,
            refusingActions,
            setRefusingActions,
            simplifiedRequesting, 
            setSimplifiedRequesting,
            simplifiedRefusal, 
            setSimplifiedRefusal,
            inviteAccepted,
            setInviteAccepted,
            gratitudeInput,
            supportTeamInput,
            inviteSupport,
            setGratitudeInput,
            setSupportTeamInput,
            setinviteSupport,
            selectedChild, 
            setSelectedChild,
            selfLoveId, setSelfLoveId,

            selectedAntecedentItemsChecklist, 
            setSelectedAntecedentItemsChecklist,
            selectedAntecedentDirectionsChecklist, 
            setSelectedAntecedentDirectionsChecklist,
            selectedAntecedentPeopleChecklist, 
            setSelectedAntecedentPeopleChecklist,

            selectedBehaviors, setSelectedBehaviors,

            selectedConsequencesItemsChecklist, 
            setSelectedConsequencesItemsChecklist,
            selectedConsequencesDirectionsChecklist, 
            setSelectedConsequencesDirectionsChecklist,
            selectedConsequencesPeopleChecklist, 
            setSelectedConsequencesPeopleChecklist,

            selectedActivities, setSelectedActivities,

            clearRulesId, setClearRulesId,
            familyExpectations, setFamilyExpectations,
            selectedAntecedentItemsServerFormat, setSelectedAntecedentItemsServerFormat,
            selectedActivityItemsServerFormat, setSelectedActivityItemsServerFormat,

            teaching, setTeaching,
            acknowledgment, setAcknowledgment,
            recognition, setRecognition,
            feelingsId, setFeelingsId,

            
            listOfActivities,
            setListOfActivities,
            hasComplicatedActivity,
            setHasComplicatedActivity,
            dailyScheduleId,
            routineName,
            listOfSteps,
            setDailyScheduleId,
            setRoutineName,
            setListOfSteps,
            childrenData, setChildrenData,
            currentChild, setCurrentChild,
            pushSubscriptionEndpoint
          }}
        >
          <View style={styles.container}> {/* Ensures full height */}
            {/* Add this notification permission button for web */}
            {((window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) && !notificationsEnabled) && (
              <Modal isVisible={!notificationsEnabled} style={styles.modal}>
                <View style={styles.modalContent}>
                  <Text style={styles.title}>Enable Notifications</Text>
                  <Text style={styles.description}>
                    Get notified about important updates and reminders.
                  </Text>
                  <Button
                    title="Enable Notifications"
                    onPress={requestNotificationPermission}
                  />
                  {permissionStatus === 'denied' && (
                    <Text style={styles.errorText}>
                      Notifications are blocked. Please enable them in your browser settings.
                    </Text>
                  )}
                </View>
              </Modal>
            )}
            <NavigationContainer linking={linking} fallback={<MyComponent />}>
            <SideMenu />
              <Stack.Navigator initialRouteName="Landing" screenOptions={{ headerShown: false }}>
                <Stack.Screen name="Loading" component={LoadingScreen} />
                <Stack.Screen name="Landing" component={MyComponent} />
                <Stack.Screen name="Chat" component={ChatPage} />
                <Stack.Screen name="Signin" component={SignInPage} />
                <Stack.Screen name="Signup" component={SignUpPage} />
                <Stack.Screen name="Library" component={Library} />
                <Stack.Screen name="Support" component={SupportScreen} />
                <Stack.Screen name="selfLove1" component={selfLoveScreenOne} />
                <Stack.Screen name="selfLove2" component={SelfLoveScreenTwo} />
                <Stack.Screen name="selfLoveSupport" component={SelfLoveSupportScreen} />
                <Stack.Screen name="selfLoveVideo" component={SelfLoveVideoScreen} />
                <Stack.Screen name="SelfLoveChallengingBehaviorScreen" component={SelfLoveChallengingBehaviorScreen} />
                <Stack.Screen name="SelfLoveDataBehaviour" component={SelfLoveDataBehaviour} />
                <Stack.Screen name="AntecedentScreen" component={AntecedentScreen} />
                <Stack.Screen name="BehaviorScreen" component={BehaviorScreen} />
                <Stack.Screen name="ActivityScreen" component={ActivityScreen} />
                <Stack.Screen name="ConsequencesScreen" component={ConsequencesScreen} />
                <Stack.Screen name="SetUpTheDayScreenOne" component={SetUpTheDayScreenOne} />
                <Stack.Screen name="DailyScheduleScreen" component={DailyScheduleScreen} />
                <Stack.Screen name="RoutineSetupScreen" component={RoutineSetupScreen} />
                <Stack.Screen name="SetUpTheDayChallengingBehavior" component={SetUpTheDayChallengingBehavior} />
                <Stack.Screen name="SetUpTheDayDataBehaviour" component={SetUpTheDayDataBehaviour} />
               
                <Stack.Screen name="ClearRulesScreenOne" component={ClearRulesScreenOne} />
                <Stack.Screen name="FamilyExpectationsScreen" component={FamilyExpectationsScreen} />
                <Stack.Screen name="ClearRulesChallengingBehaviour" component={ClearRulesChallengingBehaviour} />
                <Stack.Screen name="ClearRulesDataBehaviour" component={ClearRulesDataBehaviour} />
                <Stack.Screen name="StayPositiveScreenOne" component={StayPositiveScreenOne} />
                <Stack.Screen name="StayPositiveScreenTwo" component={StayPositiveScreenTwo} />
                <Stack.Screen name="StayPositiveChallengingBehaviour" component={StayPositiveChallengingBehaviour} />
                <Stack.Screen name="StayPositiveDataBehaviour" component={StayPositiveDataBehaviour} />
                <Stack.Screen name="FeelingsScreenOne" component={FeelingsScreenOne} />
                <Stack.Screen name="FeelingsScreenTwo" component={FeelingsScreenTwo} />
                <Stack.Screen name="FeelingsChallengingBehaviour" component={FeelingsChallengingBehaviour} /> 
                <Stack.Screen name="FeelingsDataBehaviour" component={FeelingsDataBehaviour} /> 
                <Stack.Screen name="SharedChat" component={SharedChatScreen} />
                <Stack.Screen name="BasicInfo" component={BasicInfoPage} />
                <Stack.Screen name="MethodsOfCommunication" component={MethodsOfCommunicationForm} />
                <Stack.Screen name="MoreInfo" component={MoreInfoScreen} />
                <Stack.Screen name="AdditionalCaregiver" component={AdditionalCaregiver} />
                <Stack.Screen name="ProfessionalInvite" component={ProfessionalInvite} />
                <Stack.Screen name="SelectChildScreen" component={SelectChildScreen} />
                <Stack.Screen name="BehaviorSupportPlan" component={BehaviorSupportPlan} />
              </Stack.Navigator>
            </NavigationContainer>
            <Questionnaire visible={isQuestionnaireVisible} onClose={handleCloseQuestionnaire} />
          </View>
          <StatusBar style="auto" />
        </AppContext.Provider>
      </NativeBaseProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1, // Ensures the App's root view takes full height
    height: height,
  },
  notificationContainer: {
    backgroundColor: '#f0f0f0',
    padding: 20,
    borderRadius: 10,
    marginVertical: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  description: {
    marginBottom: 15,
  },
  errorText: {
    color: 'red',
    marginTop: 10,
  },
  successText: {
    color: 'green',
    marginVertical: 10,
  },
  modal: {
    marginHorizontal: 10,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
  },
});

// if (Platform.OS === 'web') {
//   if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//       navigator.serviceWorker.register('/service-worker.js').then(registration => {
//         console.log('SW registered: ', registration);
//       }).catch(registrationError => {
//         console.log('SW registration failed: ', registrationError);
//       });
//     });
//   }
// }

// Helper function to convert ArrayBuffer to Base64
function ArrayBufferToBase64(buffer) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
}