import React, {useEffect, useContext} from 'react';
import { useNavigation } from '@react-navigation/native';
import ChallengingBehaviorScreen from './ChallengingBehaviorScreen'; // Import the reusable component
import { AppContext } from "../../App"

const ClearRulesChallengingBehaviour = () => {
  const {familyExpectations, setFamilyExpectations, clearRulesId, setClearRulesId, user} = useContext(AppContext)

    const navigation = useNavigation();
    const handleYesPress = () => {
      // Handle yes press logic here
      createClearRules("Yes")
    };
  
    const handleNoPress = () => {
      // Handle no press logic here
      createClearRules("No")
    };

    const createClearRules = async (option) => {
      // Make API call to create clear rules
      try {
        const response = await fetch("https://api.childbehaviorcheck.com/api/clearRule", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "userid": user,
          },
          body: JSON.stringify({
            user_id: user,
            family_expectations: familyExpectations,
            challenging_behavior_or_not: option
          }),
        });
    
        if (response.status !== 201) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("clear rule response:", data);
        setClearRulesId(data.clear_rule_id)
        setFamilyExpectations([])
    
        if (option === "Yes"){
          navigation.navigate('ClearRulesDataBehaviour');
        } else if (option === "No") {
          navigation.navigate('Support');
        }
      } catch (error) {
        console.error("Error creating clear rule:", error);
      }
    }
  
    return (
      <ChallengingBehaviorScreen onYesPress={handleYesPress} onNoPress={handleNoPress} />
    );
  };
  
export default ClearRulesChallengingBehaviour;