import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView, Dimensions, StatusBar } from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import PracticeButton from './PracticeButton';
import { useNavigation } from "@react-navigation/native";

const { width } = Dimensions.get("window"); // Get screen width

const { height: screenHeight } = Dimensions.get("window") //Get height of the screen

const PracticeScreenComponent = ({ headerText, paragraphs, buttons, onPracticeButtonPress }) => {
  const navigation = useNavigation();

  const [statusBarHeight, setStatusBarHeight] = useState(0)
  const [height, setHeight] = useState(100)

  useEffect(() => {
    // Subtract 20% from the screen height
    StatusBar.currentHeight && setStatusBarHeight(StatusBar.currentHeight)
    setHeight(screenHeight)
  }, [])

  return (

    <SafeAreaView style={[
      styles.container, 
      {
        marginTop: statusBarHeight,
        height: height,
      }
      ]}>
      <TopNav />
      <ScrollView style={styles.scrollView}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>{headerText}</Text>
        </View>
        
        <View style={styles.contentContainer}>
          {paragraphs.map((paragraph, index) => (
            <Text key={index} style={styles.paragraph}>
              {paragraph}
            </Text>
          ))}

          <View style={styles.blueContainer}>
            {Object.entries(buttons).map(([text, link], index) => (
              <TouchableOpacity
                key={index}
                style={styles.whiteButton}
                onPress={() => navigation.navigate(link)}
              >
                <Text style={styles.buttonText}>{text}</Text>
              </TouchableOpacity>
            ))}
          </View>

          <PracticeButton onPress={onPracticeButtonPress} />
        </View>
       
      </ScrollView>
      <View styles={{width: "100%", position: "fixed", bottom: 0}}>
        <BottomNav navigation={navigation} />
      </View>
    </SafeAreaView>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',

    //added these styles
    position: "absolute",
    minWidth: width, 
  },
  scrollView: {
    flex: 1,
  },
 
  headerContainer: {
    backgroundColor: '#f0f0f0',
    padding: 15,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontWeight: '500',
    fontFamily: "chewy-regular"
  },
  contentContainer: {
    padding: 20,
  },
  paragraph: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    lineHeight: 24,
    color: '#333',
  },
  blueContainer: {
    backgroundColor: "#35A8E0",
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 20,
    width: "90%",
    alignSelf: "center",
  },
  whiteButton: {
    width: "60%",
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#fff',
    borderRadius: 8,
    padding: 15,
    marginVertical: 8,
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '500',
  },
});

export default PracticeScreenComponent;