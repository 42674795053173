import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableOpacity, Dimensions, StatusBar } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import TopNav from './TopNav'; 
import BottomNav from './BottomNav';

const { width } = Dimensions.get("window"); // Get screen width
const circleSize = width * 0.3; // Circles adjust size dynamically

const { height: screenHeight } = Dimensions.get("window") //Get height of the screen

const SupportScreen = ({ navigation }) => {
  const [statusBarHeight, setStatusBarHeight] = useState(0)
  const [height, setHeight] = useState(100)

  useEffect(() => {
    // Subtract 20% from the screen height
    StatusBar.currentHeight && setStatusBarHeight(StatusBar.currentHeight)
    setHeight(screenHeight)
  }, [])

  return (
    <SafeAreaView style={[
      styles.container,
      {
        marginTop: statusBarHeight,
        height: height,
      }
      ]}>
      <TopNav />
      <ScrollView style={styles.scroll} contentContainerStyle={styles.content}>
        {/* Tab Navigation */}
        <View style={styles.tabs}>
          <TouchableOpacity style={[styles.tab, styles.activeTab]}>
            <Text style={styles.tabText}>Universal Supports</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.tab}>
            <Text style={styles.tabText}>Journal Entries</Text>
          </TouchableOpacity>
        </View>

        {/* Title */}
        <Text style={styles.heading}>Universal Support Strategies</Text>

        {/* Circular Buttons Layout */}
        <View style={styles.circleContainer}>
          <View style={styles.row}>
            <TouchableOpacity style={[styles.circle, styles.activeCircle]} onPress={() => navigation.navigate("selfLove1")}>
              <Text style={styles.circleText}>Self-Love</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.circle}>
              <Text style={styles.circleText} onPress={() => navigation.navigate("SetUpTheDayScreenOne")}>Set up the{'\n'}Day</Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity 
  style={styles.circle} 
  onPress={() => navigation.navigate('ClearRulesScreenOne')}
>
  <Text style={styles.circleText}>Clear Rules</Text>
</TouchableOpacity>

          <View style={styles.row}>
          <TouchableOpacity 
      style={styles.circle} 
      onPress={() => navigation.navigate("StayPositiveScreenOne")}
    >
      <Text style={styles.circleText}>Stay Positive</Text>
    </TouchableOpacity>
    <TouchableOpacity 
      style={styles.circle} 
      onPress={() => navigation.navigate('FeelingsScreenOne')}
    >
      <Text style={styles.circleText}>Feelings</Text>
    </TouchableOpacity>
          </View>
        </View>

        {/* Additional Resources Button */}
      </ScrollView>
      {/* Bottom Navigation */}
      <View styles={{width: "100%", position: "fixed", bottom: 0}}>
        <BottomNav navigation={navigation} />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: { 
    flex: 1, 
    backgroundColor: "fff",

    //added these styles
    position: "absolute",
    minWidth: width, 
  },
  content: { 
    alignItems: "center", 
    paddingBottom: 80 
  }, // Prevents overlap with BottomNav
  scroll: { 
    flex: 1, 
    backgroundColor: "#fff" 
  }, // Prevents overlap with BottomNav
  tabs: { 
    flexDirection: "row", 
    borderBottomWidth: 1, 
    borderBottomColor: "#ccc", 
    width: "100%", 
    justifyContent: "center", 
    paddingTop: 10 
  },
  tab: { 
    paddingBottom: 5, 
    marginHorizontal: 15 
  },
  activeTab: { 
    borderBottomWidth: 2, 
    borderBottomColor: "#35A8E0" 
  },
  tabText: { 
    fontSize: 16,
  
  },
  heading: { 
    fontSize: 28, 
    fontWeight: "bold", 
    textAlign: "center", 
    marginVertical: 15, 
    fontFamily:  'Chewy-Regular'
  },
  circleContainer: { 
    alignItems: "center", 
    marginTop: 10 
  },
  row: { 
    flexDirection: "row", 
    justifyContent: "center" 
  },
  circle: {
    width: circleSize,
    height: circleSize,
    borderRadius: circleSize / 2,
    backgroundColor: "#D3D3D3",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    elevation: 5,
  },
  activeCircle: { 
    backgroundColor: "#F58231" 
  },
  circleText: { 
    textAlign: "center", 
    fontSize: 20, 
    fontWeight: "300" ,
     fontFamily:  'Noteworthy'
  },
  resourceButton: { 
    backgroundColor: "#35A8E0", 
    paddingVertical: 12, 
    paddingHorizontal: 60, 
    borderRadius: 8, 
    marginVertical: 20 
  },
  resourceButtonText: { 
    color: "#fff", 
    fontSize: 16, 
    fontWeight: "normal" 
  },
});

export default SupportScreen;