import React from 'react';
import ContentPage from './PracticeScreenComponent';
import { useNavigation } from "@react-navigation/native";

const SetUpTheDayScreenOne = () => {
  const navigation = useNavigation();
  const headerText = "Setting up the day";
  const paragraphs = [
    "Children are most successful when they can predict what comes next. Creating and maintaining predictable activities and routines at home can reduce challenging behavior!",
    "Start by writing a list of the activities your child needs to do every day (or most days!). This might include waking up, brushing teeth, eating meals, going to school, or playing with toys.",
    "Next, split larger or difficult activities up into smaller steps your child can complete successfully. For example, getting ready for bed may involve putting on pajamas, brushing teeth, and using the toilet.",
    "Teach your child to do the steps in the same way every day. Consistent routines can ease anxiety and frustration for both you and your child.",
    "Here are some more resources for setting up your child's day like making a visual schedule and using transition warnings!"
  ];
  const buttons = {
    "Making a visual Daily Schedule": "selfCareLink",
    "Transition Warnings": "hotButtonsLink",
  };
  const onPracticeButtonPress = () => {
    navigation.navigate("DailyScheduleScreen")
    // Navigate to the next screen or perform an action
    console.log("Practice button pressed");
  };

  return (
    <ContentPage
      headerText={headerText}
      paragraphs={paragraphs}
      buttons={buttons}
      onPracticeButtonPress={onPracticeButtonPress}
    />
  );
};

export default SetUpTheDayScreenOne;