import React from 'react';
import { View, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export default function CustomCheckbox({ checked }) {
  return (
    <View style={styles.container}>
      <Icon 
        name={checked ? "checkbox-marked" : "checkbox-blank-outline"} 
        size={24} 
        color={checked ? "#64B5F6" : "#e0e0e0"}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  }
});