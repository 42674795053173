// import React, { useState, useEffect, useContext } from 'react';
// import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
// import TopNav from './TopNav';
// import BottomNav from './BottomNav';
// import CustomCheckbox from './CustomCheckbox';
// import { useNavigation } from "@react-navigation/native";
// import { AppContext } from '../../App';

// export default function ConsequencesScreen() {
//   const [activeTab, setActiveTab] = useState('items');
//   const navigation = useNavigation();
//   const {             selectedConsequencesItemsChecklist, 
//     setSelectedConsequencesItemsChecklist,
//     selectedConsequencesDirectionsChecklist, 
//     setSelectedConsequencesDirectionsChecklist,
//     selectedConsequencesPeopleChecklist, 
//     setSelectedConsequencesPeopleChecklist } = useContext(AppContext)
//   // const [itemsChecklist] = useState([
//   //   { id: '1', label: 'The child is given or gets more time with a toy or object', checked: false },
//   //   { id: '2', label: "Someone takes a child's toy or object", checked: false },
//   //   { id: '3', label: 'The child is redirected to a different toy or activity', checked: false },
//   //   { id: '4', label: 'The activity changes or is removed', checked: false },
//   //   { id: '5', label: 'An adult threatens to take away something the child enjoys', checked: false },
//   //   { id: '6', label: 'Other', checked: false },
//   // ]);

//   // const [directionsChecklist] = useState([
//   //   { id: '1', label: 'A direction or request is removed', checked: false },
//   //   { id: '2', label: 'A direction or request is changed', checked: false },
//   //   { id: '3', label: 'The rules or directions are reviewed', checked: false },
//   //   { id: '4', label: 'A direction or an activity is delayed', checked: false },
//   //   { id: '5', label: 'The child is taken to a new area or room', checked: false },
//   //   { id: '6', label: 'An adult completes a task for a child', checked: false },
//   //   { id: '7', label: 'Other', checked: false },
//   // ]);

//   // const [peopleChecklist] = useState([
//   //   { id: '1', label: 'The child receives help', checked: false },
//   //   { id: '2', label: 'The child is punished', checked: false },
//   //   { id: '3', label: 'A reprimand is given ("Don\'t do that", "Enough", or "Stop it")', checked: false },
//   //   { id: '4', label: 'The child is sent to time out', checked: false },
//   //   { id: '5', label: 'An adult comforts child (Hugs or soothing words)', checked: false },
//   //   { id: '6', label: 'Someone plays with the child', checked: false },
//   //   { id: '7', label: 'Someone talks to the child about their behavior', checked: false },
//   //   { id: '8', label: 'An adult gives the child space', checked: false },
//   //   { id: '9', label: "An adult talks to the child about how they're feeling", checked: false },
//   //   { id: '10', label: 'An adults asks child what they need or how they can help', checked: false },
//   //   { id: '11', label: 'An adult talks to child about unrelated topic', checked: false },
//   //   { id: '12', label: 'Other', checked: false },
//   // ]);

//   // const [checkedItems, setCheckedItems] = useState({
//   //   items: new Set(),
//   //   directions: new Set(),
//   //   people: new Set(),
//   // });

//   // const toggleCheckbox = (id, type) => {
//   //   setCheckedItems(prev => {
//   //     const newSet = new Set(prev[type]);
//   //     if (newSet.has(id)) {
//   //       newSet.delete(id);
//   //     } else if (newSet.size < 2) {
//   //       newSet.add(id);
//   //     }
//   //     return { ...prev, [type]: newSet };
//   //   });
//   // };

//   // const getChecklistForTab = () => {
//   //   switch (activeTab) {
//   //     case 'items':
//   //       return { data: itemsChecklist, type: 'items' };
//   //     case 'directions':
//   //       return { data: directionsChecklist, type: 'directions' };
//   //     case 'people':
//   //       return { data: peopleChecklist, type: 'people' };
//   //     default:
//   //       return { data: [], type: 'items' };
//   //   }
//   // };

//   const [itemsChecklist, setItemsChecklist] = useState([
//     { id: '1', label: 'The child is given or gets more time with a toy or object', checked: false },
//     { id: '2', label: "Someone takes a child's toy or object", checked: false },
//     { id: '3', label: 'The child is redirected to a different toy or activity', checked: false },
//     { id: '4', label: 'The activity changes or is removed', checked: false },
//     { id: '5', label: 'An adult threatens to take away something the child enjoys', checked: false },
//     { id: '6', label: 'Other', checked: false },
//   ]);

//   const [directionsChecklist, setDirectionsChecklist] = useState([
//     { id: '1', label: 'A direction or request is removed', checked: false },
//     { id: '2', label: 'A direction or request is changed', checked: false },
//     { id: '3', label: 'The rules or directions are reviewed', checked: false },
//     { id: '4', label: 'A direction or an activity is delayed', checked: false },
//     { id: '5', label: 'The child is taken to a new area or room', checked: false },
//     { id: '6', label: 'An adult completes a task for a child', checked: false },
//     { id: '7', label: 'Other', checked: false },
//   ]);

//   const [peopleChecklist, setPeopleChecklist] = useState([
//     { id: '1', label: 'The child receives help', checked: false },
//     { id: '2', label: 'The child is punished', checked: false },
//     { id: '3', label: 'A reprimand is given', checked: false },
//     { id: '4', label: 'The child is sent to time out', checked: false },
//     { id: '5', label: 'An adult comforts child', checked: false },
//     { id: '6', label: 'Someone plays with the child', checked: false },
//     { id: '7', label: 'Someone talks to the child about their behavior', checked: false },
//     { id: '8', label: 'An adult gives the child space', checked: false },
//     { id: '9', label: "An adult talks to the child about how they're feeling", checked: false },
//     { id: '10', label: 'An adults asks child what they need or how they can help', checked: false },
//     { id: '11', label: 'An adult talks to child about unrelated topic', checked: false },
//     { id: '12', label: 'Other', checked: false },
//   ]);

//   // Initialize checklists based on context values
//   useEffect(() => {
//     if (selectedConsequencesItemsChecklist.length > 0) {
//       const updatedItemsChecklist = itemsChecklist.map(item => ({
//         ...item,
//         checked: selectedConsequencesItemsChecklist.some(selectedItem => selectedItem.id === item.id),
//       }));
//       setItemsChecklist(updatedItemsChecklist);
//     }

//     if (selectedConsequencesDirectionsChecklist.length > 0) {
//       const updatedDirectionsChecklist = directionsChecklist.map(item => ({
//         ...item,
//         checked: selectedConsequencesDirectionsChecklist.some(selectedItem => selectedItem.id === item.id),
//       }));
//       setDirectionsChecklist(updatedDirectionsChecklist);
//     }

//     if (selectedConsequencesPeopleChecklist.length > 0) {
//       const updatedPeopleChecklist = peopleChecklist.map(item => ({
//         ...item,
//         checked: selectedConsequencesPeopleChecklist.some(selectedItem => selectedItem.id === item.id),
//       }));
//       setPeopleChecklist(updatedPeopleChecklist);
//     }
//   }, []);

//   // Update context whenever checklists change
//   useEffect(() => {
//     const selectedItems = itemsChecklist.filter(item => item.checked);
//     setSelectedConsequencesItemsChecklist(selectedItems);
//   }, [itemsChecklist]);

//   useEffect(() => {
//     const selectedDirections = directionsChecklist.filter(item => item.checked);
//     setSelectedConsequencesDirectionsChecklist(selectedDirections);
//   }, [directionsChecklist]);

//   useEffect(() => {
//     const selectedPeople = peopleChecklist.filter(item => item.checked);
//     setSelectedConsequencesPeopleChecklist(selectedPeople);
//   }, [peopleChecklist]);

//   const toggleCheckbox = (id, type) => {
//     const updateChecklist = (list) =>
//       list.map((item) =>
//         item.id === id ? { ...item, checked: !item.checked } : item
//       );

//     switch (type) {
//       case 'items':
//         setItemsChecklist(updateChecklist(itemsChecklist));
//         break;
//       case 'directions':
//         setDirectionsChecklist(updateChecklist(directionsChecklist));
//         break;
//       case 'people':
//         setPeopleChecklist(updateChecklist(peopleChecklist));
//         break;
//       default:
//         break;
//     }
//   };

//   const getChecklistForTab = () => {
//     switch (activeTab) {
//       case 'items':
//         return { data: itemsChecklist, type: 'items' };
//       case 'directions':
//         return { data: directionsChecklist, type: 'directions' };
//       case 'people':
//         return { data: peopleChecklist, type: 'people' };
//       default:
//         return { data: [], type: 'items' };
//     }
//   };

//   const renderChecklist = () => {
//     const { data, type } = getChecklistForTab();
//     return data.map((item) => (
//       <TouchableOpacity
//         key={item.id}
//         style={styles.checkboxContainer}
//         onPress={() => toggleCheckbox(item.id, type)}
//       >
//         <CustomCheckbox checked={item.checked} />
//         <Text style={styles.checkboxLabel}>{item.label}</Text>
//       </TouchableOpacity>
//     ));
//   };

//   // const renderChecklist = () => {
//   //   const { data, type } = getChecklistForTab();
//   //   return data.map((item) => (
//   //     <TouchableOpacity
//   //       key={item.id}
//   //       style={styles.checkboxContainer}
//   //       onPress={() => toggleCheckbox(item.id, type)}
//   //     >
//   //       <CustomCheckbox checked={checkedItems[type].has(item.id)} />
//   //       <Text style={styles.checkboxLabel}>{item.label}</Text>
//   //     </TouchableOpacity>
//   //   ));
//   // };

//   return (
//     <SafeAreaView style={styles.container}>
//       <TopNav />
      
//       <View style={styles.headerContainer}>
//         <Text style={styles.headerText}>Consequences</Text>
//       </View>

//       <View style={styles.tabContainer}>
//         <TouchableOpacity 
//           style={[styles.tab, activeTab === 'items' && styles.activeTab]}
//           onPress={() => setActiveTab('items')}
//         >
//           <Text style={[styles.tabText, activeTab === 'items' && styles.activeTabText]}>
//             Items/Activities
//           </Text>
//         </TouchableOpacity>
        
//         <TouchableOpacity 
//           style={[styles.tab, activeTab === 'directions' && styles.activeTab]}
//           onPress={() => setActiveTab('directions')}
//         >
//           <Text style={[styles.tabText, activeTab === 'directions' && styles.activeTabText]}>
//             Directions
//           </Text>
//         </TouchableOpacity>
        
//         <TouchableOpacity 
//           style={[styles.tab, activeTab === 'people' && styles.activeTab]}
//           onPress={() => setActiveTab('people')}
//         >
//           <Text style={[styles.tabText, activeTab === 'people' && styles.activeTabText]}>
//             People
//           </Text>
//         </TouchableOpacity>
//       </View>

//       <ScrollView style={styles.content}>
//         <Text style={styles.instruction}>
//           Select one or two options, then hit submit
//         </Text>

//         {renderChecklist()}
        
//         <TouchableOpacity 
//           style={styles.submitButton} 
//           onPress={() => navigation.goBack()}
//         >
//           <Text style={styles.submitButtonText}>Submit</Text>
//         </TouchableOpacity>

//         <View style={styles.bottomPadding} />
//       </ScrollView>

//       <BottomNav />
//     </SafeAreaView>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//   },
//   headerContainer: {
//     backgroundColor: '#e5e5e5',
//     padding: 15,
//     alignItems: 'center',
//     borderBottomWidth: 1,
//     borderBottomColor: '#e0e0e0',
//   },
//   headerText: {
//     fontSize: 20,
//     color: '#666',
//     fontFamily: 'chewy-regular',
  
//   },
//   tabContainer: {
//     flexDirection: 'row',
//     borderBottomWidth: 1,
//     borderBottomColor: '#e0e0e0',
//   },
//   tab: {
//     flex: 1,
//     paddingVertical: 15,
//     alignItems: 'center',
//   },
//   activeTab: {
//     borderBottomWidth: 2,
//     borderBottomColor: '#64B5F6',
//   },
//   tabText: {
//     fontSize: 16,
//     color: '#666',
//     fontFamily: 'System',
//   },
//   activeTabText: {
//     color: '#64B5F6',
//   },
//   content: {
//     flex: 1,
//     padding: 20,
//   },
//   instruction: {
//     fontSize: 16,
//     color: '#333',
//     marginBottom: 20,
//     fontFamily: 'System',
//   },
//   checkboxContainer: {
//     flexDirection: 'row',
//     alignItems: 'center',
//     padding: 15,
//     backgroundColor: '#f5f5f5',
//     borderRadius: 8,
//     marginBottom: 10,
//     borderWidth: 1,
//     borderColor: '#e0e0e0',
//   },
//   checkboxLabel: {
//     flex: 1,
//     marginLeft: 10,
//     fontSize: 16,
//     color: '#333',
//     fontFamily: 'System',
//   },
//   submitButton: {
//     backgroundColor: '#64B5F6',
//     padding: 15,
//     borderRadius: 8,
//     alignItems: 'center',
//     marginVertical: 20,
//     width: "20%",
//     alignSelf: "center"
//   },
//   submitButtonText: {
//     color: '#fff',
//     fontSize: 16,
//     fontWeight: '600',
//     fontFamily: 'System',
//   },
//   bottomPadding: {
//     height: 20,
//   },
// });

import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import CustomCheckbox from './CustomCheckbox';
import { useNavigation } from "@react-navigation/native";
import { AppContext } from '../../App';

export default function ConsequencesScreen() {
  const [activeTab, setActiveTab] = useState('items');
  const navigation = useNavigation();
  const {
    selectedConsequencesItemsChecklist, 
    setSelectedConsequencesItemsChecklist,
    selectedConsequencesDirectionsChecklist, 
    setSelectedConsequencesDirectionsChecklist,
    selectedConsequencesPeopleChecklist, 
    setSelectedConsequencesPeopleChecklist 
  } = useContext(AppContext);
  
  // Track total selected items across all tabs
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);

  const [itemsChecklist, setItemsChecklist] = useState([
    { id: '1', label: 'The child is given or gets more time with a toy or object', checked: false },
    { id: '2', label: "Someone takes a child's toy or object", checked: false },
    { id: '3', label: 'The child is redirected to a different toy or activity', checked: false },
    { id: '4', label: 'The activity changes or is removed', checked: false },
    { id: '5', label: 'An adult threatens to take away something the child enjoys', checked: false },
    { id: '6', label: 'Other', checked: false },
  ]);

  const [directionsChecklist, setDirectionsChecklist] = useState([
    { id: '1', label: 'A direction or request is removed', checked: false },
    { id: '2', label: 'A direction or request is changed', checked: false },
    { id: '3', label: 'The rules or directions are reviewed', checked: false },
    { id: '4', label: 'A direction or an activity is delayed', checked: false },
    { id: '5', label: 'The child is taken to a new area or room', checked: false },
    { id: '6', label: 'An adult completes a task for a child', checked: false },
    { id: '7', label: 'Other', checked: false },
  ]);

  const [peopleChecklist, setPeopleChecklist] = useState([
    { id: '1', label: 'The child receives help', checked: false },
    { id: '2', label: 'The child is punished', checked: false },
    { id: '3', label: 'A reprimand is given', checked: false },
    { id: '4', label: 'The child is sent to time out', checked: false },
    { id: '5', label: 'An adult comforts child', checked: false },
    { id: '6', label: 'Someone plays with the child', checked: false },
    { id: '7', label: 'Someone talks to the child about their behavior', checked: false },
    { id: '8', label: 'An adult gives the child space', checked: false },
    { id: '9', label: "An adult talks to the child about how they're feeling", checked: false },
    { id: '10', label: 'An adults asks child what they need or how they can help', checked: false },
    { id: '11', label: 'An adult talks to child about unrelated topic', checked: false },
    { id: '12', label: 'Other', checked: false },
  ]);

  // Initialize checklists based on context values and count total selected
  useEffect(() => {
    let count = 0;
    
    if (selectedConsequencesItemsChecklist.length > 0) {
      const updatedItemsChecklist = itemsChecklist.map(item => {
        const isChecked = selectedConsequencesItemsChecklist.some(selectedItem => selectedItem.id === item.id);
        if (isChecked) count++;
        return { ...item, checked: isChecked };
      });
      setItemsChecklist(updatedItemsChecklist);
    }

    if (selectedConsequencesDirectionsChecklist.length > 0) {
      const updatedDirectionsChecklist = directionsChecklist.map(item => {
        const isChecked = selectedConsequencesDirectionsChecklist.some(selectedItem => selectedItem.id === item.id);
        if (isChecked) count++;
        return { ...item, checked: isChecked };
      });
      setDirectionsChecklist(updatedDirectionsChecklist);
    }

    if (selectedConsequencesPeopleChecklist.length > 0) {
      const updatedPeopleChecklist = peopleChecklist.map(item => {
        const isChecked = selectedConsequencesPeopleChecklist.some(selectedItem => selectedItem.id === item.id);
        if (isChecked) count++;
        return { ...item, checked: isChecked };
      });
      setPeopleChecklist(updatedPeopleChecklist);
    }
    
    setTotalSelectedCount(count);
  }, []);

  // Update context whenever checklists change
  useEffect(() => {
    const selectedItems = itemsChecklist.filter(item => item.checked);
    setSelectedConsequencesItemsChecklist(selectedItems);
  }, [itemsChecklist]);

  useEffect(() => {
    const selectedDirections = directionsChecklist.filter(item => item.checked);
    setSelectedConsequencesDirectionsChecklist(selectedDirections);
  }, [directionsChecklist]);

  useEffect(() => {
    const selectedPeople = peopleChecklist.filter(item => item.checked);
    setSelectedConsequencesPeopleChecklist(selectedPeople);
  }, [peopleChecklist]);

  // Modified toggle function to enforce the two-selection limit
  const toggleCheckbox = (id, type) => {
    let currentList;
    let setterFunction;
    
    switch (type) {
      case 'items':
        currentList = itemsChecklist;
        setterFunction = setItemsChecklist;
        break;
      case 'directions':
        currentList = directionsChecklist;
        setterFunction = setDirectionsChecklist;
        break;
      case 'people':
        currentList = peopleChecklist;
        setterFunction = setPeopleChecklist;
        break;
      default:
        return;
    }
    
    // Find the item being toggled
    const item = currentList.find(item => item.id === id);
    
    // If item is already checked, we're unchecking it
    if (item.checked) {
      const updatedList = currentList.map(item => 
        item.id === id ? { ...item, checked: false } : item
      );
      setterFunction(updatedList);
      setTotalSelectedCount(totalSelectedCount - 1);
    } 
    // If item is not checked and we haven't reached the limit, check it
    else if (totalSelectedCount < 2) {
      const updatedList = currentList.map(item => 
        item.id === id ? { ...item, checked: true } : item
      );
      setterFunction(updatedList);
      setTotalSelectedCount(totalSelectedCount + 1);
    }
    // If we've reached the limit and trying to check a new item, don't allow it
  };

  const getChecklistForTab = () => {
    switch (activeTab) {
      case 'items':
        return { data: itemsChecklist, type: 'items' };
      case 'directions':
        return { data: directionsChecklist, type: 'directions' };
      case 'people':
        return { data: peopleChecklist, type: 'people' };
      default:
        return { data: [], type: 'items' };
    }
  };

  // Modified render function to disable checkboxes when limit is reached
  const renderChecklist = () => {
    const { data, type } = getChecklistForTab();
    
    return data.map((item) => {
      // Determine if this checkbox should be disabled
      const isDisabled = totalSelectedCount >= 2 && !item.checked;
      
      return (
        <TouchableOpacity
          key={item.id}
          style={[
            styles.checkboxContainer,
            isDisabled && styles.disabledCheckbox
          ]}
          onPress={() => !isDisabled && toggleCheckbox(item.id, type)}
          disabled={isDisabled}
        >
          <CustomCheckbox checked={item.checked} disabled={isDisabled} />
          <Text style={[
            styles.checkboxLabel,
            isDisabled && styles.disabledText
          ]}>
            {item.label}
          </Text>
        </TouchableOpacity>
      );
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>Consequences</Text>
      </View>

      <View style={styles.tabContainer}>
        <TouchableOpacity 
          style={[styles.tab, activeTab === 'items' && styles.activeTab]}
          onPress={() => setActiveTab('items')}
        >
          <Text style={[styles.tabText, activeTab === 'items' && styles.activeTabText]}>
            Items/Activities
          </Text>
        </TouchableOpacity>
        
        <TouchableOpacity 
          style={[styles.tab, activeTab === 'directions' && styles.activeTab]}
          onPress={() => setActiveTab('directions')}
        >
          <Text style={[styles.tabText, activeTab === 'directions' && styles.activeTabText]}>
            Directions
          </Text>
        </TouchableOpacity>
        
        <TouchableOpacity 
          style={[styles.tab, activeTab === 'people' && styles.activeTab]}
          onPress={() => setActiveTab('people')}
        >
          <Text style={[styles.tabText, activeTab === 'people' && styles.activeTabText]}>
            People
          </Text>
        </TouchableOpacity>
      </View>

      <ScrollView style={styles.content}>
        <Text style={styles.instruction}>
          Select up to two options total across all tabs, then hit submit
        </Text>

        {renderChecklist()}
        
        <TouchableOpacity 
          style={styles.submitButton} 
          onPress={() => navigation.goBack()}
        >
          <Text style={styles.submitButtonText}>Submit</Text>
        </TouchableOpacity>

        <View style={styles.bottomPadding} />
      </ScrollView>

      <BottomNav />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  headerContainer: {
    backgroundColor: '#e5e5e5',
    padding: 15,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontFamily: 'chewy-regular',
  },
  tabContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  tab: {
    flex: 1,
    paddingVertical: 15,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#64B5F6',
  },
  tabText: {
    fontSize: 16,
    color: '#666',
    fontFamily: 'System',
  },
  activeTabText: {
    color: '#64B5F6',
  },
  content: {
    flex: 1,
    padding: 20,
  },
  instruction: {
    fontSize: 16,
    color: '#333',
    marginBottom: 20,
    fontFamily: 'System',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#e0e0e0',
  },
  disabledCheckbox: {
    backgroundColor: '#e0e0e0',
    opacity: 0.7,
  },
  checkboxLabel: {
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
    color: '#333',
    fontFamily: 'System',
  },
  disabledText: {
    color: '#999',
  },
  submitButton: {
    backgroundColor: '#64B5F6',
    padding: 15,
    borderRadius: 8,
    alignItems: 'center',
    marginVertical: 20,
    width: "20%",
    alignSelf: "center"
  },
  submitButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
    fontFamily: 'System',
  },
  bottomPadding: {
    height: 20,
  },
});