import React, { useEffect, useState, useContext } from 'react';
import { 
  View, 
  Text, 
  ScrollView, 
  TouchableOpacity, 
  StyleSheet, 
  SafeAreaView, 
  Dimensions,
  StatusBar 
} from 'react-native';
import TopNav from '../components/TopNav';
import BottomNav from '../components/BottomNav';
import { useNavigation } from "@react-navigation/native";
import Icon from 'react-native-vector-icons/Ionicons'; // Make sure to install this package
import { AppContext } from  "../../App"
const { width, height: screenHeight } = Dimensions.get("window");

const SelectChildScreen = () => {
  const navigation = useNavigation();
  const {childrenData, setCurrentChild, setRecentChats} = useContext(AppContext)
  const [statusBarHeight, setStatusBarHeight] = useState(0);
  const [height, setHeight] = useState(100);
  const [children, setChildren] = useState(childrenData);

  useEffect(() => {
    StatusBar.currentHeight && setStatusBarHeight(StatusBar.currentHeight);
    setHeight(screenHeight);
  }, []);

  const handleChildSelect = (child) => {
    // Navigate back
    setCurrentChild(child)
    fetchChats(child)
    navigation.goBack();
  };

  const fetchChats = async (child) => {
    try {
    //   const child = currentChild;
      console.log(`child id is : ${child.value}`)
      if (child.value) {
        const response = await fetch(
          `https://api.childbehaviorcheck.com/back/history/active_chats/${child.value}`,
          {
            method: "POST",
            // headers: {
            //   userid: userId,
            // },
          }
        );

        if (!response.ok && response.status !== 404) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          if (response.status === 404) {
            setRecentChats([]);
          }

        const data = await response.json();
        console.log("user chat summaries:", data)

        // Transform data into grouped chats by date
        const groupedChats = groupChatsByDate(data);
        setRecentChats(groupedChats);
      }
    } catch (error) {
      console.error("Error fetching chat summaries:", error);
    }
  };

  const groupChatsByDate = (chats) => {
    const grouped = {};
    const today = new Date();
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  
    chats.forEach((chat) => {
      const chatDate = new Date(chat.created_at);
      let date;
  
      if (isSameDay(chatDate, today)) {
        date = "Today";
      } else if (isSameDay(chatDate, yesterday)) {
        date = "Yesterday";
      } else {
        date = formatChatDate(chatDate);
      }
  
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push({
        id: chat.chat_summary_id,
        title: chat.chat_summary,
      });
    });
  
    return Object.keys(grouped).map((date) => ({
      date,
      chats: grouped[date],
    }));
  };

  return (
    <SafeAreaView style={[styles.container, { marginTop: statusBarHeight, height: height }]}>
      <TopNav />
      
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>Select a child</Text>
      </View>

      <View style={styles.listHeaderContainer}>
        <Text style={styles.listTitle}>List of Children</Text>
        {/* <TouchableOpacity onPress={() => navigation.navigate('EditChildren')}>
          <Text style={styles.editButton}>Edit</Text>
        </TouchableOpacity> */}
      </View>

      <ScrollView style={styles.scrollView}>
        {children.map((child) => (
          <TouchableOpacity
            key={child.value}
            style={styles.childItem}
            onPress={() => handleChildSelect(child)}
          >
            <View style={styles.iconContainer}>
              <Icon name="person" size={24} color="#fff" />
            </View>
            <Text style={styles.childName}>{child.label}</Text>
          </TouchableOpacity>
        ))}
      </ScrollView>

      <TouchableOpacity 
        style={styles.fabButton}
        onPress={() => navigation.navigate('BasicInfo')}
      >
        <Icon name="add" size={32} color="#fff" />
      </TouchableOpacity>

      <BottomNav navigation={navigation} />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    position: "absolute",
    minWidth: width,
  },
  headerContainer: {
    backgroundColor: '#f0f0f0',
    padding: 15,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontWeight: '500',
    fontFamily: "chewy-regular"
  },

  listHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    paddingBottom: 10,
  },
  listTitle: {
    fontSize: 25,
    fontWeight: '400',
    color: '#000',
    marginVertical: 5,
  },
  editButton: {
    fontSize: 18,
    color: '#007AFF', // iOS blue color
  },
  scrollView: {
    flex: 1,
  },
  childItem: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    marginHorizontal: 20,
    marginVertical: 5,
    padding: 15,
    borderRadius: 10,
  },
  iconContainer: {
    width: 50,
    height: 50,
    backgroundColor: '#87CEEB',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  childName: {
    marginLeft: 15,
    fontSize: 18,
    color: '#000',
  },
  fabButton: {
    position: 'absolute',
    bottom: 90, // Adjusted to account for BottomNav
    right: 20,
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: '#87CEEB',
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
});

export default SelectChildScreen;