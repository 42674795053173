// import React, { useState, useEffect, useContext } from 'react';
// import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import TopNav from './TopNav';
// import BottomNav from './BottomNav';
// import CustomCheckbox from './CustomCheckbox';
// import { useNavigation } from "@react-navigation/native";
// import { AppContext } from '../../App';

// export default function ActivityScreen() {
//     const navigation = useNavigation();
//     const { selectedActivities, setSelectedActivities, selectedActivityItemsServerFormat, setSelectedActivityItemsServerFormat } = useContext(AppContext)
//   const [activities, setActivities] = useState([
//     { id: '1', label: 'Social outings', checked: false },
//     { id: '2', label: 'Leaving fun', checked: false },
//     { id: '3', label: 'Personal care routines (bathroom, tooth brushing, bathing)', checked: false },
//     { id: '4', label: 'In the car or bus', checked: false },
//     { id: '5', label: 'Meals', checked: false },
//     { id: '6', label: 'Appointments (doctor, dentist)', checked: false },
//     { id: '7', label: 'Playtime', checked: false },
//     { id: '8', label: 'Transitions between daily tasks (getting ready in morning, home from school, bedtime)', checked: false },
//     { id: '9', label: 'Other', checked: false },
//   ]);


//   const optionMapping = {
//     'Social outings': 'Social outings',
//     'Leaving fun': 'Leaving fun',
//     'Personal care routines (bathroom, tooth brushing, bathing)': 'Personal care routines',
//     'In the car or bus': 'In the car or bus',
//     'Meals': 'Meals',
//     'Appointments (doctor, dentist)': 'Appointments',
//     'Playtime': 'Playtime',
//     'Transitions between daily tasks (getting ready in morning, home from school, bedtime)': 'Transitions between daily tasks',
//     'Other': 'Other'
//   };

//   const transformSelectedOptions = (selectedOptions) => {
//     console.log("Options being transformed:", selectedOptions);
//     return selectedOptions.map(option => {
//       const transformed = optionMapping[option.label] || option.label;
//       console.log(`Transforming ${option.label} to ${transformed}`);
//       return transformed;
//     });
//   };
//       // Initialize behaviors based on selectedActivities
//       useEffect(() => {
//         if (selectedActivities.length > 0) {
//           const updatedActivities = activities.map(activity => ({
//             ...activity,
//             checked: selectedActivities.some(selected => selected.id === activity.id),
//           }));
//           setActivities(updatedActivities);
//         }
//       }, []); // Run only once on component mount
  
//         // Update selectedActivities whenever activities change
//     useEffect(() => {
//       const newselectedActivities = activities.filter(activity => activity.checked);
//       setSelectedActivities(newselectedActivities);
//       console.log("Selected Items:", newselectedActivities);
  
//       const transformedOptions = transformSelectedOptions(newselectedActivities);
      
//       console.log("Transformed Options:", transformedOptions);
//       setSelectedActivityItemsServerFormat(transformedOptions);
//     }, [activities]);

//   const toggleCheckbox = (id) => {
//     setActivities(activities.map(item =>
//       item.id === id ? { ...item, checked: !item.checked } : item
//     ));
//   };

//   const handleSubmit = () => {
//     const selectedActivities = activities.filter(a => a.checked);
//     // navigation.navigate('DataBehavior')
//     console.log('Selected activities:', selectedActivities);
//     navigation.goBack()
//   };

//   return (
//     <SafeAreaView style={styles.container}>
//       <TopNav />
      
//       <View style={styles.headerContainer}>
//         <Text style={styles.headerText}>Activity</Text>
//       </View>

//       <ScrollView style={styles.content}>
//         <Text style={styles.instruction}>
//           Select one or two options, then hit submit
//         </Text>

//         {activities.map((item) => (
//           <TouchableOpacity
//             key={item.id}
//             style={styles.checkboxContainer}
//             onPress={() => toggleCheckbox(item.id)}
//           >
//             <CustomCheckbox checked={item.checked} />
//             <Text style={styles.checkboxLabel}>{item.label}</Text>
//           </TouchableOpacity>
//         ))}
        
//         <TouchableOpacity 
//           style={styles.submitButton}
//           onPress={handleSubmit}
//         >
//           <Text style={styles.submitButtonText}>Submit</Text>
//         </TouchableOpacity>

//         {/* Add extra padding at bottom for scrolling past the bottom nav */}
//         <View style={styles.bottomPadding} />
//       </ScrollView>

//       <BottomNav />
//     </SafeAreaView>
//   );
// }

// const styles = StyleSheet.create({
//     container: {
//         flex: 1,
//         backgroundColor: '#fff',
//       },
//       headerContainer: {
//         backgroundColor: '#e5e5e5',
//         padding: 15,
//         alignItems: 'center',
//         borderBottomWidth: 1,
//         borderBottomColor: '#e0e0e0',
//       },
//       headerText: {
//         fontSize: 20,
//         color: '#666',
//         fontFamily: 'chewy-regular',
    
//       },
//       content: {
//         flex: 1,
//         padding: 20,
//       },
//       instruction: {
//         fontSize: 16,
//         color: '#333',
//         marginBottom: 20,
//         fontFamily: 'System',
//       },
//       checkboxContainer: {
//         flexDirection: 'row',
//         alignItems: 'center',
//         padding: 15,
//         backgroundColor: '#f5f5f5',
//         borderRadius: 8,
//         marginBottom: 10,
//         borderWidth: 1,
//         borderColor: '#e0e0e0',
//       },
//       checkboxLabel: {
//         flex: 1,
//         marginLeft: 10,
//         fontSize: 16,
//         color: '#333',
//         fontFamily: 'System',
//       },
//       submitButton: {
//         backgroundColor: '#64B5F6',
//         padding: 15,
//         borderRadius: 8,
//         alignItems: 'center',
//         marginVertical: 20,
//         width: "20%",
//         alignSelf: "center"
//       },
//       submitButtonText: {
//         color: '#fff',
//         fontSize: 16,
//         fontWeight: '600',
//         fontFamily: 'System',
//       },
// });

import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import CustomCheckbox from './CustomCheckbox';
import { useNavigation } from "@react-navigation/native";
import { AppContext } from '../../App';

// If your CustomCheckbox component doesn't support the disabled prop, you can use this one
// const CustomCheckbox = ({ checked, disabled }) => (
//   <Icon 
//     name={checked ? "checkbox-marked" : "checkbox-blank-outline"} 
//     size={24} 
//     color={disabled && !checked ? "#cccccc" : checked ? "#64B5F6" : "#e0e0e0"}
//   />
// );

export default function ActivityScreen() {
    const navigation = useNavigation();
    const { selectedActivities, setSelectedActivities, selectedActivityItemsServerFormat, setSelectedActivityItemsServerFormat } = useContext(AppContext);
    const [totalSelected, setTotalSelected] = useState(0);
    
    const [activities, setActivities] = useState([
      { id: '1', label: 'Social outings', checked: false },
      { id: '2', label: 'Leaving fun', checked: false },
      { id: '3', label: 'Personal care routines (bathroom, tooth brushing, bathing)', checked: false },
      { id: '4', label: 'In the car or bus', checked: false },
      { id: '5', label: 'Meals', checked: false },
      { id: '6', label: 'Appointments (doctor, dentist)', checked: false },
      { id: '7', label: 'Playtime', checked: false },
      { id: '8', label: 'Transitions between daily tasks (getting ready in morning, home from school, bedtime)', checked: false },
      { id: '9', label: 'Other', checked: false },
    ]);

    const optionMapping = {
      'Social outings': 'Social outings',
      'Leaving fun': 'Leaving fun',
      'Personal care routines (bathroom, tooth brushing, bathing)': 'Personal care routines',
      'In the car or bus': 'In the car or bus',
      'Meals': 'Meals',
      'Appointments (doctor, dentist)': 'Appointments',
      'Playtime': 'Playtime',
      'Transitions between daily tasks (getting ready in morning, home from school, bedtime)': 'Transitions between daily tasks',
      'Other': 'Other'
    };

    const transformSelectedOptions = (selectedOptions) => {
      console.log("Options being transformed:", selectedOptions);
      return selectedOptions.map(option => {
        const transformed = optionMapping[option.label] || option.label;
        console.log(`Transforming ${option.label} to ${transformed}`);
        return transformed;
      });
    };

    // Initialize activities based on selectedActivities and count total selected
    useEffect(() => {
      if (selectedActivities.length > 0) {
        let count = 0;
        const updatedActivities = activities.map(activity => {
          const isChecked = selectedActivities.some(selected => selected.id === activity.id);
          if (isChecked) count++;
          return {
            ...activity,
            checked: isChecked,
          };
        });
        setActivities(updatedActivities);
        setTotalSelected(count);
      }
    }, []); // Run only once on component mount

    // Update selectedActivities whenever activities change
    useEffect(() => {
      const newSelectedActivities = activities.filter(activity => activity.checked);
      setSelectedActivities(newSelectedActivities);
      console.log("Selected Items:", newSelectedActivities);

      const transformedOptions = transformSelectedOptions(newSelectedActivities);
      
      console.log("Transformed Options:", transformedOptions);
      setSelectedActivityItemsServerFormat(transformedOptions);
    }, [activities]);

    // Modified toggle function to enforce the two-selection limit
    const toggleCheckbox = (id) => {
      // Find the activity being toggled
      const activity = activities.find(a => a.id === id);
      
      // If activity is already checked, we're unchecking it
      if (activity.checked) {
        setActivities(activities.map(item =>
          item.id === id ? { ...item, checked: false } : item
        ));
        setTotalSelected(totalSelected - 1);
      } 
      // If activity is not checked and we haven't reached the limit, check it
      else if (totalSelected < 2) {
        setActivities(activities.map(item =>
          item.id === id ? { ...item, checked: true } : item
        ));
        setTotalSelected(totalSelected + 1);
      }
      // If we've reached the limit and trying to check a new activity, don't allow it
    };

    const handleSubmit = () => {
      const selectedActivities = activities.filter(a => a.checked);
      console.log('Selected activities:', selectedActivities);
      navigation.goBack();
    };

    return (
      <SafeAreaView style={styles.container}>
        <TopNav />
        
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Activity</Text>
        </View>

        <ScrollView style={styles.content}>
          <Text style={styles.instruction}>
            Select up to two options, then hit submit
          </Text>

          {activities.map((item) => {
            const isDisabled = totalSelected >= 2 && !item.checked;
            
            return (
              <TouchableOpacity
                key={item.id}
                style={[
                  styles.checkboxContainer,
                  isDisabled && styles.disabledCheckbox
                ]}
                onPress={() => !isDisabled && toggleCheckbox(item.id)}
                disabled={isDisabled}
              >
                <CustomCheckbox checked={item.checked} disabled={isDisabled} />
                <Text style={[
                  styles.checkboxLabel,
                  isDisabled && styles.disabledText
                ]}>
                  {item.label}
                </Text>
              </TouchableOpacity>
            );
          })}
          
          <TouchableOpacity 
            style={styles.submitButton}
            onPress={handleSubmit}
          >
            <Text style={styles.submitButtonText}>Submit</Text>
          </TouchableOpacity>

          {/* Add extra padding at bottom for scrolling past the bottom nav */}
          <View style={styles.bottomPadding} />
        </ScrollView>

        <BottomNav />
      </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    headerContainer: {
        backgroundColor: '#e5e5e5',
        padding: 15,
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    headerText: {
        fontSize: 20,
        color: '#666',
        fontFamily: 'chewy-regular',
    },
    content: {
        flex: 1,
        padding: 20,
    },
    instruction: {
        fontSize: 16,
        color: '#333',
        marginBottom: 20,
        fontFamily: 'System',
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
        marginBottom: 10,
        borderWidth: 1,
        borderColor: '#e0e0e0',
    },
    disabledCheckbox: {
        backgroundColor: '#e0e0e0',
        opacity: 0.7,
    },
    checkboxLabel: {
        flex: 1,
        marginLeft: 10,
        fontSize: 16,
        color: '#333',
        fontFamily: 'System',
    },
    disabledText: {
        color: '#999',
    },
    submitButton: {
        backgroundColor: '#64B5F6',
        padding: 15,
        borderRadius: 8,
        alignItems: 'center',
        marginVertical: 20,
        width: "20%",
        alignSelf: "center"
    },
    submitButtonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '600',
        fontFamily: 'System',
    },
    bottomPadding: {
        height: 20,
    },
});