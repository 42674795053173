import React from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useNavigation } from "@react-navigation/native";

const SelfLoveVideoScreen = () => {
    const navigation = useNavigation();
  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      <ScrollView style={styles.scrollView}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Challenging Behavior</Text>
        </View>
        
        <View style={styles.contentContainer}>
          <Text style={styles.titleText}>
            You're off to a great start!
          </Text>

          <Text style={styles.paragraph}>
            We're now going to ask for some information about your child's challenging behavior to help us create your behavior support plan.
          </Text>

          <Text style={styles.paragraph}>
            Watch the video below for tips on how to collect this information!
          </Text>

          {/* Placeholder for video */}
          <View style={styles.videoPlaceholder} />

          <TouchableOpacity 
  style={styles.nextButton} 
  onPress={() => navigation.navigate('SelfLoveChallengingBehaviorScreen')}
>
  <Text style={styles.nextButtonText}>Next</Text>
</TouchableOpacity>
        </View>
      </ScrollView>
      <BottomNav />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollView: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: '#f2f2f2',
    padding: 15,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontFamily: 'Chewy-Regular',
  },
  contentContainer: {
    padding: 20,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginBottom: 20,
    color: '#000',
    paddingHorizontal: 10,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginBottom: 20,
    color: '#000',
    paddingHorizontal: 10,
  },
  videoPlaceholder: {
    width: '100%',
    aspectRatio: 16/9,
    backgroundColor: '#1a1a1a',
    marginVertical: 20,
  },
  nextButton: {
    backgroundColor: '#5BBFEB',
    paddingVertical: 12,
    borderRadius: 8,
    width: 150,
    marginBottom: 60,
    marginTop: 40,
    alignSelf: 'center',
  },
  nextButtonText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'System',
  },
});

export default SelfLoveVideoScreen;