import React from 'react';
import { useNavigation } from '@react-navigation/native';
import DataBehaviorScreen from './DataBehaviorScreen'; // Import the reusable component
import { useContext } from 'react';
import { AppContext } from '../../App'; // Adjust the import path as needed


const StayPositiveDataBehaviour = () => {
    const navigation = useNavigation();
    
      // Access context to get the selected data
      const {
        selectedAntecedentItemsChecklist,
        selectedAntecedentDirectionsChecklist,
        selectedAntecedentPeopleChecklist,
        selectedBehaviors,
        selectedConsequencesItemsChecklist,
        selectedConsequencesDirectionsChecklist,
        selectedConsequencesPeopleChecklist,
        selectedActivities,
        selfLoveId,
        setSelectedAntecedentItemsChecklist,
        setSelectedAntecedentDirectionsChecklist,
        setSelectedAntecedentPeopleChecklist,
        setSelectedBehaviors,
        setSelectedConsequencesItemsChecklist,
        setSelectedConsequencesDirectionsChecklist,
        setSelectedConsequencesPeopleChecklist,
        setSelectedActivities,
        selectedActivityItemsServerFormat, setSelectedActivityItemsServerFormat,
        selectedAntecedentItemsServerFormat, setSelectedAntecedentItemsServerFormat,
        selectedChild
      } = useContext(AppContext);
    
      const handleSubmitPress = async () => {
        try {
          // Prepare the data for each endpoint
          // const selfLoveId = '190562ce-cedc-4e57-8595-0a9d72cb7ce9'; // Replace with actual self_love_id
    
          // Data for selfLoveBehavior endpoint
          const behaviorData = {
            child_id: selectedChild,
            behavior: selectedBehaviors.map((item) => item.label),
            // other_behavior: selectedBehaviors.find((item) => item.label === 'Other')?.label || null,
          };
    
          // Data for selfLoveAntecedent endpoint
          const antecedentData = {
            child_id: selectedChild,
            items_activities: selectedAntecedentItemsServerFormat,
            // other_items_activities: selectedAntecedentItemsChecklist.find((item) => item.label === 'Other')?.label || null,
            directions: selectedAntecedentDirectionsChecklist.map((item) => item.label),
            // other_directions: selectedAntecedentDirectionsChecklist.find((item) => item.label === 'Other')?.label || null,
            people: selectedAntecedentPeopleChecklist.map((item) => item.label),
            // other_people: selectedAntecedentPeopleChecklist.find((item) => item.label === 'Other')?.label || null,
          };
    
          // Data for selfLoveConsequences endpoint
          const consequencesData = {
            child_id: selectedChild,
            items_activities: selectedConsequencesItemsChecklist.map((item) => item.label),
            // other_items_activities: selectedConsequencesItemsChecklist.find((item) => item.label === 'Other')?.label || null,
            directions: selectedConsequencesDirectionsChecklist.map((item) => item.label),
            // other_directions: selectedConsequencesDirectionsChecklist.find((item) => item.label === 'Other')?.label || null,
            people: selectedConsequencesPeopleChecklist.map((item) => item.label),
            // other_people: selectedConsequencesPeopleChecklist.find((item) => item.label === 'Other')?.label || null,
          };
    
          // Data for selfLoveActivity endpoint
          const activityData = {
            child_id: selectedChild,
            activity: selectedActivityItemsServerFormat,
            // other_activity: selectedActivities.find((item) => item.label === 'Other')?.label || null,
          };
    
          // Send POST requests to all endpoints
          const endpoints = [
            { url: 'https://api.childbehaviorcheck.com/api/challenging-behavior/behavior', data: behaviorData },
            { url: 'https://api.childbehaviorcheck.com/api/challenging-behavior/antecedent', data: antecedentData },
            { url: 'https://api.childbehaviorcheck.com/api/challenging-behavior/consequences', data: consequencesData },
            { url: 'https://api.childbehaviorcheck.com/api/challenging-behavior/activity', data: activityData },
          ];
    
          const requests = endpoints.map((endpoint) =>
            fetch(endpoint.url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(endpoint.data),
            })
          );
    
          // Wait for all requests to complete
          const responses = await Promise.all(requests);
    
          // Check if all responses are successful
          const allSuccessful = responses.every((response) => response.ok);
          if (!allSuccessful) {
            throw new Error('One or more requests failed');
          }
    
            // Clear all states after successful submission
            setSelectedAntecedentItemsChecklist([]);
            setSelectedAntecedentDirectionsChecklist([]);
            setSelectedAntecedentPeopleChecklist([]);
            setSelectedBehaviors([]);
            setSelectedConsequencesItemsChecklist([]);
            setSelectedConsequencesDirectionsChecklist([]);
            setSelectedConsequencesPeopleChecklist([]);
            setSelectedActivities([]);
            setSelectedAntecedentItemsServerFormat([]);
            setSelectedActivityItemsServerFormat([]);
    
          // Navigate to Support screen after all requests are successful
          navigation.navigate('Support');
        } catch (error) {
          console.error('Error submitting data:', error);
          // Handle error (e.g., show an error message to the user)
        }
      };
  
    return (
      <DataBehaviorScreen onSubmit={handleSubmitPress}/>
    );
  };
  
export default StayPositiveDataBehaviour;