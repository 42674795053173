import { useState } from "react"
import { View, Text, StyleSheet, SafeAreaView, TextInput, TouchableOpacity, Dimensions, ScrollView } from "react-native"
import TopNav from "./TopNav"
import BottomNav from "./BottomNav"
import { useNavigation } from "@react-navigation/native";

const windowWidth = Dimensions.get("window").width

export default function StayPositiveScreenTwo() {
  const [phrases, setPhrases] = useState("")
  const [reactions, setReactions] = useState("")
  const [newWays, setNewWays] = useState("")
  const [phrasesHeight, setPhrasesHeight] = useState(55)
  const [reactionsHeight, setReactionsHeight] = useState(55)
  const [newWaysHeight, setNewWaysHeight] = useState(55)
  const navigation = useNavigation();


  // Function to calculate the height based on text wrapping
  const calculateHeight = (text, setHeight) => {
    const textInputWidth = windowWidth - 70 // Width of the TextInput (windowWidth - paddingHorizontal * 2 - borderWidth * 2)
    const averageCharWidth = 10 // Approximate width of a single character
    const lineHeight = 24 // Height of one line of text
    const maxHeight = 150 // Maximum height before scrolling

    // Calculate the number of lines based on text width and TextInput width
    const textWidth = text.length * averageCharWidth
    const numberOfLines = Math.ceil(textWidth / textInputWidth)

    // Calculate the new height
    const newHeight = Math.min(maxHeight, 55 + (numberOfLines - 1) * lineHeight)

    // Update the height only if it has changed
    if (newHeight !== phrasesHeight && setHeight === setPhrasesHeight) {
      setPhrasesHeight(newHeight)
    } else if (newHeight !== reactionsHeight && setHeight === setReactionsHeight) {
      setReactionsHeight(newHeight)
    } else if (newHeight !== newWaysHeight && setHeight === setNewWaysHeight) {
      setNewWaysHeight(newHeight)
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      <ScrollView contentContainerStyle={styles.content}>
        <View style={styles.headerSection}>
          <Text style={styles.headerText}>Focusing on the Positive</Text>
        </View>

        <View style={styles.questionsContainer}>
          <Text style={styles.questionText}>
            Positive attention can look so different for different families! Write down 3 phrases or actions you already
            use to encourage or show love to your child.
          </Text>
          <TextInput
            style={[styles.input, { height: phrasesHeight }]}
            value={phrases}
            onChangeText={(text) => {
              setPhrases(text)
              calculateHeight(text, setPhrasesHeight)
            }}
            placeholder=""
            multiline
            scrollEnabled={phrasesHeight >= 150}
            onFocus={() => {}}
            onBlur={() => {}}
          />

          <Text style={[styles.questionText, styles.subsequentQuestion]}>
            How does your child react to these things?
          </Text>
          <TextInput
            style={[styles.input, { height: reactionsHeight }]}
            value={reactions}
            onChangeText={(text) => {
              setReactions(text)
              calculateHeight(text, setReactionsHeight)
            }}
            placeholder=""
            multiline
            scrollEnabled={reactionsHeight >= 150}
            onFocus={() => {}}
            onBlur={() => {}}
          />

          <Text style={[styles.questionText, styles.subsequentQuestion]}>
            Now, brainstorm 1 or 2 new ways you can show positive attention to your child!
          </Text>
          <TextInput
            style={[styles.input, { height: newWaysHeight }]}
            value={newWays}
            onChangeText={(text) => {
              setNewWays(text)
              calculateHeight(text, setNewWaysHeight)
            }}
            placeholder=""
            multiline
            scrollEnabled={newWaysHeight >= 150}
            onFocus={() => {}}
            onBlur={() => {}}
          />

<TouchableOpacity 
      style={styles.nextButton} 
      onPress={() => navigation.navigate("StayPositiveChallengingBehaviour")}
    >
      <Text style={styles.nextButtonText}>Next</Text>
    </TouchableOpacity>
        </View>
      </ScrollView>
      <View styles={{width: "100%", position: "fixed", bottom: 0}}>
        <BottomNav navigation={navigation} />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  content: {
    flexGrow: 1,
  },
  headerSection: {
    backgroundColor: "#F0F0F0",
    paddingVertical: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
  },
  headerText: {
    fontSize: 20,
    color: "#666",
    fontFamily: "chewy-regular"
  },
  questionsContainer: {
    paddingHorizontal: 20,
    paddingTop: 30,
  },
  questionText: {
    fontSize: 16,
    textAlign: "center",
    color: "#000",
    lineHeight: 32,
    marginBottom: 20,
  },
  subsequentQuestion: {
    marginTop: 30,
  },
  input: {
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 8,
    paddingHorizontal: 15,
    paddingTop: 10, // Added padding at the top
    fontSize: 18,
    backgroundColor: "#fff",
    textAlignVertical: "top", // Ensure text starts from the top
  },
  nextButton: {
    backgroundColor: "#64B5F6",
    width: windowWidth * 0.3,
    height: 50,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 40,
    marginBottom: 40,
  },
  nextButtonText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "400",
  },
})