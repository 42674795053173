import React, { useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import ChallengingBehaviorScreen from './ChallengingBehaviorScreen'; // Import the reusable component
import { AppContext } from '../../App';

const SetUpTheDayChallengingBehavior = () => {
  const navigation = useNavigation();
  const {
    user, 
    listOfActivities,
    hasComplicatedActivity,
    dailyScheduleId,
    routineName,
    listOfSteps,
    setDailyScheduleId,
  } = useContext(AppContext);

  const handleYesPress = () => {
    createDailySchedule("Yes");
  };

  const handleNoPress = () => {
    createDailySchedule("No");
  };

  const createDailySchedule = async (option) => {
    try {
     
      const response = await fetch("https://api.childbehaviorcheck.com/api/dailySchedule", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user,
          list_of_activities: listOfActivities,
          has_complicated_activity_or_not:  hasComplicatedActivity,
        }),
      });

      if (response.status !== 201) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Daily Schedule Response:", data);
      setDailyScheduleId(data.daily_schedule_id);

      if (hasComplicatedActivity === "Yes") {
        await saveComplicatedActivitySteps(data.daily_schedule_id);
      }
      if (option === "Yes") {
        navigation.navigate('SetUpTheDayDataBehaviour');
      } else {
        navigation.navigate('Support');
      }
    } catch (error) {
      console.error("Error creating daily schedule:", error);
    }
  };

  const saveComplicatedActivitySteps = async (scheduleId) => {
    try {
      const response = await fetch("https://api.childbehaviorcheck.com/api/stepsO", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          daily_schedule_id: scheduleId,
          routine_name: routineName,
          list_of_steps: listOfSteps,
        }),
      });

      if (response.status !== 201) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log("Complicated Activity Steps Saved Successfully");
    } catch (error) {
      console.error("Error saving complicated activity steps:", error);
    }
  };

  return (
    <ChallengingBehaviorScreen onYesPress={handleYesPress} onNoPress={handleNoPress} />
  );
};

export default SetUpTheDayChallengingBehavior;
