import React from 'react';
import ContentPage from './PracticeScreenComponent';
import { useNavigation } from "@react-navigation/native";

const SelfLovePage = () => {
  const navigation = useNavigation();
  const headerText = "Engaging in Self-Love";
  const paragraphs = [
    "The most important thing you can do when supporting others is to take care of yourself.",
    "It can be easy to neglect yourself when caring for others, but this often leaves you feeling tired and overwhelmed. Focusing on taking care of your mental and physical health will make the days better for everyone!",
    "Self-love looks different for each of us. Start by thinking about how you show care to those closest to you. Also think about what brings you joy and what you are grateful for.",
    "Make a commitment to doing one new thing to care for yourself today!",
    "Here are some additional resources for taking care of yourself so you can support your child!"
  ];
  const buttons = {
    "Taking Care of Yourself": "selfCareLink",
    "Hot Buttons": "hotButtonsLink",
    "Stay Calm / PROTECT / Connect": "stayCalmLink"
  };
  const onPracticeButtonPress = () => {
    navigation.navigate("selfLove2")
    // Navigate to the next screen or perform an action
    console.log("Practice button pressed");
  };

  return (
    <ContentPage
      headerText={headerText}
      paragraphs={paragraphs}
      buttons={buttons}
      onPracticeButtonPress={onPracticeButtonPress}
    />
  );
};

export default SelfLovePage;