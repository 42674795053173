import React, { useState } from 'react';
import { 
  View, 
  Text, 
  StyleSheet, 
  SafeAreaView, 
  ScrollView, 
  TextInput, 
  TouchableOpacity, 
  KeyboardAvoidingView, 
  Platform,
  Dimensions 
} from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useNavigation } from '@react-navigation/native';
import { useContext } from 'react';
import { AppContext } from '../../App'; // Adjust the import path as needed
const windowWidth = Dimensions.get('window').width;


export default function RoutineSetupScreen() {
  const navigation = useNavigation();
  const [steps, setSteps] = useState(Array(10).fill(''));
  const handleStepChange = (text, index) => {
    const newSteps = [...steps];
    newSteps[index] = text;
    setSteps(newSteps);
  };

  // Access context to get the selected data
  const {
setRoutineName,setListOfSteps, routineName,listOfSteps} = useContext(AppContext);
  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView 
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.keyboardAvoidingView}
      >
        <TopNav />
        
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Setting up the day</Text>
        </View>

        <ScrollView 
          style={styles.content} 
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.scrollContent}
        >
          <Text style={styles.question}>What is the name of the routine?</Text>
          <TextInput
            style={styles.nameInput}
            placeholder="Name"
            placeholderTextColor="#999"
            value={routineName}
            onChangeText={setRoutineName}
          />

          <Text style={styles.question}>Write the steps in order here:</Text>
          
          {Array(10).fill().map((_, index) => (
            <View key={index} style={styles.stepContainer}>
              <Text style={styles.stepNumber}>{index + 1}.</Text>
              <TextInput
                style={styles.stepInput}
                value={steps[index]}
                onChangeText={(text) => handleStepChange(text, index)}
              />
            </View>
          ))}

          <TouchableOpacity style={styles.nextButton} onPress={() => {
    setListOfSteps(steps); // Save before navigating
    navigation.navigate('SetUpTheDayChallengingBehavior');
  }}>
            <Text style={styles.nextButtonText}>Next</Text>
          </TouchableOpacity>
        </ScrollView>

        <BottomNav />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: '#F5F5F5',
    padding: 15,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontFamily: "chewy-regular"
  },
  content: {
    flex: 1,
  },
  scrollContent: {
    paddingHorizontal: 50,
    paddingTop: 30,
    paddingBottom: 20,
  },
  question: {
    fontSize: 16,
    color: '#000',
    marginBottom: 15,
  },
  nameInput: {
    height: 30,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderRadius: 8,
    paddingHorizontal: 15,
    fontSize: 16,
    marginBottom: 30,
    marginLeft: 25,
    backgroundColor: '#fff',
  },
  stepContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    width: '100%',
  },
  stepNumber: {
    fontSize: 18,
    width: 30,
    color: '#000',
  },
  stepInput: {
    flex: 1,
    height: 30,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderRadius: 8,
    paddingHorizontal: 15,
    fontSize: 16,
    backgroundColor: '#fff',
  },
  nextButton: {
    backgroundColor: '#64B5F6',
    height: 40,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginHorizontal: windowWidth * 0.1, // 10% of screen width on each side
    marginBottom: 20,
    alignSelf: "center",
    width: "35%",
  },
  nextButtonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: '500',
  },
});