import React, {useContext} from 'react';
import { useNavigation } from '@react-navigation/native';
import ChallengingBehaviorScreen from './ChallengingBehaviorScreen'; // Import the reusable component
import { AppContext } from "../../App"


const FeelingsChallengingBehaviour = () => {
    const {recognition, setRecognition, acknowledgment, setAcknowledgment, teaching, setTeaching, feelingsId, setFeelingsId, user} = useContext(AppContext)
    const navigation = useNavigation();
    const handleYesPress = () => {
      // Handle yes press logic here
      createFeelings("Yes")
    };
  
    const handleNoPress = () => {
      // Handle no press logic here
      createFeelings("No")
    };

    const createFeelings = async (option) => {
      // Make API call to create feelings universal strategy
      try {
        const response = await fetch("https://api.childbehaviorcheck.com/api/feelings", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "userid": user,
          },
          body: JSON.stringify({
            user_id: user,
            howUserRecognizeFeelings: recognition,
            howUserAcknowledgeFeelings: acknowledgment,
            howChildAcknowledgeFeelings: teaching,
            challenging_behavior_or_not: option
          }),
        });
    
        if (response.status !== 201) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("Feelings response:", data);
        setFeelingsId(data.feelings_id)
        setRecognition("")
        setAcknowledgment("")
        setTeaching("")
    
        if (option === "Yes"){
          navigation.navigate('FeelingsDataBehaviour');
        } else if (option === "No") {
          navigation.navigate('Support');
        }
      } catch (error) {
        console.error("Error creating Feelings support:", error);
      }
    }
  
    return (
      <ChallengingBehaviorScreen onYesPress={handleYesPress} onNoPress={handleNoPress} />
    );
  };
  
export default FeelingsChallengingBehaviour;