"use client"

import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Image,
  Dimensions,
  ScrollView,
  Modal,
  TextInput,
} from "react-native";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";

import { useNavigation } from "@react-navigation/native";
import { AppContext } from "../../App"


   

const windowWidth = Dimensions.get("window").width;

const initialExpectations = [
  {
    id: 1,
    text: "We clean up after ourselves",
    image: require("../../assets/FBSIcons/cloud-orange.jpeg"),
  },
  {
    id: 2,
    text: "We use kind words",
    image: require("../../assets/FBSIcons/oval-blue.png"),
  },
  {
    id: 3,
    text: "We talk and listen to others",
    image: require("../../assets/FBSIcons/square-cyan.jpeg"),
  },
  {
    id: 4,
    text: "We keep our bodies safe",
    image: require("../../assets/FBSIcons/star-yellow.jpeg"),
  },
  {
    id: 5,
    text: "We are honest",
    image: require("../../assets/FBSIcons/cloud-green.jpeg"),
  },
  {
    id: 6,
    text: "We are responsible for actions",
    image: require("../../assets/FBSIcons/oval-orange.png"),
  },
  {
    id: 7,
    text: "We respect people,\nanimals, and things",
    image: require("../../assets/FBSIcons/square-blue.jpeg"),
  },
  {
    id: 8,
    text: "We have fun",
    image: require("../../assets/FBSIcons/star-cyan.jpeg"),
  },
  {
    id: 9,
    text: "Create your own",
    image: require("../../assets/FBSIcons/cloud-yellow.jpeg"),
  },
];

export default function FamilyExpectationsScreen() {
  const {familyExpectations, setFamilyExpectations, clearRulesId, setClearRulesId} = useContext(AppContext)
  const [selectedItems, setSelectedItems] = useState([]);
  const [expectations, setExpectations] = useState(initialExpectations);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newActivity, setNewActivity] = useState("");
  const navigation = useNavigation();
  const handleItemPress = (item) => {
    if (item.text === "Create your own") {
      setIsModalVisible(true); // Show the modal
    } else if (selectedItems.includes(item.text)) {
      setSelectedItems(selectedItems.filter((text) => text !== item.text));
    } else {
      setSelectedItems([...selectedItems, item.text]);
    }
  };


  const onNextClicked = () => {
    console.log("Selected items:", selectedItems);
    // Set the familyExpectations state in the context
    setFamilyExpectations(selectedItems);
    navigation.navigate('ClearRulesChallengingBehaviour');
  };

  const handleAddActivity = () => {
    if (newActivity.trim() !== "") {
      // Get the image for the new expectation (cycle through the first 8 images)
      const imageIndex = (expectations.length - 1) % 8; // Subtract 1 to exclude "Create your own"
      const newExpectation = {
        id: expectations.length + 1, // Generate a unique ID
        text: newActivity.trim(),
        image: expectations[imageIndex].image, // Use the cycled image
      };

      // Insert the new expectation just before "Create your own"
      const updatedExpectations = [...expectations];
      const createYourOwnIndex = updatedExpectations.findIndex(
        (item) => item.text === "Create your own"
      );
      updatedExpectations.splice(createYourOwnIndex, 0, newExpectation);

      setExpectations(updatedExpectations); // Update the expectations array
      setNewActivity(""); // Clear the input
      setIsModalVisible(false); // Close the modal
    }
  };

  const renderExpectation = (item) => (
    <TouchableOpacity
      key={item.id}
      onPress={() => handleItemPress(item)}
      style={styles.expectationContainer}
    >
      <Image source={item.image} style={styles.shapeImage} />
      <Text style={styles.overlayText}>{item.text}</Text>
    </TouchableOpacity>
  );

  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>Making the rules clear</Text>
      </View>
      <ScrollView style={styles.scrollView}>
        <Text style={styles.title}>Family Expectations</Text>

        <Text style={styles.instruction}>
          Select options by tapping them. Select 3 to 5 expectations that are important to you, your child, and other
          family members! Use our examples, or create your own!
        </Text>

        <Text style={styles.subInstruction}>
          Engage your children as much as you can in helping you decide the rules. Once you have settled on the
          expectations, post them in a high-traffic area of your home, and reference them often throughout the day.
        </Text>

        <View style={styles.shapesGrid}>{expectations.map((item) => renderExpectation(item))}</View>

        <View style={styles.selectedFrame}>
          {selectedItems.map((text, index) => (
            <Text key={index} style={styles.selectedText}>
             {text}
            </Text>
          ))}
        </View>

        <TouchableOpacity 
          style={styles.nextButton} 
          onPress={onNextClicked}
        >
          <Text style={styles.nextButtonText}>Next</Text>
        </TouchableOpacity>
      </ScrollView>

      {/* Modal for "Create your own" */}
      <Modal
        visible={isModalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setIsModalVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Create your own</Text>
            <TextInput
              style={styles.modalInput}
              value={newActivity}
              onChangeText={setNewActivity}
              autoFocus={true}
              placeholder=""
            />
            <View style={styles.modalButtonContainer}>
              <TouchableOpacity style={styles.modalButton} onPress={() => setIsModalVisible(false)}>
                <Text style={styles.modalButtonText}>Cancel</Text>
              </TouchableOpacity>
              <View style={styles.modalButtonSeparator} />
              <TouchableOpacity style={styles.modalButton} onPress={handleAddActivity}>
                <Text style={styles.modalButtonText}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <BottomNav />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  scrollView: {
    flex: 1,
  },
  headerText: {
    fontSize: 20,
    color: "#666",
    fontFamily: "chewy-regular",
    textAlign: "center",
  },
  headerContainer: {
    backgroundColor: "#f2f2f2",
    padding: 15,
    alignItems: "center",
    borderBottomWidth: 1,
    marginBottom: 22,
    borderBottomColor: "#e0e0e0",
  },
  title: {
    fontSize: 24,
    fontWeight: "regular",
    textAlign: "center",
    marginBottom: 20,
  },
  instruction: {
    fontSize: 16,
    textAlign: "center",
    marginHorizontal: 20,
    marginBottom: 15,
    lineHeight: 22,
  },
  subInstruction: {
    fontSize: 16,
    textAlign: "center",
    marginHorizontal: 20,
    marginBottom: 30,
    lineHeight: 22,
  },
  shapesGrid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: "#fff",
    paddingHorizontal: 0,
  },
  expectationContainer: {
    width: windowWidth / 3 - 20,
    height: windowWidth / 3 - 20,
    margin: 10,
    justifyContent: "center",
    backgroundColor: "#fff",
    alignItems: "center",
  },
  shapeImage: {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    resizeMode: "contain",
  },
  overlayText: {
    position: "absolute",
    color: "#fff",
    fontSize: 14,
    textAlign: "center",
    paddingHorizontal: 10,
    fontWeight: "500",
  },
  selectedFrame: {
    marginHorizontal: 50,
    marginVertical: 20,
    borderWidth: 2,
    borderColor: "#000",
    borderRadius: 15,
    padding: 15,
    minHeight: 150,
  },
  selectedText: {
    fontSize: 16,
    marginBottom: 8,
    alignSelf: "center",
    color: "#000",
  },
  nextButton: {
    backgroundColor: "#64B5F6",
    marginHorizontal: windowWidth * 0.35,
    marginBottom: 50,
    padding: 15,
    borderRadius: 8,
    alignItems: "center",
  },
  nextButtonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "500",
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  modalContent: {
    width: "55%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: 20,
    overflow: "hidden",
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 20,
    textAlign: "center",
    color: "#000",
  },
  modalInput: {
    backgroundColor: "#fff",
    borderRadius: 9,
    padding: 15,
    marginHorizontal: 20,
    marginBottom: 20,
    fontSize: 16,
    height: 30,
    borderWidth: 0,
  },
  modalButtonContainer: {
    flexDirection: "row",
    borderTopWidth: 0.5,
    borderTopColor: "rgba(0, 0, 0, 0.1)",
    height: 50,
  },
  modalButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalButtonSeparator: {
    width: 0.5,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  modalButtonText: {
    color: "#007AFF",
    fontSize: 17,
    fontWeight: "400",
  },
});