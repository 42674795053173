"use client"

import { useState, useContext } from "react"
import { View, Text, TouchableOpacity, StyleSheet, Modal, TextInput, ActivityIndicator } from "react-native"
import { AppContext } from "../../App" 
import { Ionicons } from "@expo/vector-icons" // Make sure you have @expo/vector-icons installed

const questions = [
  "How have you felt about your child's behavior lately?",
  "Have you tried any of the strategies we've discussed / from the BSP?",
  "What components of the app have been most helpful?",
]

const Questionnaire = ({ visible, onClose }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const { user } = useContext(AppContext) 

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      setError(null)

      // Format the answers for the API
      const formattedAnswers = {
        child_behavior_feelings: answers[0] || "",
        strategies_tried: answers[1] || "",
        helpful_app_components: answers[2] || "",
      }

      console.log("Submitting answers:", formattedAnswers)

      // Send the answers to the API
      const response = await fetch("https://api.childbehaviorcheck.com/api/questionnaire", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: user, // Assuming user is the user ID
        },
        body: JSON.stringify(formattedAnswers),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Response from API:", data)

      // Clear the answers
      setAnswers({})
      setCurrentQuestionIndex(0)

      // Close the modal
      onClose()
    } catch (error) {
      console.error("Error submitting questionnaire:", error)
      setError("Failed to submit your answers. Please try again later.")
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleAnswerChange = (text) => {
    setAnswers({
      ...answers,
      [currentQuestionIndex]: text,
    })
  }

  return (
    <Modal visible={visible} transparent={true} animationType="slide">
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <Ionicons name="close" size={24} color="#666" />
          </TouchableOpacity>
          
          <Text style={styles.titleText}>Please take a moment to answer a few questions about your experience</Text>
          <Text style={styles.questionText}>{questions[currentQuestionIndex]}</Text>
          <TextInput
            style={styles.input}
            onChangeText={handleAnswerChange}
            value={answers[currentQuestionIndex] || ""}
            placeholder="Your answer"
            multiline
          />

          {error && <Text style={styles.errorText}>{error}</Text>}

          <View style={styles.buttonContainer}>
            {currentQuestionIndex > 0 && (
              <TouchableOpacity style={styles.button} onPress={handlePrevious} disabled={isSubmitting}>
                <Text style={styles.buttonText}>Previous</Text>
              </TouchableOpacity>
            )}

            {currentQuestionIndex < questions.length - 1 ? (
              <TouchableOpacity style={styles.button} onPress={handleNext} disabled={isSubmitting}>
                <Text style={styles.buttonText}>Next</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={[styles.button, isSubmitting && styles.disabledButton]}
                onPress={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <ActivityIndicator color="white" size="small" />
                ) : (
                  <Text style={styles.buttonText}>Submit</Text>
                )}
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    width: "80%",
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    position: "relative", // Needed for absolute positioning of close button
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    padding: 5,
    zIndex: 1,
  },
  titleText: {
    fontSize: 15,
    marginBottom: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10, // Added to prevent text from being hidden under close button
  },
  questionText: {
    fontSize: 14,
    marginBottom: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
    minHeight: 100,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#27AFDE",
    padding: 10,
    borderRadius: 5,
    minWidth: 100,
    alignItems: "center",
  },
  disabledButton: {
    backgroundColor: "#a0d8eb",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
  },
  errorText: {
    color: "red",
    marginBottom: 10,
    textAlign: "center",
  },
})

export default Questionnaire