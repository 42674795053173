import React from 'react';
import ContentPage from './PracticeScreenComponent';
import { useNavigation } from "@react-navigation/native";

const SelfLovePage = () => {
  const navigation = useNavigation();
  const headerText = "Making the rules clear";
  const paragraphs = [
    "Children are most successful when they know what you expect of them.",
    "Develop 3 to 5 family expectations or rules such as “We are kind with our words,” and remind them of the rules throughout the day.",
    "Including your child in developing these rules will increase their engagement and understanding!",
    "Here are some resources to guide you as you create and use these expectations!",
  ];
  const buttons = {
    "Setting Clear Behavior Expectations": "selfCareLink",
    "Redirections": "hotButtonsLink",
  };
  const onPracticeButtonPress = () => {
    navigation.navigate("FamilyExpectationsScreen")
    // Navigate to the next screen or perform an action
    console.log("Practice button pressed");
  };

  return (
    <ContentPage
      headerText={headerText}
      paragraphs={paragraphs}
      buttons={buttons}
      onPracticeButtonPress={onPracticeButtonPress}
    />
  );
};

export default SelfLovePage;