import React from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useNavigation } from "@react-navigation/native";

const ChallengingBehaviorScreen = ({ onYesPress, onNoPress }) => {
  const navigation = useNavigation();
  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      <ScrollView style={styles.scrollView}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Challenging Behavior</Text>
        </View>
        
        <View style={styles.contentContainer}>
          <Text style={styles.titleText}>
            Way to go! You are on the way to improving how you and your child interact!
          </Text>

          <Text style={styles.paragraph}>
            You can do this – just keep showing up! We're taking small steps at first, and want to support you and your family as you progress toward bigger goals.
          </Text>

          <Text style={styles.paragraph}>
            Come back tomorrow for your next strategy!
          </Text>

          <Text style={styles.questionText}>
            Did your child engage in challenging behavior today?
          </Text>

          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button}>
              <Text style={styles.buttonText} onPress={onNoPress}>No</Text>
            </TouchableOpacity>
            
            <TouchableOpacity 
  style={styles.button} 
  onPress={onYesPress}
>
  <Text style={styles.buttonText}>Yes</Text>
</TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <BottomNav />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollView: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: '#f2f2f2',
    padding: 15,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontFamily: 'chewy-regular',
  },
  contentContainer: {
    padding: 20,
    paddingTop: 40,
    alignItems: 'center',
  },
  titleText: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginBottom: 30,
    color: '#000',
    paddingHorizontal: 20,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginBottom: 30,
    color: '#000',
    paddingHorizontal: 20,
  },
  questionText: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginBottom: 30,
    color: '#000',
    paddingHorizontal: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
    width: '100%',
    paddingHorizontal: 20,
  },
  button: {
    backgroundColor: '#5BBFEB',
    paddingVertical: 12,
    paddingHorizontal: 40,
    borderRadius: 8,
    width: '45%',
    marginBottom: 270,
    marginTop: 20,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'System',
  },
});

export default ChallengingBehaviorScreen;