import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, TextInput, TouchableOpacity, StyleSheet, SafeAreaView, Dimensions, StatusBar } from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import DropDownPicker from "react-native-dropdown-picker"
import { AppContext } from '../../App';
import { useNavigation } from "@react-navigation/native";

const { height: screenHeight } = Dimensions.get("window")

const SelfLoveSupportScreen = () => {
  // const [selectedChild, setSelectedChild] = useState(null);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [professionals, setProfessionals] = useState([]);
  // const [children, setChildren] = useState([]); // State for children
  // const [childContainers, setChildContainers] = useState([{ id: 1, showAddButton: true }]); // State for child containers

  const {user, selectedChild, setSelectedChild} = useContext(AppContext);
  const navigation = useNavigation();
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(null)
  const [professionals, setProfessionals] = useState([{ name: "", email: "" }])
  const [filteredProfessionals, setFilteredProfessionals] = useState([{ name: "", email: "" }])
  const [modalVisible, setModalVisible] = useState(false)
  const [statusBarHeight, setStatusBarHeight] = useState(0)
  const [height, setHeight] = useState(100)
  const [childrenData, setChildrenData] = useState([])
  

  useEffect(() => {
    // Subtract 20% from the screen height
    StatusBar.currentHeight && setStatusBarHeight(StatusBar.currentHeight)
    setHeight(screenHeight)
  }, [])

  // const handleAddChildrenContainer = () => {
  //   // Hide the "Add" button of the last container
  //   const updatedContainers = childContainers.map((container, index) => {
  //     if (index === childContainers.length - 1) {
  //       return { ...container, showAddButton: false };
  //     }
  //     return container;
  //   });

  //   // Add a new container with a visible "Add" button
  //   setChildContainers([
  //     ...updatedContainers,
  //     { id: childContainers.length + 1, showAddButton: true },
  //   ]);
  // };

  useEffect(() => {
    console.log("Starting to fetch user children")
    // Fetch children from API
    const fetchUserChildren = async () => {
      try {
        const userId = user;
        console.log(`User id is : ${userId}`)
        if (userId) {
          const response = await fetch(
            `https://api.childbehaviorcheck.com/api/children/user/${userId}`,
            {
              method: "GET",
              // headers: {
              //   userid: userId,
              // },
            }
          );
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
          console.log("user children", data)
  
          // Transform data into childrenData

          const transformedChildrenData = data.children_data.map((child) => ({
            label: child.child_name,
            value: child.child_id,
          }));

          setChildrenData(transformedChildrenData);
          // setSelectedChild(0);
        

        }
      } catch (error) {
        console.error("Error fetching user children", error);
      }
    };
  
    fetchUserChildren();
  }, [user]);

  const addNewInputPair = () => {
    setProfessionals([...professionals, { name: "", email: "" }])
  }

  useEffect(() => {
    setSelectedChild(value)
  }, [value])

  const updateProfessional = (index, field, value) => {
    const updatedProfessionals = [...professionals]
    updatedProfessionals[index][field] = value
    setProfessionals(updatedProfessionals)
  }

  const handleInviteAll = async () => {
    console.log("Selected child:", selectedChild)
    console.log("Inviting professionals:", professionals)
  
    // Validate professionals array
    const validProfessionals = professionals.filter((professional) => professional.name !== "" && professional.email !== "");
  
    if (validProfessionals.length === 0) {
      console.error("No valid professionals to invite");
      return;
    }
  
    setFilteredProfessionals(validProfessionals)
    // Make API call to invite professionals
    try {
      const response = await fetch("https://api.childbehaviorcheck.com/api/professionals/send-invites", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "userid": user,
        },
        body: JSON.stringify({
          professionals: validProfessionals,
          childId: selectedChild,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Invitation response:", data);
  
      // Navigate to challanging behavior screen
      navigation.navigate('selfLoveVideo');

    } catch (error) {
      console.error("Error inviting professionals:", error);
    }
  }

  return (
    <SafeAreaView style={[
      styles.container,
      {
        marginTop: statusBarHeight,
        height: height,
      }
    ]}>
      <TopNav />
      <ScrollView style={styles.scrollView}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Support Team</Text>
        </View>

        <View style={styles.contentContainer}>
          <Text style={styles.paragraph}>
            To share your child's information, enter the names and emails of professionals that work with your child below. This could be therapists, teachers, child care providers, or physicians.
          </Text>

          <Text style={styles.paragraph}>
            These professionals will be able to see your child's info, data, and behavior support plan. They'll also be able to put in their own data that you'll be able to see. They can't make any changes to anything in your account, and you can revoke access any time by tapping the X next to their name.
          </Text>

          <Text style={styles.paragraph}>
            When you're done adding professionals, click on the Invite button to proceed to the next page. You can also come back to this page any time by tapping the menu button in the top left corner.
          </Text>
{/* 
          <View style={styles.dropdownContainer}>
            <TouchableOpacity
              style={styles.dropdown}
              onPress={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <Text style={styles.dropdownText}>
                {selectedChild || 'Select a child'}
              </Text>
            </TouchableOpacity>

            {isDropdownOpen && (
              <View style={styles.dropdownContent}>
                {children.length === 0 ? (
                  <Text style={styles.noChildrenText}>There's nothing to show!</Text>
                ) : (
                  <ScrollView style={styles.childrenList}>
                    {children.map((child, index) => (
                      <TouchableOpacity
                        key={index}
                        style={styles.childItem}
                        onPress={() => {
                          setSelectedChild(child);
                          setIsDropdownOpen(false);
                        }}
                      >
                        <Text style={styles.childText}>{child}</Text>
                      </TouchableOpacity>
                    ))}
                  </ScrollView>
                )}
              </View>
            )}
          </View>

          <ScrollView style={styles.formContainer}>
            {childContainers.map((container, index) => (
              <View key={container.id} style={styles.addChildrenContainer}>
                <View style={styles.childInputRow}>
                  <TextInput
                    style={styles.childInputName}
                    placeholder=""
                  />
                  <TextInput
                    style={styles.childInputEmail}
                    placeholder=""
                  />
                </View>
                {container.showAddButton && (
                  <TouchableOpacity
                    style={styles.addButton}
                    onPress={handleAddChildrenContainer}
                  >
                    <Text style={styles.addText}>Add</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </ScrollView> */}

          <View style={styles.dropdownContainer}>
            <DropDownPicker
              open={open}
              value={value}
              items={childrenData}
              setOpen={setOpen}
              setValue={setValue}
              style={styles.dropdown}
              dropDownContainerStyle={styles.dropdownList}
              placeholder="Select child"
            />
          </View>

          <ScrollView style={styles.inputScrollView}>
            {professionals.map((professional, index) => (
              <View key={index} style={styles.inputContainer}>
                <TextInput
                  style={styles.nameInput}
                  placeholder="Name"
                  value={professional.name}
                  onChangeText={(text) => updateProfessional(index, "name", text)}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Email"
                  value={professional.email}
                  onChangeText={(text) => updateProfessional(index, "email", text)}
                  keyboardType="email-address"
                />
                {index === professionals.length - 1 && (
                  <TouchableOpacity style={styles.addButton} onPress={addNewInputPair}>
                    <Text style={styles.addButtonText}>Add</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </ScrollView>

        <TouchableOpacity style={styles.inviteButton} onPress={handleInviteAll}>
          <Text style={styles.inviteButtonText}>Invite all professionals</Text>
        </TouchableOpacity>
        </View>
      </ScrollView>
      <View styles={{width: "100%", position: "fixed", bottom: 0}}>
        <BottomNav />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    position: "absolute",
  },
  scrollView: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: '#f2f2f2',
    height: 50,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerText: {
    fontSize: 19,
    color: '#666',
    fontWeight: '500',
    marginTop: 10,
    fontFamily: 'chewy-regular',
  },
  contentContainer: {
    padding: 20,
    justifyContent: 'center',
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 20,
    lineHeight: 24,
    color: '#333',
  },
  // formContainer: {
  //   backgroundColor: '#fff',
  //   borderRadius: 10,
  //   borderWidth: 1,
  //   borderColor: '#ddd',
  //   padding: 0,
  //   height: 200, // Fixed height for scrolling
  //   marginBottom: 20,
  // },
  // dropdownContainer: {
  //   position: 'relative',
  //   zIndex: 2,
  // },
  // dropdown: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  //   borderWidth: 1,
  //   borderColor: '#ddd',
  //   borderRadius: 8,
  //   padding: 15,
  //   height: 45,
  //   marginBottom: 15,
  //   backgroundColor: '#fff',
  // },
  // dropdownText: {
  //   fontSize: 16,
  //   color: '#333',
  // },
  // dropdownContent: {
  //   position: 'absolute',
  //   top: 40,
  //   left: 0,
  //   right: 0,
  //   borderWidth: 1,
  //   borderColor: '#ddd',
  //   borderRadius: 8,
  //   backgroundColor: '#fff',
  //   maxHeight: 200,
  //   zIndex: 20,
  // },
  // childrenList: {
  //   maxHeight: 200,
  // },
  // childItem: {
  //   padding: 15,
  //   borderBottomWidth: 1,
  //   borderBottomColor: '#ddd',
  // },
  // childText: {
  //   fontSize: 16,
  //   color: '#333',
  // },
  // noChildrenText: {
  //   padding: 15,
  //   color: '#666',
  //   textAlign: 'center',
  // },
  // addChildrenContainer: {
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  // },
  // childInputRow: {
  //   flex: 1,
  //   flexDirection: 'row',
  //   marginRight: 10,
  // },
  // childInputName: {
  //   flex: 0.20,
  //   borderWidth: 1,
  //   borderColor: '#ddd',
  //   borderRadius: 8,
  //   padding: 10,
  //   margin: 10,
  // },
  // childInputEmail: {
  //   flex: 0.5,
  //   borderWidth: 1,
  //   borderColor: '#ddd',
  //   borderRadius: 8,
  //   padding: 10,
  //   margin: 10,
  // },
  // addText: {
  //   color: '#007AFF',
  //   fontSize: 16,
  // },
  // addButton: {
  //   margin: 30,
  // },

  dropdownContainer: {
    zIndex: 1000,
    marginBottom: 20,
    marginTop: 12
  },
  dropdown: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 8,
  },
  dropdownList: {
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  inputScrollView: {
    flex: 1,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 8,
    width: "100%",
    padding: 8,
    maxHeight: (screenHeight * 0.3)
  },
  inputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  nameInput: {
    paddingHorizontal: 12,
    paddingVertical: 0,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 8,
    flex: 1,
    marginRight: 6,
    width: "20%"
  },
  input: {
    padding: 12,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 8,
    flex: 1,
    marginRight: 6,
    width: "70%"
  },
  addButton: {
    justifyContent: "center",
    alignItems: "center",
    width: "20%"
  },
  addButtonText: {
    color: "#27AFDE",
    fontSize: 16,
    fontWeight: "500",
  },

  inviteButton: {
    backgroundColor: '#e0e0e0',
    borderRadius: 8,
    padding: 15,
    alignItems: 'center',
    width: '45%',
    alignSelf: 'center',
  },
  inviteButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '500',
  },
});

export default SelfLoveSupportScreen;