import React from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";

const PracticeButton = ({ onPress }) => {
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Text style={styles.text}>Put it in Practice!</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#F5A329", // slightly lighter than #F58220
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 20,
    width: "90%",
    alignSelf: "center",

  },
  text: {
    color: "#fff",
    fontSize: 27,
    fontWeight: "bold",
       fontFamily: "chewy-regular"
  },
});

export default PracticeButton;
