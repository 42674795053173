import React from 'react';
import { useNavigation } from '@react-navigation/native';
import ChallengingBehaviorScreen from './ChallengingBehaviorScreen'; // Import the reusable component


const StayPositiveChallengingBehaviour = () => {
    const navigation = useNavigation();
    const handleYesPress = () => {
      // Handle yes press logic here
      navigation.navigate('StayPositiveDataBehaviour');
    };
  
    const handleNoPress = () => {
      // Handle no press logic here
      navigation.navigate('Support');
    };
  
    return (
      <ChallengingBehaviorScreen onYesPress={handleYesPress} onNoPress={handleNoPress} />
    );
  };
  
export default StayPositiveChallengingBehaviour;