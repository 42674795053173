// import React, { useState, useRef, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Keyboard,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { VStack, HStack } from "native-base";
import { LinearGradient } from "expo-linear-gradient";
import { AppContext } from "../../App";
import HistroyLoading from "../components/HistroyLoading.js";
import LoginRequiredModal from "../components/LoginRequiredModal.js";
import VideoIcon from "../../assets/icons/video-camera-alt.png";
import { useFonts } from "expo-font";
import { useNavigation } from "@react-navigation/native";
import React, { useState, useRef, useContext, useEffect } from "react";
import Toast from 'react-native-root-toast';
import { v4 as uuidv4 } from "uuid";



const { height, width } = Dimensions.get('window');
const endingMessages = [
  "You're welcome! Remember, you're doing a great job, and we're here to help whenever you need it. Take care!",
  "You're welcome! Don't hesitate to contact us if you have more questions in the future. Take care!",
  "You're welcome! Remember, small steps lead to big changes. Feel free to reach out if you have more questions. Good luck!",
  "You're welcome! We're here to help guide you in supporting your child's development. Feel free to reach out if you have more questions in the future. Take care!",
  "You're welcome! Don't hesitate to contact us if you have more questions in the future. Best of luck!",
  "Glad I could help! Remember, parenting is a journey, and seeking guidance is a sign of strength. Take care and don't hesitate to contact us if you have more questions in the future.",
  "Glad to be of assistance! Remember, every child is unique and finding the right approach can take time. Take care and don't hesitate to get in touch if you have more questions in the future.",
  "Glad to help! Take care, and don't hesitate to contact us if you need more support in the future.",
  "Glad to assist! Don't hesitate to contact us if you have more questions in the future. Take care!",
  "You're welcome! Don't hesitate to contact us if you have more questions in the future. Best of luck and take care!",
  "You're welcome! Remember, every step forward is progress. Don't hesitate to get in touch if you need more advice in the future. Take care!",
  "You're welcome! It's great that you're seeking ways to support your daughter. Don't hesitate to contact us if you need more help in the future. Take care!",
  "You're welcome! Remember, parenting is a journey with its unique challenges. Don't hesitate to contact us if you need more help in the future. Take care!",
  "You're welcome! It's great that you're seeking ways to understand and guide your daughter's behavior. Don't hesitate to contact us if you need more help in the future. Take care!",
  "You're very welcome. It's great to see you being so proactive and supportive of your daughter's needs. Please remember, we're here to help you navigate these challenges. Don't hesitate to reach out if you need more support. Take care!",
  "You're welcome! It's understandable that mornings have been tough, but it's commendable how you're seeking solutions. We're here to support you and your child through this journey. Don't hesitate to get in touch if you need more help. Take care!",
  "You're very welcome. It's great that you're seeking support and willing to try new approaches with your child. Please reach out if you need more assistance or just someone to talk to. Take care!",
  "Alright, take all the time you need. Remember, small steps can lead to big changes over time. If you have any questions later on or need further guidance, don't hesitate to reach out. We're here to support you and your daughter. Take care, and best of luck with everything!",
  "I'm glad we could come up with a plan together. Remember, we're here to support you, so don't hesitate to get in touch if you need further assistance or just someone to talk to. Take care, and I hope the new routine brings more peaceful nights.",
  "You're welcome! It's wonderful that you're proactive in seeking solutions for your child's development. We're here to support you, so please don't hesitate to get in touch if you need more help in the future. Take care and good luck with the new strategies!",
  "Great, don't hesitate to reach out if you need further assistance. Best of luck tonight, and take care!",
  "Great, I'm glad we found a potential starting point. Remember, consistency and patience are key. Don't hesitate to reach out if you need more support or if new concerns arise. Take care and good luck tonight!",
  "You're welcome! I'm glad we could find a strategy that feels right for you. Don't hesitate to contact us if any other concerns arise or if you just need some advice. Good luck, and take care!",
  "Okay, starting with this plan is a good move. Remember, small steps can make a big difference. We're here to support you and your daughter through this. Please keep us updated on how things go, and don't hesitate to contact us for further assistance. Take care!",
  "Great, taking that first step to communicate with the staff is important. Remember, we're here to support you through this process. Don't hesitate to reach out if you need more advice or if new concerns arise. Good luck, and take care!",
  "You're welcome! It's important to address one challenge at a time, and you're doing a great job seeking solutions for your son. If the pet idea comes up again or if there are other concerns, we're here to help. Good luck with the new homework strategy, and take care!",
  "I'm glad we could identify a potential strategy to help with the homework challenges. Remember, change takes time and consistency. Feel free to reach out if you need further support or just want to share updates. Good luck, and take care!",
  "Focusing on these strategies is a good plan. Remember, every child and situation is unique, so it's okay to adapt as you go. Don't hesitate to reach out if you have questions or need further support. Good luck, and take care!",
  "You're welcome! It's great that you're taking proactive steps to help your daughter overcome her discomfort. We're here to support you, so feel free to reach out anytime. Take care, and good luck with the outdoor adventures!",
  "You're welcome! I'm glad we could find a new strategy to explore. I'm looking forward to hearing how it goes. Take care, and best of luck with the next outing!",
  "Starting with these strategies is a great first step. Remember, change takes time, and there might be bumps along the way, but consistency is key. We're here to support you, so don't hesitate to get in touch if you need more help. Good luck, and take care!",
  "You're welcome! It's great that you're actively seeking to support your son. Don't hesitate to contact us if you need more help in the future. Take care!",
  "You're welcome! I'm glad I could help. Feel free to contact us if you need further assistance in the future. Take care!",
  "You're welcome! It's good to see you taking steps to understand and address your daughter's behavior. Don't hesitate to contact us if you have more questions in the future. Take care!",
  "You're welcome! I'm glad I could help. Don't hesitate to contact us if you need more assistance in the future. Take care!",
  "You're very welcome! Remember, change takes time and consistency. We're here to support you whenever you need. Take care!",
  "Of course, I'm here to help however I can. It's important to remember that progress can be slow and requires patience. If any new concerns arise or you just need to talk, don't hesitate to reach out. Take care!",
  "You're very welcome. I'm here to support you and your family through these challenges. Don't hesitate to get in touch if you need anything in the future. Take care and good luck!",
  "You're very welcome. It's been a pleasure to assist you, and I'm here whenever you need further support. Wishing you and your daughter peaceful and restful nights ahead. Take care!",
  "You're very welcome. I'm here to help, and it's important to me that you feel supported and understood. If any new questions or concerns arise, please don't hesitate to get in touch. Wishing you and your son the best with your new bedtime routine. Take care!",
  "You're welcome! It's been a pleasure to assist you. Please don't hesitate to reach out if you need further support in the future. Best of luck with your mealtime adventures, and take care!",
  "You're welcome! Remember, consistency is key when implementing new strategies. We're here to support you, so don't hesitate to reach out if you need more guidance. Take care!",
  "You're welcome! It's great to see you taking these steps to support your child. Remember we're always here if you need us. Take care!",
  "You're welcome! Wishing you all the best as you try these new approaches. Don't hesitate to contact us if you need anything else. Take care!"
];

export default function Page() {
    const { data, setMenuOpen, menuOpen, user, machineId, isConnected, currentChatSummary, setCurrentChatSummary, currentChild } =
      useContext(AppContext);
    const navigation = useNavigation();
    const [text, setText] = useState("");
    const [initialText, setInitialText] = useState("");
    const [loading, setLoading] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showHistroyLoadingModal, setShowHistroyLoadingModal] = useState(false);
    const [showBehaviorSupportPrompt, setShowBehaviorSupportPrompt] = useState(false);
    const [isAnalyzingBehavior, setIsAnalyzingBehavior] = useState(false);


    const isEndingMessage = (message) => {
      return endingMessages.some(endingMsg => message.includes(endingMsg));
    };
  
    const [chatHistory, setChatHistory] = useState([
      {
        role: "assistant",
        content: "What challenge can I help you with ?",
      },
    ]);
    useEffect(() => {
      if (user != null && currentChatSummary != 0) {
        handleGetHistory();
      }
    }, [user]);
    useEffect(() => {
      console.log(initialText);
      if (initialText !== "") {
        setLoading(true);
        console.log(initialText);
        handleSubmit();
      }
    }, [initialText]);
    const scrollViewRef = useRef();
    const handleChangeText = (value) => {
      setText(value);
    };
    const scrollToBottom = () => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }
    };

    // const scrollViewRef = useRef();

    useEffect(() => {
        // Scroll to the bottom when chat history updates
        scrollViewRef.current?.scrollToEnd({ animated: true });
    }, [chatHistory]);
  
    // const handleSubmit = async () => {
    //   console.log("Send button clicked!"); 
    //   Keyboard.dismiss();
    //   if (!isConnected) {
    //     let toast = Toast.show("No Internet Connection! Try Again", {
    //       duration: Toast.durations.SHORT,
    //     });
  
    //     return;
    //   }
      
    //   let toQuestion = text;
    //   setText("");
    //   if (user == null && chatHistory.length >= 6) {
    //     setShowLoginModal(true);
    //     return;
    //   }
    //   setLoading(true);
    //   try {
    //     const response = await fetch(
    //       "https://chat.childbehaviorcheck.com/generic/assistant",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           question: toQuestion,
    //           history: chatHistory,
    //         }),
    //       }
    //     );
  
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
  
    //     const data = await response.json();
    //     console.log(data.success.message);

    //     const assistantMessage = data.success.message;
    //     if (assistantMessage.includes("[ending]") || isEndingMessage(assistantMessage) || assistantMessage.includes("Take care!") || assistantMessage.includes("if you have more questions in the future") || assistantMessage.toLowerCase().includes("Best of luck")) {
    //       setShowBehaviorSupportPrompt(true);
    //     }

    //     if (currentChatSummary == 0)
    //     setChatHistory([
    //       ...chatHistory,
    //       {
    //         role: "user",
    //         content: toQuestion,
    //       },
    //       {
    //         role: "assistant",
    //         content: data.success.message,
    //       },
    //     ]);
    //     if (user != null)
    //       await handleSaveHistory(toQuestion, data.success.message);
    //   } catch (error) {
    //     setChatHistory([
    //       ...chatHistory,
    //       {
    //         role: "user",
    //         content: toQuestion,
    //       },
    //       {
    //         role: "assistant",
    //         content: "",
    //       },
    //     ]);
    //     console.error("Error:", error);
    //   }
    //   scrollToBottom();
  
    //   setLoading(false);
    // };

    const handleSubmit = async () => {
      console.log("Send button clicked!")
      Keyboard.dismiss()
      if (!isConnected) {
        const toast = Toast.show("No Internet Connection! Try Again", {
          duration: Toast.durations.SHORT,
        })
  
        return
      }
  
      const toQuestion = text
      setText("")
      if (user == null && chatHistory.length >= 6) {
        setShowLoginModal(true)
        return
      }
      setLoading(true)
      try {
        const response = await fetch("https://chat.childbehaviorcheck.com/generic/assistant", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: toQuestion,
            history: chatHistory,
          }),
        })
  
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
  
        const data = await response.json()
        console.log(data.success.message)
  
        const assistantMessage = data.success.message
        if (
          assistantMessage.includes("[ending]") ||
          isEndingMessage(assistantMessage) ||
          assistantMessage.includes("Take care!") ||
          assistantMessage.includes("if you have more questions in the future") ||
          assistantMessage.toLowerCase().includes("Best of luck")
        ) {
          setShowBehaviorSupportPrompt(true)
        }
  
        if (currentChatSummary == 0) {
          // Generate chat summary first and then save history with the new ID
          try {
            const summaryId = await generateChatSummary(toQuestion)
            setChatHistory([
              ...chatHistory,
              {
                role: "user",
                content: toQuestion,
              },
              {
                role: "assistant",
                content: data.success.message,
              },
            ])
            if (user != null) {
              await handleSaveHistory(toQuestion, data.success.message, summaryId)
            }
          } catch (error) {
            console.error("Error with chat summary:", error)
          }
        } else {
          // Use existing chat summary ID
          setChatHistory([
            ...chatHistory,
            {
              role: "user",
              content: toQuestion,
            },
            {
              role: "assistant",
              content: data.success.message,
            },
          ])
          if (user != null) {
            await handleSaveHistory(toQuestion, data.success.message, currentChatSummary)
          }
        }
      } catch (error) {
        setChatHistory([
          ...chatHistory,
          {
            role: "user",
            content: toQuestion,
          },
          {
            role: "assistant",
            content: "",
          },
        ])
        console.error("Error:", error)
      }
      scrollToBottom()
  
      setLoading(false)
    }
  

    const generateChatSummary = async (userQuestion) => {
      try {
        const response = await fetch(
          "https://api.childbehaviorcheck.com/back/history/generate-chat-summary",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // userid: user,
            },
            body: JSON.stringify({ 
              userQuestion,
              child_id: currentChild.value
             }),
          }
        );
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setCurrentChatSummary(data.chat_summary_id);
        const summaryId = data.chat_summary_id
        return summaryId
      } catch (error) {
        console.error("Error generating chat summary:", error);
      }
    };

    const handleSaveHistory = async (msg, responce, chatSummaryId) => {
      try {
        const response = await fetch(
          "https://api.childbehaviorcheck.com/back/history",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userid: user,
            },
            body: JSON.stringify({
              _id: "6593bc7a65e63b8aec728732",
              question: msg,
              status: "complete",
              response: responce,
              machine_id: machineId,
              chat_id: uuidv4(),
              chat_summary_id: chatSummaryId
            }),
          }
        );
  
        if (!response.ok) {
          // setWrong(true);
          let toast = Toast.show("Failed to Save history!", {
            duration: Toast.durations.SHORT,
          });
        }
  
        // user_data.user_id
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const handleDeleteHistory = async () => {
      if (!isConnected) {
        let toast = Toast.show("No Internet Connection! Try Again", {
          duration: Toast.durations.SHORT,
        });
  
        return;
      }
      if (user == null) {
        setChatHistory([
          {
            role: "assistant",
            content: "What challenge can I help you with ?",
          },
        ]);
        return;
      }
      try {
        setShowHistroyLoadingModal(true);
  
        const response = await fetch(
          "https://api.childbehaviorcheck.com/history/delete",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              userid: user,
            },
            body: JSON.stringify({}),
          }
        );
  
        if (!response.ok) {
          // setWrong(true);
          setShowHistroyLoadingModal(false);
  
          let toast = Toast.show("Failed to Clear history!", {
            duration: Toast.durations.SHORT,
          });
        } else {
          setChatHistory([
            {
              role: "assistant",
              content: "What challenge can I help you with ?",
            },
          ]);
          setShowHistroyLoadingModal(false);
  
          let toast0 = Toast.show("History Cleared..", {
            duration: Toast.durations.SHORT,
          });
        }
  
        // user_data.user_id
      } catch (error) {
        setShowHistroyLoadingModal(false);
  
        console.error("Error:", error);
      }
    };
    const handleGetHistory = async () => {
      if (!isConnected) {
        let toast = Toast.show("No Internet Connection! Try Again", {
          duration: Toast.durations.SHORT,
        });
  
        return;
      }
  
      try {
        setShowHistroyLoadingModal(true);
        const response = await fetch(
          "https://api.childbehaviorcheck.com/back/history/get",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userid: user,
              chatsummaryid: currentChatSummary
            },
            body: JSON.stringify({
              _id: "6593bc7a65e63b8aec728732",
            }),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          // setWrong(true);
          setShowHistroyLoadingModal(false);
  
          let toast = Toast.show("Failed to Fetch history!", {
            duration: Toast.durations.SHORT,
          });
        } else if (data.success != null) {
          let temp = [];
  
          for (let i = 0; i < data.success.length; i++) {
            if (data.success[i].type == "apiMessage") {
              temp.push({
                role: "assistant",
                content: data.success[i].message,
              });
            } else if (data.success[i].type == "userMessage") {
              temp.push({
                role: "user",
                content: data.success[i].message,
              });
            }
          }
          console.log("chat history is", temp)
          setChatHistory(temp);
          setShowHistroyLoadingModal(false);
  
          let toast0 = Toast.show("History Fetched..", {
            duration: Toast.durations.SHORT,
          });
        } else {
          setShowHistroyLoadingModal(false);
        }
  
        // user_data.user_id
      } catch (error) {
        setShowHistroyLoadingModal(false);
  
        console.error("Error:", error);
      }
    };
    const formatMsg = (msg) => {
      let temp = msg.replace(/\n\[.*?\]/g, "");
  
      return temp.replace(/\[.*?\]/g, "");
    };


    const analyzeBehaviorAndNavigate = async () => {
      if (!user || !currentChild) {
        Toast.show("User or child information missing", {
          duration: Toast.durations.SHORT,
        });
        return;
      }
    
      setIsAnalyzingBehavior(true);
    
      try {
        const response = await fetch(
          "https://api.childbehaviorcheck.com/back/history/analyze-behavior",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userid: user,
              chatsummaryid: currentChatSummary.toString(),
            },
            body: JSON.stringify({
              _id: "6593bc7a65e63b8aec728732",
              child_id: currentChild.value,
            }),
          }
        );
    
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
    
        const data = await response.json();
        
        if (data.success) {
          navigation.navigate('BehaviorSupportPlan', {
            behaviorAnalysis: data
          });
        } else {
          Toast.show("Failed to analyze behavior", {
            duration: Toast.durations.SHORT,
          });
        }
      } catch (error) {
        console.error("Error analyzing behavior:", error);
        Toast.show("Error analyzing behavior", {
          duration: Toast.durations.SHORT,
        });
      } finally {
        setIsAnalyzingBehavior(false);
      }
    };

    return (
      <SafeAreaView style={[styles.container]}>
        {/* <SideMenu /> */}
         <LoginRequiredModal
          showModal={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        />
        <HistroyLoading
          showModal={showHistroyLoadingModal}
          onClose={() => setShowHistroyLoadingModal(false)}
        />
        <View style={styles.Header}>
          <View style={styles.HeaderLogo}>
            <TouchableOpacity onPress={() => navigation.navigate("Landing")}>
              <Image
                source={require("../../assets/icons/back.png")}
                style={{width:32, height:32}}
                
              />
            </TouchableOpacity>
            <Image
              source={require("../../assets/logo40.png")}
              style={{width:32, height:32}}
            />
            <View style={{width:32, height:32}}></View>
            {/* <TouchableOpacity onPress={() => handleDeleteHistory()}>
              <Image
                source={require("../../assets/icons/reset.png")}
                style={{width:32, height:32}}
              />
            </TouchableOpacity> */}
          </View>
          <View style={styles.HeaderLbl}>
            <Text style={styles.headerlbltxt}>Child Behavior Check-in</Text>
          </View>
        </View>
        <View style={styles.chatContainer}>
          <View style={styles.Chatbox}>
            <ScrollView ref={scrollViewRef} style={styles.scrollContainer}>
              {chatHistory.length == 1 && (
                <>
                  <View style={{ alignItems: "center", marginBottom: 16,  }}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: "Rubik-Regular",
                        marginBottom: 8,
                      }}
                    >
                      Throwing / damaging objects or property behavior
                    </Text>
                    <TouchableOpacity onPress={() => {
                          try {
                            setInitialText("How do you kindly tell your toddler to stop throwing everything?");
                            setText("How do you kindly tell your toddler to stop throwing everything?");
                            console.log(initialText);
                            console.log(text)
                        } catch (error) {
                            console.error("Failed to set text or submit:", error);
                        }
                      }}>
                      <View
                        style={[
                          styles.bot_msg,
                          {
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: "Rubik-Regular",
                          }}
                        >
                          How do you kindly tell your toddler to stop throwing
                          everything?
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <View style={{ alignItems: "center", marginBottom: 16 }}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: "Rubik-Regular",
                        marginBottom: 8,
                      }}
                    >
                      Refusing to follow directions behavior
                    </Text>
                    <TouchableOpacity onPress={() => {
                          try {
                            setInitialText("What are some effective methods of disciplining a child who refuses to listen and cries instead of following directions?");
                            setText("What are some effective methods of disciplining a child who refuses to listen and cries instead of following directions?");
                        } catch (error) {
                            console.error("Failed to set text or submit:", error);
                        }
                      }}>
                      <View
                        style={[
                          styles.bot_msg,
                          {
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                          },
                        ]}
                      >
                           <Text
                              style={{
                                fontSize: 16,
                                fontFamily: "Rubik-Regular",
                              }}
                            >
                              What are some effective methods of disciplining a child
                              who refuses to listen and cries instead of following
                              directions?
                            </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <View style={{ alignItems: "center", marginBottom: 16 }}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: "Rubik-Regular",
                        marginBottom: 8,
                      }}
                    >
                      Tantrums behavior
                    </Text>
                  <TouchableOpacity onPress={() => {
                        try {
                          setInitialText( "How do I stop a child from throwing a tantrum in public?");
                          setText( "How do I stop a child from throwing a tantrum in public?");
                      } catch (error) {
                          console.error("Failed to set text or submit:", error);
                      }
                      }}>
                      <View
                        style={[
                          styles.bot_msg,
                          {
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                          },
                        ]}
                      >
                      <Text
                        style={{
                          fontSize: 16,
                          fontFamily: "Rubik-Regular",
                        }}
                      >
                        How do I stop a child from throwing a tantrum in public?
                      </Text>
                    </View>
                    </TouchableOpacity>
                  </View>
                </>
              )}
  
              {/* <View style={styles.bot_msg}>
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "Rubik-Regular",
                  }}
                >
                  What challenge can I help you with ?
                </Text>
              </View> */}
            {/* <View style={{height: chatHistory.length == 1? height-500 : height-200, paddingBottom: 80}} >
                {chatHistory.map((item, index) =>
                    item.role === "user" ? (
                    <LinearGradient
                        colors={["#81C6DE", "#27AFDE"]}
                        style={styles.user_msg}
                        start={{ x: 0, y: 0 }} // Start from the top left corner
                        end={{ x: 1, y: 1 }} // End at the bottom right corner
                        locations={[0, 1]} // Optional: specify color stops
                        angle={91}
                        key={index}
                    >
                        <Text
                        style={{
                            color: "#fff",
                            fontSize: 16,
                            fontFamily: "Rubik-Regular",
                        }}
                        >
                        {item.content}
                        </Text>
                    </LinearGradient>
                    ): (
                    <View style={styles.bot_msg} key={index}>
                        <Text
                        style={{
                            fontSize: 16,
                            fontFamily: "Rubik-Regular",
                        }}
                        >
                        {formatMsg(item.content)}
                        </Text>
                    </View>
                    )
                )}
            </View> */}
            <View style={{ height: chatHistory.length === 1 ? height - 500 : height - 200, paddingBottom: 200 }}>
                {chatHistory.length > 0 ? (
                    chatHistory.map((item, index) =>
                    item.role === "user" ? (
                        <LinearGradient
                        colors={["#81C6DE", "#27AFDE"]}
                        style={styles.user_msg}
                        start={{ x: 0, y: 0 }} // Start from the top left corner
                        end={{ x: 1, y: 1 }} // End at the bottom right corner
                        locations={[0, 1]} // Optional: specify color stops
                        key={index}
                        >
                        <Text
                            style={{
                            color: "#fff",
                            fontSize: 16,
                            fontFamily: "Rubik-Regular",
                            }}
                        >
                            {item.content}
                        </Text>
                        </LinearGradient>
                    ) : (
                      <View>
                        <View style={styles.bot_msg} key={index}>
                        <Text
                            style={{
                            fontSize: 16,
                            fontFamily: "Rubik-Regular",
                            }}
                        >
                            {formatMsg(item.content)}
                        </Text>
                        </View>
                      </View>
                    )
                    )
                ) : (
                    <Text>No chat history available</Text>
                )}
                {showBehaviorSupportPrompt && (
                <View style={styles.behaviorSupportPrompt}>
                  <Text style={styles.promptText}>
                    Based on the information you entered concerning your challenge, we created a Behavior Support plan that can help you. Would you like to take a look?
                  </Text>
                  <View style={styles.promptButtons}>
                  <TouchableOpacity 
                    style={[styles.yesButton, isAnalyzingBehavior && styles.disabledButton]}
                    onPress={analyzeBehaviorAndNavigate}
                    disabled={isAnalyzingBehavior}
                  >
                    {isAnalyzingBehavior ? (
                      <ActivityIndicator color="#fff" />
                    ) : (
                      <Text style={styles.buttonText}>Yes</Text>
                    )}
                  </TouchableOpacity>
                    <TouchableOpacity 
                      style={styles.noButton}
                      onPress={() => {
                        setShowBehaviorSupportPrompt(false);
                        setText(""); // Reset text input if needed
                      }}
                    >
                      <Text style={styles.buttonText}>Not at the moment</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>

            </ScrollView>
            <View style={{height: 100, backgroundColor: "#E1F4F9"}}></View>
            {loading && (
              <View style={styles.answeringCon}>
                <View style={styles.answering}>
                  <View
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "#5EB0E0",
                      borderRadius: 4,
                      marginRight: 16,
                    }}
                  ></View>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: "Rubik-Medium",
                    }}
                  >
                    Stop answering ...
                  </Text>
                </View>
              </View>
            )}
          </View>
          </View>


          <View style={[styles.inputField, showBehaviorSupportPrompt && { display: 'none' }]}>
            <View style={styles.inputCon}>
              <TextInput
                style={styles.input}
                value={text}
                onChangeText={handleChangeText}
                placeholder="What are you struggling with ..."
                placeholderTextColor="rgba(0, 0, 0, 0.3)"
                autoFocus={false}
                multiline={true}
              />
              {text === "" && (
                <Image
                  style={{ width: 24, height: 16, marginHorizontal: 14 }}
                  source={VideoIcon}
                />
              )}
              {text === "" && (
                <Image
                  style={{}}
                  resizeMode="contain"
                  source={require("../../assets/icons/Microphone.png")}
                />
              )}
              {!(text === "") && (
                <TouchableOpacity onPress={handleSubmit}>
                  <Image
                    style={{ width: 28, height: 28, marginLeft: 14 }}
                    resizeMode="contain"
                    source={require("../../assets/icons/send.png")}
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
      </SafeAreaView>
    );
  }

const styles = StyleSheet.create({
  container: {
    paddingTop: 14,
    backgroundColor: "#E1F4F9",
    flex: 1,
  },
  Header: {
    width: "100%",
    height: 90,
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    zIndex: 1,
    backgroundColor: "#E1F4F9",
  },
  HeaderLogo: {
    height: 40,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    marginBottom: 16,
    marginTop: 5
  },
  HeaderLbl: {
    justifyContent: "center",
  },
  headerlbltxt: {
    fontSize: 24,
    fontFamily: "Rubik-Medium",
  },
  chatContainer: {
    flex: 1,
    marginTop: 34,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingHorizontal: 16,
    marginTop: 80,
  },
  inputField: {
    height: height > 900 ? 100 :height > 740 && height < 899 ? 88 : 88,
    width: "100%",
    backgroundColor: "#DAECF7",
    paddingTop: 14,
    paddingBottom: 25,
    paddingHorizontal: 16,
    bottom: 0,
    position:"fixed",
  },
  inputCon: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: 64,
    borderRadius: 40,
    paddingHorizontal: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    fontSize: 16,
    fontFamily: "Rubik-Regular",
    flex: 1,
  },
  bot_msg: {
    borderRadius: 16,
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
  },
  user_msg: {
    borderRadius: 16,
    padding: 16,
    width: "100%",
    marginVertical: 16,
  },
  gradient: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
  },
  answeringCon: {
    position: "relative",
    bottom: 200,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  answering: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    zIndex: 999,
    borderRadius: 8,
    paddingHorizontal: 53,
    paddingVertical: 19,
  },
  Chatbox: {
    flex: 1,
    position: "relative",
    paddingBottom: 7,
  },

  behaviorSupportPrompt: {
    // position: 'absolute',
    // bottom: height > 900 ? 100 : height > 740 && height < 899 ? 88 : 88,
    // left: 0,
    // right: 0,
    // backgroundColor: '#fff',
    // padding: 16,
    // borderTopWidth: 1,
    // borderTopColor: '#eee',
    marginTop: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
    padding: 16,
    width: "100%",
  },
  promptText: {
    fontSize: 16,
    fontFamily: 'Rubik-Regular',
    marginBottom: 16,
    textAlign: 'center',
  },
  promptButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  yesButton: {
    backgroundColor: '#27AFDE',
    padding: 12,
    borderRadius: 8,
    flex: 1,
    marginRight: 8,
  },
  noButton: {
    backgroundColor: '#ccc',
    padding: 12,
    borderRadius: 8,
    flex: 1,
    marginLeft: 8,
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Rubik-Medium',
  },
  disabledButton: {
    backgroundColor: '#81C6DE', // A lighter color to indicate disabled state
  },
});
