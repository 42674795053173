import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, TextInput, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useNavigation } from "@react-navigation/native";
import { AppContext } from "../../App"

const SelfLoveScreenTwo = () => {
  const navigation = useNavigation();
  const { 
    gratitudeInput,
    supportTeamInput,
    inviteSupport,
    setGratitudeInput,
    setSupportTeamInput,
    setinviteSupport,
   } = useContext(AppContext)

  const handleButtonPress = (option) => {
    setinviteSupport(option);

    // Navigate based on the button option
    if (option === 'No') {
      navigation.navigate('selfLoveVideo');
    } else if (option === 'Yes') {
      navigation.navigate('selfLoveSupport');
    }
  };
  
  useEffect(() => {
    console.log({
      gratitudeInput,
      supportTeamInput,
      inviteSupport: inviteSupport,
    });
  }, [inviteSupport])


  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      <ScrollView style={styles.scrollView}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Engaging in Self-Love</Text>
        </View>
        
        <View style={styles.contentContainer}>
          <Text style={styles.paragraph}>
            Gratitude helps us be present in the moment and develop feelings of self-compassion and joy.
          </Text>

          <Text style={styles.paragraph}>
            Write down three things, people, or experiences you are grateful for. Consider repeating this process every day – here in the app, or in a notebook.
          </Text>

            <TextInput
            style={styles.textInput}
            multiline={true}
            textAlignVertical="top"
            placeholder=""
            blurOnSubmit={true}
            outline = {false}
            onChangeText={setGratitudeInput}
            value={gratitudeInput}
          />
          

          <Text style={styles.paragraph}>
            Another great way to take care of yourself is to strengthen your support system (the group of people you can ask for help when you need it).
          </Text>

          <Text style={styles.subheading}>
            Who is on your support team?
          </Text>

          <Text style={styles.paragraph}>
            List your partner, family members, friends, or community members you can count on.
          </Text>

          <TextInput
            style={styles.textInput}
            multiline={true}
            textAlignVertical="top"
            placeholder=""
            onChangeText={setSupportTeamInput}
            value={supportTeamInput}
          />

          <Text style={styles.paragraph}>
            Does it feel important to invite them to see your child's progress on this app?
          </Text>

          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button}   onPress={() => handleButtonPress('No')}>
              <Text style={styles.buttonText}>No</Text>
            </TouchableOpacity>
            <TouchableOpacity 
              style={styles.button} 
              onPress={() => handleButtonPress('Yes')}
            >
              <Text style={styles.buttonText}>Yes</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <BottomNav />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollView: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: '#f0f0f0',
    padding: 15,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontWeight: '500',
    fontFamily: "chewy-regular"
  },
  contentContainer: {
    padding: 20,
  },
  paragraph: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    lineHeight: 24,
    color: '#333',
  },
  subheading: {
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: 15,
    color: '#333',
  },
  textInput: {
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    height: 50,
    padding: 15,
    marginBottom: 25,
    backgroundColor: '#fff',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    paddingHorizontal: 20,
  },
  button: {
    backgroundColor: '#5BBFEB',
    borderRadius: 8,
    padding: 15,
    width: '45%',
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '500',
  },
});

export default SelfLoveScreenTwo;