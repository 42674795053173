// import React, { useState, useContext, useEffect } from 'react';
// import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';

// import CustomCheckbox from './CustomCheckbox';
// import { useNavigation } from "@react-navigation/native";
// import { AppContext } from '../../App';


// // Import your existing navigation components
// import TopNav from './TopNav';
// import BottomNav from './BottomNav';

// export default function AntecedentScreen() {
//   const navigation = useNavigation();
//   const {setSelectedAntecedentItemsChecklist, setSelectedAntecedentDirectionsChecklist, setSelectedAntecedentPeopleChecklist, selectedAntecedentItemsChecklist, selectedAntecedentDirectionsChecklist, selectedAntecedentPeopleChecklist, selectedAntecedentItemsServerFormat, setSelectedAntecedentItemsServerFormat} = useContext(AppContext);
//   const [activeTab, setActiveTab] = useState('items'); // Removed TypeScript syntax
//   const [itemsChecklist, setItemsChecklist] = useState([
//     { id: '1', label: 'The child has to wait for something (Activity or object)', checked: false },
//     { id: '2', label: 'An activity or event ends (Time to leave playground, bath is over)', checked: false },
//     { id: '3', label: 'An activity or event begins (Mealtime, schoolwork)', checked: false },
//     { id: '4', label: 'An unexpected activity begins (Not part of routine)', checked: false },
//     { id: '5', label: 'An activity is open-ended (open playroom, unfamiliar toys without directions)', checked: false },
//     { id: '6', label: 'A toy or other object is taken away', checked: false },
//     { id: '7', label: 'Another child is playing with a toy', checked: false },
//     { id: '8', label: 'The child is offered choices and does not want any option', checked: false },
//     { id: '9', label: 'The child asks for an object or to do an activity', checked: false },
//     { id: '10', label: 'Other', checked: false },
//   ]);

//   const [directionsChecklist, setDirectionsChecklist] = useState([
//     { id: '1', label: 'The child is told not to do something', checked: false },
//     { id: '2', label: 'The child is given a direction or asked to do something', checked: false },
//     { id: '3', label: "The child is told it's time to clean up", checked: false },
//     { id: '4', label: 'The child asks for an activity to end', checked: false },
//     { id: '5', label: 'Child refuses to follow a direction or complete a task', checked: false },
//     { id: '6', label: 'The child asks for permission to not follow a direction', checked: false },
//     { id: '7', label: 'Other', checked: false },
//   ]);

//   const [peopleChecklist, setPeopleChecklist] = useState([
//     { id: '1', label: 'Someone is talking to the child', checked: false },
//     { id: '2', label: 'The child is playing with other children', checked: false },
//     { id: '3', label: 'An adult is leading an activity', checked: false },
//     { id: '4', label: 'An adult is focused on another person or task', checked: false },
//     { id: '5', label: 'An adult shifts attention away from the child', checked: false },
//     { id: '6', label: 'The child expresses frustration', checked: false },
//     { id: '7', label: 'The child asks for attention', checked: false },
//     { id: '8', label: 'The child asks for help', checked: false },
//     { id: '9', label: 'Other', checked: false },
//   ]);


//     // Initialize checklists based on context values
//     useEffect(() => {
//       if (selectedAntecedentItemsChecklist.length > 0) {
//         const updatedItemsChecklist = itemsChecklist.map(item => ({
//           ...item,
//           checked: selectedAntecedentItemsChecklist.some(selectedItem => selectedItem.id === item.id)
//         }));
//         setItemsChecklist(updatedItemsChecklist);
//       }
  
//       if (selectedAntecedentDirectionsChecklist.length > 0) {
//         const updatedDirectionsChecklist = directionsChecklist.map(item => ({
//           ...item,
//           checked: selectedAntecedentDirectionsChecklist.some(selectedItem => selectedItem.id === item.id)
//         }));
//         setDirectionsChecklist(updatedDirectionsChecklist);
//       }
  
//       if (selectedAntecedentPeopleChecklist.length > 0) {
//         const updatedPeopleChecklist = peopleChecklist.map(item => ({
//           ...item,
//           checked: selectedAntecedentPeopleChecklist.some(selectedItem => selectedItem.id === item.id)
//         }));
//         setPeopleChecklist(updatedPeopleChecklist);
//       }
//     }, []);
  

//   const toggleCheckbox = (id, type) => {
//     const updateChecklist = (list) =>
//       list.map((item) =>
//         item.id === id ? { ...item, checked: !item.checked } : item
//       );

//     switch (type) {
//       case 'items':
//         setItemsChecklist(updateChecklist(itemsChecklist));
//         break;
//       case 'directions':
//         setDirectionsChecklist(updateChecklist(directionsChecklist));
//         break;
//       case 'people':
//         setPeopleChecklist(updateChecklist(peopleChecklist));
//         break;
//       default:
//         break;
//     }
//   };

//   const renderChecklist = (items, type) => {
//     return items.map((item) => (
//       <TouchableOpacity
//         key={item.id}
//         style={styles.checkboxContainer}
//         onPress={() => toggleCheckbox(item.id, type)}
//       >
//       <CustomCheckbox checked={item.checked} />
//         <Text style={styles.checkboxLabel}>{item.label}</Text>
//       </TouchableOpacity>
//     ));
//   };

//   const transformSelectedOptions = (selectedOptions) => {
//     console.log("Options being transformed:", selectedOptions);
//     return selectedOptions.map(option => {
//       const transformed = optionMapping[option.label] || option.label;
//       console.log(`Transforming ${option.label} to ${transformed}`);
//       return transformed;
//     });
//   };

//   // Filter selected items and update context
//   useEffect(() => {
//     const selectedItems = itemsChecklist.filter(item => item.checked);
//     setSelectedAntecedentItemsChecklist(selectedItems);
//     console.log("Selected Items:", selectedItems);
  
//     const transformedOptions = transformSelectedOptions(selectedItems);
    
//     console.log("Transformed Options:", transformedOptions);
    
//     setSelectedAntecedentItemsServerFormat(transformedOptions);
//   }, [itemsChecklist]); // Use the entire checklist as the dependency

//   useEffect(() => {
//     const selectedDirections = directionsChecklist.filter(item => item.checked);
//     setSelectedAntecedentDirectionsChecklist(selectedDirections);
//   }, [directionsChecklist]); // Use the entire checklist as the dependency

//   useEffect(() => {
//     const selectedPeople = peopleChecklist.filter(item => item.checked);
//     setSelectedAntecedentPeopleChecklist(selectedPeople);
//   }, [peopleChecklist]); // Use the entire checklist as the dependency


//   const optionMapping = {
//     'The child has to wait for something (Activity or object)': 'The child has to wait for something',
//     'An activity or event ends (Time to leave playground, bath is over)': 'An activity or event ends',
//     'An activity or event begins (Mealtime, schoolwork)': 'An activity or event begins',
//     'An unexpected activity begins (Not part of routine)': 'An unexpected activity begins',
//     'An activity is open-ended (open playroom, unfamiliar toys without directions)': 'An activity is open-ended',
//     'A toy or other object is taken away': 'A toy or other object is taken away',
//     'Another child is playing with a toy': 'Another child is playing with a toy',
//     'The child is offered choices and does not want any option': 'The child is offered choices and does not want any option',
//     'The child asks for an object or to do an activity': 'The child asks for an object or to do an activity',
//     'Other': 'Others'
//   };


//   return (
//     <SafeAreaView style={styles.container}>
//       <TopNav />

//       <Text style={styles.titleText}>Antecedent</Text>

//       <View style={styles.tabContainer}>
//         <TouchableOpacity
//           style={[styles.tab, activeTab === 'items' && styles.activeTab]}
//           onPress={() => setActiveTab('items')}
//         >
//           <Text style={[styles.tabText, activeTab === 'items' && styles.activeTabText]}>
//             Items/Activities
//           </Text>
//         </TouchableOpacity>

//         <TouchableOpacity
//           style={[styles.tab, activeTab === 'directions' && styles.activeTab]}
//           onPress={() => setActiveTab('directions')}
//         >
//           <Text style={[styles.tabText, activeTab === 'directions' && styles.activeTabText]}>
//             Directions
//           </Text>
//         </TouchableOpacity>

//         <TouchableOpacity
//           style={[styles.tab, activeTab === 'people' && styles.activeTab]}
//           onPress={() => setActiveTab('people')}
//         >
//           <Text style={[styles.tabText, activeTab === 'people' && styles.activeTabText]}>
//             People
//           </Text>
//         </TouchableOpacity>
//       </View>

//       <ScrollView style={styles.content}>
//         {activeTab === 'items' && (
//           <Text style={styles.instruction}>Select one or two options, then hit submit</Text>
//         )}
//         {activeTab === 'items' && renderChecklist(itemsChecklist, 'items')}
//         {activeTab === 'directions' && renderChecklist(directionsChecklist, 'directions')}
//         {activeTab === 'people' && renderChecklist(peopleChecklist, 'people')}

//         <TouchableOpacity
//           style={styles.submitButton}
//           onPress={() => navigation.goBack()}
//         >
//           <Text style={styles.submitButtonText}>Submit</Text>
//         </TouchableOpacity>
//       </ScrollView>

//       <BottomNav />
//     </SafeAreaView>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//   },
//   titleText: {
//     textAlign: 'center',
//     fontSize: 20,
//     color: '#666',
//     paddingVertical: 15,
//     backgroundColor: '#e5e5e5',
//     fontFamily: "chewy-regular",
//   },
//   tabContainer: {
//     flexDirection: 'row',
//     borderBottomWidth: 1,
//     borderBottomColor: '#ee0e0',
//   },
//   tab: {
//     flex: 1,
//     paddingVertical: 15,
//     alignItems: 'center',
//   },
//   activeTab: {
//     borderBottomWidth: 2,
//     borderBottomColor: '#64B5F6',
//   },
//   tabText: {
//     fontSize: 16,
//     color: '#666',
//   },
//   activeTabText: {
//     color: '#64B5F6',
//   },
//   content: {
//     flex: 1,
//     padding: 20,
//   },
//   instruction: {
//     fontSize: 16,
//     color: '#333',
//     marginBottom: 20,
//   },
//   checkboxContainer: {
//     flexDirection: 'row',
//     alignItems: 'center',
//     padding: 15,
//     backgroundColor: '#f5f5f5',
//     borderRadius: 8,
//     marginBottom: 10,
//     borderWidth: 1,
//     borderColor: '#e0e0e0',
//   },
//   checkboxLabel: {
//     flex: 1,
//     marginLeft: 10,
//     fontSize: 16,
//     color: '#333',
//   },
//   submitButton: {
//     backgroundColor: '#64B5F6',
//     padding: 15,
//     borderRadius: 8,
//     alignItems: 'center',
//     marginVertical: 20,
//     width: "23%",
//     alignSelf: "center"

//   },
//   submitButtonText: {
//     color: '#fff',
//     fontSize: 12,
//     fontWeight: '600',
//   },
// });

import React, { useState, useContext, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';

import CustomCheckbox from './CustomCheckbox';
import { useNavigation } from "@react-navigation/native";
import { AppContext } from '../../App';

// Import your existing navigation components
import TopNav from './TopNav';
import BottomNav from './BottomNav';

export default function AntecedentScreen() {
  const navigation = useNavigation();
  const {setSelectedAntecedentItemsChecklist, setSelectedAntecedentDirectionsChecklist, setSelectedAntecedentPeopleChecklist, selectedAntecedentItemsChecklist, selectedAntecedentDirectionsChecklist, selectedAntecedentPeopleChecklist, selectedAntecedentItemsServerFormat, setSelectedAntecedentItemsServerFormat} = useContext(AppContext);
  const [activeTab, setActiveTab] = useState('items');
  
  // Track total selected items across all tabs
  const [totalSelectedCount, setTotalSelectedCount] = useState(0);
  
  const [itemsChecklist, setItemsChecklist] = useState([
    { id: '1', label: 'The child has to wait for something (Activity or object)', checked: false },
    { id: '2', label: 'An activity or event ends (Time to leave playground, bath is over)', checked: false },
    { id: '3', label: 'An activity or event begins (Mealtime, schoolwork)', checked: false },
    { id: '4', label: 'An unexpected activity begins (Not part of routine)', checked: false },
    { id: '5', label: 'An activity is open-ended (open playroom, unfamiliar toys without directions)', checked: false },
    { id: '6', label: 'A toy or other object is taken away', checked: false },
    { id: '7', label: 'Another child is playing with a toy', checked: false },
    { id: '8', label: 'The child is offered choices and does not want any option', checked: false },
    { id: '9', label: 'The child asks for an object or to do an activity', checked: false },
    { id: '10', label: 'Other', checked: false },
  ]);

  const [directionsChecklist, setDirectionsChecklist] = useState([
    { id: '1', label: 'The child is told not to do something', checked: false },
    { id: '2', label: 'The child is given a direction or asked to do something', checked: false },
    { id: '3', label: "The child is told it's time to clean up", checked: false },
    { id: '4', label: 'The child asks for an activity to end', checked: false },
    { id: '5', label: 'Child refuses to follow a direction or complete a task', checked: false },
    { id: '6', label: 'The child asks for permission to not follow a direction', checked: false },
    { id: '7', label: 'Other', checked: false },
  ]);

  const [peopleChecklist, setPeopleChecklist] = useState([
    { id: '1', label: 'Someone is talking to the child', checked: false },
    { id: '2', label: 'The child is playing with other children', checked: false },
    { id: '3', label: 'An adult is leading an activity', checked: false },
    { id: '4', label: 'An adult is focused on another person or task', checked: false },
    { id: '5', label: 'An adult shifts attention away from the child', checked: false },
    { id: '6', label: 'The child expresses frustration', checked: false },
    { id: '7', label: 'The child asks for attention', checked: false },
    { id: '8', label: 'The child asks for help', checked: false },
    { id: '9', label: 'Other', checked: false },
  ]);

  // Initialize checklists based on context values and count total selected
  useEffect(() => {
    let count = 0;
    
    if (selectedAntecedentItemsChecklist.length > 0) {
      const updatedItemsChecklist = itemsChecklist.map(item => {
        const isChecked = selectedAntecedentItemsChecklist.some(selectedItem => selectedItem.id === item.id);
        if (isChecked) count++;
        return { ...item, checked: isChecked };
      });
      setItemsChecklist(updatedItemsChecklist);
    }

    if (selectedAntecedentDirectionsChecklist.length > 0) {
      const updatedDirectionsChecklist = directionsChecklist.map(item => {
        const isChecked = selectedAntecedentDirectionsChecklist.some(selectedItem => selectedItem.id === item.id);
        if (isChecked) count++;
        return { ...item, checked: isChecked };
      });
      setDirectionsChecklist(updatedDirectionsChecklist);
    }

    if (selectedAntecedentPeopleChecklist.length > 0) {
      const updatedPeopleChecklist = peopleChecklist.map(item => {
        const isChecked = selectedAntecedentPeopleChecklist.some(selectedItem => selectedItem.id === item.id);
        if (isChecked) count++;
        return { ...item, checked: isChecked };
      });
      setPeopleChecklist(updatedPeopleChecklist);
    }
    
    setTotalSelectedCount(count);
  }, []);

  // Modified toggle function to enforce the two-selection limit
  const toggleCheckbox = (id, type) => {
    let currentList;
    let setterFunction;
    
    switch (type) {
      case 'items':
        currentList = itemsChecklist;
        setterFunction = setItemsChecklist;
        break;
      case 'directions':
        currentList = directionsChecklist;
        setterFunction = setDirectionsChecklist;
        break;
      case 'people':
        currentList = peopleChecklist;
        setterFunction = setPeopleChecklist;
        break;
      default:
        return;
    }
    
    // Find the item being toggled
    const item = currentList.find(item => item.id === id);
    
    // If item is already checked, we're unchecking it
    if (item.checked) {
      const updatedList = currentList.map(item => 
        item.id === id ? { ...item, checked: false } : item
      );
      setterFunction(updatedList);
      setTotalSelectedCount(totalSelectedCount - 1);
    } 
    // If item is not checked and we haven't reached the limit, check it
    else if (totalSelectedCount < 2) {
      const updatedList = currentList.map(item => 
        item.id === id ? { ...item, checked: true } : item
      );
      setterFunction(updatedList);
      setTotalSelectedCount(totalSelectedCount + 1);
    }
    // If we've reached the limit and trying to check a new item, don't allow it
  };

  // Modified render function to disable checkboxes when limit is reached
  const renderChecklist = (items, type) => {
    return items.map((item) => {
      // Determine if this checkbox should be disabled
      const isDisabled = totalSelectedCount >= 2 && !item.checked;
      
      return (
        <TouchableOpacity
          key={item.id}
          style={[
            styles.checkboxContainer,
            isDisabled && styles.disabledCheckbox
          ]}
          onPress={() => !isDisabled && toggleCheckbox(item.id, type)}
          disabled={isDisabled}
        >
          <CustomCheckbox checked={item.checked} disabled={isDisabled} />
          <Text style={[
            styles.checkboxLabel,
            isDisabled && styles.disabledText
          ]}>
            {item.label}
          </Text>
        </TouchableOpacity>
      );
    });
  };

  const transformSelectedOptions = (selectedOptions) => {
    console.log("Options being transformed:", selectedOptions);
    return selectedOptions.map(option => {
      const transformed = optionMapping[option.label] || option.label;
      console.log(`Transforming ${option.label} to ${transformed}`);
      return transformed;
    });
  };

  // Filter selected items and update context
  useEffect(() => {
    const selectedItems = itemsChecklist.filter(item => item.checked);
    setSelectedAntecedentItemsChecklist(selectedItems);
    
    const transformedOptions = transformSelectedOptions(selectedItems);
    setSelectedAntecedentItemsServerFormat(transformedOptions);
  }, [itemsChecklist]);

  useEffect(() => {
    const selectedDirections = directionsChecklist.filter(item => item.checked);
    setSelectedAntecedentDirectionsChecklist(selectedDirections);
  }, [directionsChecklist]);

  useEffect(() => {
    const selectedPeople = peopleChecklist.filter(item => item.checked);
    setSelectedAntecedentPeopleChecklist(selectedPeople);
  }, [peopleChecklist]);

  const optionMapping = {
    'The child has to wait for something (Activity or object)': 'The child has to wait for something',
    'An activity or event ends (Time to leave playground, bath is over)': 'An activity or event ends',
    'An activity or event begins (Mealtime, schoolwork)': 'An activity or event begins',
    'An unexpected activity begins (Not part of routine)': 'An unexpected activity begins',
    'An activity is open-ended (open playroom, unfamiliar toys without directions)': 'An activity is open-ended',
    'A toy or other object is taken away': 'A toy or other object is taken away',
    'Another child is playing with a toy': 'Another child is playing with a toy',
    'The child is offered choices and does not want any option': 'The child is offered choices and does not want any option',
    'The child asks for an object or to do an activity': 'The child asks for an object or to do an activity',
    'Other': 'Others'
  };

  return (
    <SafeAreaView style={styles.container}>
      <TopNav />

      <Text style={styles.titleText}>Antecedent</Text>

      <View style={styles.tabContainer}>
        <TouchableOpacity
          style={[styles.tab, activeTab === 'items' && styles.activeTab]}
          onPress={() => setActiveTab('items')}
        >
          <Text style={[styles.tabText, activeTab === 'items' && styles.activeTabText]}>
            Items/Activities
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.tab, activeTab === 'directions' && styles.activeTab]}
          onPress={() => setActiveTab('directions')}
        >
          <Text style={[styles.tabText, activeTab === 'directions' && styles.activeTabText]}>
            Directions
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.tab, activeTab === 'people' && styles.activeTab]}
          onPress={() => setActiveTab('people')}
        >
          <Text style={[styles.tabText, activeTab === 'people' && styles.activeTabText]}>
            People
          </Text>
        </TouchableOpacity>
      </View>

      <ScrollView style={styles.content}>
        {activeTab === 'items' && (
          <Text style={styles.instruction}>
            Select up to two options total across all tabs, then hit submit
          </Text>
        )}
        {activeTab === 'directions' && (
          <Text style={styles.instruction}>
            Select up to two options total across all tabs, then hit submit
          </Text>
        )}
        {activeTab === 'people' && (
          <Text style={styles.instruction}>
            Select up to two options total across all tabs, then hit submit
          </Text>
        )}
        
        {activeTab === 'items' && renderChecklist(itemsChecklist, 'items')}
        {activeTab === 'directions' && renderChecklist(directionsChecklist, 'directions')}
        {activeTab === 'people' && renderChecklist(peopleChecklist, 'people')}

        <TouchableOpacity
          style={styles.submitButton}
          onPress={() => navigation.goBack()}
        >
          <Text style={styles.submitButtonText}>Submit</Text>
        </TouchableOpacity>
      </ScrollView>

      <BottomNav />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  titleText: {
    textAlign: 'center',
    fontSize: 20,
    color: '#666',
    paddingVertical: 15,
    backgroundColor: '#e5e5e5',
    fontFamily: "chewy-regular",
  },
  tabContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ee0e0',
  },
  tab: {
    flex: 1,
    paddingVertical: 15,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#64B5F6',
  },
  tabText: {
    fontSize: 16,
    color: '#666',
  },
  activeTabText: {
    color: '#64B5F6',
  },
  content: {
    flex: 1,
    padding: 20,
  },
  instruction: {
    fontSize: 16,
    color: '#333',
    marginBottom: 20,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#e0e0e0',
  },
  disabledCheckbox: {
    backgroundColor: '#e0e0e0',
    opacity: 0.7,
  },
  checkboxLabel: {
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
    color: '#333',
  },
  disabledText: {
    color: '#999',
  },
  submitButton: {
    backgroundColor: '#64B5F6',
    padding: 15,
    borderRadius: 8,
    alignItems: 'center',
    marginVertical: 20,
    width: "23%",
    alignSelf: "center"
  },
  submitButtonText: {
    color: '#fff',
    fontSize: 12,
    fontWeight: '600',
  },
});