// import React, { useState, useEffect, useContext } from 'react';
// import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
// import TopNav from './TopNav';
// import BottomNav from './BottomNav';
// import { useNavigation } from "@react-navigation/native";
// import { AppContext } from '../../App';
// import DropDownPicker from "react-native-dropdown-picker"

// const DataBehaviorScreen = ({onSubmit}) => {
//     const {
//       user, 
//       selectedChild, 
//       setSelectedChild, 
//       selectedAntecedentItemsChecklist, 
//       selectedAntecedentDirectionsChecklist, 
//       selectedAntecedentPeopleChecklist,

//       selectedBehaviors, 

//       selectedConsequencesItemsChecklist, 
//       selectedConsequencesDirectionsChecklist, 
//       selectedConsequencesPeopleChecklist,

//       selectedActivities,

//     } = useContext(AppContext);
//     const navigation = useNavigation();
//     const [value, setValue] = useState(null)
//     const [childrenData, setChildrenData] = useState([])
//     const [open, setOpen] = useState(false)


//     useEffect(() => {
//       console.log("Starting to fetch user children")
//       // Fetch children from API
//       const fetchUserChildren = async () => {
//         try {
//           const userId = user;
//           console.log(`User id is : ${userId}`)
//           if (userId) {
//             const response = await fetch(
//               `https://api.childbehaviorcheck.com/api/children/user/${userId}`,
//               {
//                 method: "GET",
//                 // headers: {
//                 //   userid: userId,
//                 // },
//               }
//             );
    
//             if (!response.ok) {
//               throw new Error(`HTTP error! status: ${response.status}`);
//             }
    
//             const data = await response.json();
//             console.log("user children", data)
    
//             // Transform data into childrenData
  
//             const transformedChildrenData = data.children_data.map((child) => ({
//               label: child.child_name,
//               value: child.child_id,
//             }));
  
//             setChildrenData(transformedChildrenData);
//             // setSelectedChild(0);
          
  
//           }
//         } catch (error) {
//           console.error("Error fetching user children", error);
//         }
//       };
    
//       fetchUserChildren();
//     }, [user]);
  
  
//     useEffect(() => {
//       setSelectedChild(value)
//     }, [value])


//   return (
//     <SafeAreaView style={styles.container}>
//       <TopNav />
//       <ScrollView style={styles.scrollView}>
//         <View style={styles.headerContainer}>
//           <Text style={styles.headerText}>Data on challenging behavior</Text>
//         </View>
        
//         <View style={styles.contentContainer}>
//           <Text style={styles.instructionText}>
//             Think of one instance of challenging behavior that occurred today. Using the boxes below, mark what happened before the behavior (antecedent), after the behavior (consequence), and during which activity the behavior occurred.
//           </Text>

//           <View style={styles.dropdownContainer}>
//             <DropDownPicker
//               open={open}
//               value={value}
//               items={childrenData}
//               setOpen={setOpen}
//               setValue={setValue}
//               style={styles.dropdown}
//               dropDownContainerStyle={styles.dropdownList}
//               placeholder="Select child"
//             />
//           </View>

//           {/* Antecedent Section */}
//           <View style={styles.sectionContainer}>
//           <TouchableOpacity 
//             style={[styles.sectionHeader, styles.antecedentHeader]} 
//           >
//             <Text style={styles.sectionHeaderText}>Antecedent</Text>
//           </TouchableOpacity>
//           <TouchableOpacity style={[styles.sectionContent, styles.antecedentContent]} onPress={() => navigation.navigate('AntecedentScreen')}>
//             {selectedAntecedentItemsChecklist.length > 0 || selectedAntecedentDirectionsChecklist.length > 0 || selectedAntecedentPeopleChecklist.length > 0 ? 
//               <View>
//                 {[...selectedAntecedentItemsChecklist, ...selectedAntecedentDirectionsChecklist, ...selectedAntecedentPeopleChecklist].map((item, index) => (
//                   <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
//                 ))}
//               </View> : 
//               <Text style={styles.tapText}>Tap to view</Text>
//             }
//           </TouchableOpacity>
//           </View>

//           {/* Behavior Section */}
//           <View style={styles.sectionContainer}>
//             <TouchableOpacity style={[styles.sectionHeader, styles.behaviorHeader]}>
//               <Text style={styles.sectionHeaderText}>Behavior</Text>
//             </TouchableOpacity>
//             <TouchableOpacity 
//               style={[styles.sectionContent, styles.behaviorContent]} 
//               onPress={() => navigation.navigate('BehaviorScreen')}
//             >
//               {selectedBehaviors.length > 0  ? 
//                 <View>
//                   {[...selectedBehaviors].map((item, index) => (
//                     <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
//                   ))}
//                 </View> : 
//                 <Text style={styles.tapText}>Tap to view</Text>
//               }
//             </TouchableOpacity>
//           </View>

//           {/* Consequences Section */}
//           <View style={styles.sectionContainer}>
//             <TouchableOpacity style={[styles.sectionHeader, styles.consequencesHeader]}>
//               <Text style={styles.sectionHeaderText}>Consequences</Text>
//             </TouchableOpacity>
//             <TouchableOpacity 
//               style={[styles.sectionContent, styles.consequencesContent]}
//               onPress={() => navigation.navigate('ConsequencesScreen')}
//             >
//               {selectedConsequencesItemsChecklist.length > 0 || selectedConsequencesDirectionsChecklist.length > 0 || selectedConsequencesPeopleChecklist.length > 0 ? 
//                 <View>
//                   {[...selectedConsequencesItemsChecklist, ...selectedConsequencesDirectionsChecklist, ...selectedConsequencesPeopleChecklist].map((item, index) => (
//                     <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
//                   ))}
//                 </View> : 
//                 <Text style={styles.tapText}>Tap to view</Text>
//               }
//             </TouchableOpacity>
//           </View>

//           {/* Activity Section */}
//           <View style={styles.sectionContainer}>
//             <TouchableOpacity style={[styles.sectionHeader, styles.activityHeader]}>
//               <Text style={styles.sectionHeaderText}>Activity</Text>
//             </TouchableOpacity>
//             <TouchableOpacity
//               style={[styles.sectionContent, styles.activityContent]}
//               onPress={() => navigation.navigate('ActivityScreen')}
//             >
//               {selectedActivities.length > 0  ? 
//                 <View>
//                   {[...selectedActivities].map((item, index) => (
//                     <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
//                   ))}
//                 </View> : 
//                 <Text style={styles.tapText}>Tap to view</Text>
//               }
//             </TouchableOpacity>
//           </View>

//           <TouchableOpacity style={styles.submitButton} >
//             <Text style={styles.submitButtonText} onPress={onSubmit}>Submit</Text>
//           </TouchableOpacity>
//         </View>
//       </ScrollView>
//       <BottomNav />
//     </SafeAreaView>
//   );
// };

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//   },
//   scrollView: {
//     flex: 1,
//   },
//   headerContainer: {
//     backgroundColor: '#f2f2f2',
//     padding: 15,
//     alignItems: 'center',
//     borderBottomWidth: 1,
//     borderBottomColor: '#e0e0e0',
//   },
//   headerText: {
//     fontSize: 20,
//     color: '#666',
//     fontFamily: "chewy-regular"
//   },
//   contentContainer: {
//     padding: 20,
//   },
//   instructionText: {
//     fontSize: 16,
//     lineHeight: 24,
//     marginBottom: 20,
//     color: '#000',
//   },
//   dropdownContainer: {
//     zIndex: 1000,
//     marginBottom: 20,
//     marginHorizontal: 30,
//   },
//   dropdown: {
//     backgroundColor: "transparent",
//     borderWidth: 1,
//     borderColor: "#000",
//     borderRadius: 8,
//   },
//   dropdownList: {
//     backgroundColor: "#fff",
//     borderColor: "#ccc",
//   },
//   // dropdownButton: {
//   //   marginHorizontal: 30,
//   //   flexDirection: 'row',
//   //   justifyContent: 'space-between',
//   //   alignItems: 'center',
//   //   borderWidth: 1,
//   //   borderColor: '#ddd',
//   //   borderRadius: 8,
//   //   padding: 15,
//   //   marginBottom: 20,
//   // },
//   // dropdownText: {
//   //   fontSize: 16,
//   //   color: '#000',
//   // },
//   // chevronText: {
//   //   fontSize: 12,
//   //   color: '#666',
//   // },
//   sectionContainer: {
//     marginBottom: 7,
//     marginHorizontal: 30,
//   },
//   sectionHeader: {
//     padding: 15,
//     borderTopLeftRadius: 0,
//     borderTopRightRadius: 0,
//   },
//   sectionHeaderText: {
//     color: '#fff',
//     fontSize: 16,
//     textAlign: 'center',
//   },
//   sectionContent: {
//     padding: 15,
//     borderBottomLeftRadius: 0,
//     borderBottomRightRadius: 0,
//   },
//   tapText: {
//     textAlign: 'center',
//     fontSize: 16,
//     color: '#000',
//   },
//   tapTextOptions: {
//     textAlign: 'start',
//     fontSize: 16,
//     color: '#000',
//   },
//   // Antecedent colors
//   antecedentHeader: {
//     backgroundColor: '#FF9966',
//   },
//   antecedentContent: {
//     backgroundColor: '#FFE6D9',
//   },
//   // Behavior colors
//   behaviorHeader: {
//     backgroundColor: '#3399FF',
//   },
//   behaviorContent: {
//     backgroundColor: '#E6F3FF',
//   },
//   // Consequences colors
//   consequencesHeader: {
//     backgroundColor: '#00CCC0',
//   },
//   consequencesContent: {
//     backgroundColor: '#E6FFFD',
//   },
//   // Activity colors
//   activityHeader: {
//     backgroundColor: '#9933FF',
//   },
//   activityContent: {
//     backgroundColor: '#F3E6FF',
//   },
//   submitButton: {
//     backgroundColor: '#cccccc',
//     padding: 15,
//     borderRadius: 8,
//     marginTop: 30,
//     marginBottom: 20,
//     width: '40%',
//     alignSelf: 'center',
//   },
//   submitButtonText: {
//     color: '#fff',
//     fontSize: 16,
//     textAlign: 'center',
//   },
// });

// export default DataBehaviorScreen;

import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useNavigation } from "@react-navigation/native";
import { AppContext } from '../../App';
import DropDownPicker from "react-native-dropdown-picker"

const DataBehaviorScreen = ({onSubmit}) => {
    const {
      user, 
      selectedChild, 
      setSelectedChild, 
      selectedAntecedentItemsChecklist, 
      selectedAntecedentDirectionsChecklist, 
      selectedAntecedentPeopleChecklist,

      selectedBehaviors, 

      selectedConsequencesItemsChecklist, 
      selectedConsequencesDirectionsChecklist, 
      selectedConsequencesPeopleChecklist,

      selectedActivities,

    } = useContext(AppContext);
    const navigation = useNavigation();
    const [value, setValue] = useState(null)
    const [childrenData, setChildrenData] = useState([])
    const [open, setOpen] = useState(false)

    // Check if any section has selected items
    const hasAntecedentSelections = selectedAntecedentItemsChecklist.length > 0 || 
                                   selectedAntecedentDirectionsChecklist.length > 0 || 
                                   selectedAntecedentPeopleChecklist.length > 0;
                                   
    const hasBehaviorSelections = selectedBehaviors.length > 0;
    
    const hasConsequenceSelections = selectedConsequencesItemsChecklist.length > 0 || 
                                    selectedConsequencesDirectionsChecklist.length > 0 || 
                                    selectedConsequencesPeopleChecklist.length > 0;
                                    
    const hasActivitySelections = selectedActivities.length > 0;
    
    // Determine if submit button should be active
    const isSubmitActive = selectedChild && 
                          (hasAntecedentSelections && 
                           hasBehaviorSelections && 
                           hasConsequenceSelections && 
                           hasActivitySelections);

    useEffect(() => {
      console.log("Starting to fetch user children")
      // Fetch children from API
      const fetchUserChildren = async () => {
        try {
          const userId = user;
          console.log(`User id is : ${userId}`)
          if (userId) {
            const response = await fetch(
              `https://api.childbehaviorcheck.com/api/children/user/${userId}`,
              {
                method: "GET",
                // headers: {
                //   userid: userId,
                // },
              }
            );
    
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log("user children", data)
    
            // Transform data into childrenData
  
            const transformedChildrenData = data.children_data.map((child) => ({
              label: child.child_name,
              value: child.child_id,
            }));
  
            setChildrenData(transformedChildrenData);
            // setSelectedChild(0);
          
  
          }
        } catch (error) {
          console.error("Error fetching user children", error);
        }
      };
    
      fetchUserChildren();
    }, [user]);
  
  
    useEffect(() => {
      setSelectedChild(value)
    }, [value])


  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      <ScrollView style={styles.scrollView}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Data on challenging behavior</Text>
        </View>
        
        <View style={styles.contentContainer}>
          <Text style={styles.instructionText}>
            Think of one instance of challenging behavior that occurred today. Using the boxes below, mark what happened before the behavior (antecedent), after the behavior (consequence), and during which activity the behavior occurred.
          </Text>

          <View style={styles.dropdownContainer}>
            <DropDownPicker
              open={open}
              value={value}
              items={childrenData}
              setOpen={setOpen}
              setValue={setValue}
              style={styles.dropdown}
              dropDownContainerStyle={styles.dropdownList}
              placeholder="Select child"
            />
          </View>

          {/* Antecedent Section */}
          <View style={styles.sectionContainer}>
          <TouchableOpacity 
            style={[styles.sectionHeader, styles.antecedentHeader]} 
          >
            <Text style={styles.sectionHeaderText}>Antecedent</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.sectionContent, styles.antecedentContent]} onPress={() => navigation.navigate('AntecedentScreen')}>
            {hasAntecedentSelections ? 
              <View>
                {[...selectedAntecedentItemsChecklist, ...selectedAntecedentDirectionsChecklist, ...selectedAntecedentPeopleChecklist].map((item, index) => (
                  <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
                ))}
              </View> : 
              <Text style={styles.tapText}>Tap to view</Text>
            }
          </TouchableOpacity>
          </View>

          {/* Behavior Section */}
          <View style={styles.sectionContainer}>
            <TouchableOpacity style={[styles.sectionHeader, styles.behaviorHeader]}>
              <Text style={styles.sectionHeaderText}>Behavior</Text>
            </TouchableOpacity>
            <TouchableOpacity 
              style={[styles.sectionContent, styles.behaviorContent]} 
              onPress={() => navigation.navigate('BehaviorScreen')}
            >
              {hasBehaviorSelections ? 
                <View>
                  {[...selectedBehaviors].map((item, index) => (
                    <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
                  ))}
                </View> : 
                <Text style={styles.tapText}>Tap to view</Text>
              }
            </TouchableOpacity>
          </View>

          {/* Consequences Section */}
          <View style={styles.sectionContainer}>
            <TouchableOpacity style={[styles.sectionHeader, styles.consequencesHeader]}>
              <Text style={styles.sectionHeaderText}>Consequences</Text>
            </TouchableOpacity>
            <TouchableOpacity 
              style={[styles.sectionContent, styles.consequencesContent]}
              onPress={() => navigation.navigate('ConsequencesScreen')}
            >
              {hasConsequenceSelections ? 
                <View>
                  {[...selectedConsequencesItemsChecklist, ...selectedConsequencesDirectionsChecklist, ...selectedConsequencesPeopleChecklist].map((item, index) => (
                    <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
                  ))}
                </View> : 
                <Text style={styles.tapText}>Tap to view</Text>
              }
            </TouchableOpacity>
          </View>

          {/* Activity Section */}
          <View style={styles.sectionContainer}>
            <TouchableOpacity style={[styles.sectionHeader, styles.activityHeader]}>
              <Text style={styles.sectionHeaderText}>Activity</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.sectionContent, styles.activityContent]}
              onPress={() => navigation.navigate('ActivityScreen')}
            >
              {hasActivitySelections ? 
                <View>
                  {[...selectedActivities].map((item, index) => (
                    <Text key={index} style={styles.tapTextOptions}>{index + 1}. {item.label}</Text>
                  ))}
                </View> : 
                <Text style={styles.tapText}>Tap to view</Text>
              }
            </TouchableOpacity>
          </View>

          <TouchableOpacity 
            style={[
              styles.submitButton, 
              isSubmitActive && styles.activeSubmitButton
            ]} 
            disabled={!isSubmitActive}
          >
            <Text 
              style={styles.submitButtonText} 
              onPress={onSubmit}
            >
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
      <BottomNav />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollView: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: '#f2f2f2',
    padding: 15,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontFamily: "chewy-regular"
  },
  contentContainer: {
    padding: 20,
  },
  instructionText: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
    color: '#000',
  },
  dropdownContainer: {
    zIndex: 1000,
    marginBottom: 20,
    marginHorizontal: 30,
  },
  dropdown: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 8,
  },
  dropdownList: {
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  sectionContainer: {
    marginBottom: 7,
    marginHorizontal: 30,
  },
  sectionHeader: {
    padding: 15,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  sectionHeaderText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
  },
  sectionContent: {
    padding: 15,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tapText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#000',
  },
  tapTextOptions: {
    textAlign: 'start',
    fontSize: 16,
    color: '#000',
  },
  // Antecedent colors
  antecedentHeader: {
    backgroundColor: '#FF9966',
  },
  antecedentContent: {
    backgroundColor: '#FFE6D9',
  },
  // Behavior colors
  behaviorHeader: {
    backgroundColor: '#3399FF',
  },
  behaviorContent: {
    backgroundColor: '#E6F3FF',
  },
  // Consequences colors
  consequencesHeader: {
    backgroundColor: '#00CCC0',
  },
  consequencesContent: {
    backgroundColor: '#E6FFFD',
  },
  // Activity colors
  activityHeader: {
    backgroundColor: '#9933FF',
  },
  activityContent: {
    backgroundColor: '#F3E6FF',
  },
  submitButton: {
    backgroundColor: '#cccccc',
    padding: 15,
    borderRadius: 8,
    marginTop: 30,
    marginBottom: 20,
    width: '40%',
    alignSelf: 'center',
  },
  activeSubmitButton: {
    backgroundColor: '#64B5F6',
  },
  submitButtonText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
  },
});

export default DataBehaviorScreen;