// A simple utility to test push notifications

// Function to convert base64 string to Uint8Array
function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")
  
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }
  
  // Function to register service worker and get subscription
  async function registerServiceWorkerAndSubscribe() {
    console.log("Registering service worker...")
  
    try {
      // Register service worker with cache busting
      const registration = await navigator.serviceWorker.register("/service-worker.js?v=" + Date.now(), {
        scope: "/",
        updateViaCache: "none",
      })
  
      console.log("Service Worker registered:", registration)
  
      // Wait for the service worker to be ready
      await navigator.serviceWorker.ready
      console.log("Service Worker is ready")
  
      // Get existing subscription or create a new one
      let subscription = await registration.pushManager.getSubscription()
  
      if (!subscription) {
        console.log("No subscription found, creating new one...")
  
        // Create new subscription
        const vapidPublicKey = "BIMuqfOmJ-hGMPfC13-tOPrfPPlxm63NTvybzmY6h4XLK-HRv-pJ3yeTF85c1Papatip8_TNNnWaMdfZsbkUKnE"
        const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)
  
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey,
        })
  
        console.log("New subscription created:", subscription)
      } else {
        console.log("Existing subscription found:", subscription)
      }
  
      // Log the subscription details for debugging
      const subscriptionJSON = subscription.toJSON()
      console.log("Subscription JSON:", subscriptionJSON)
      console.log("Endpoint:", subscriptionJSON.endpoint)
      console.log("Keys:", subscriptionJSON.keys)
  
      return subscription
    } catch (error) {
      console.error("Error registering service worker or subscribing:", error)
      throw error
    }
  }
  
  // Function to send subscription to server
  async function sendSubscriptionToServer(subscription) {
    console.log("Sending subscription to server...")
  
    try {
      const response = await fetch("https://api.childbehaviorcheck.com/api/subscriptions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscription }),
      })
  
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`)
      }
  
      const data = await response.json()
      console.log("Server response:", data)
      return data
    } catch (error) {
      console.error("Error sending subscription to server:", error)
      throw error
    }
  }
  
  // Function to test sending a notification
  async function testSendNotification(message = "Test notification") {
    console.log("Testing notification with message:", message)
  
    try {
      const subscription = await registerServiceWorkerAndSubscribe()
  
      // Send the subscription to the server
      await sendSubscriptionToServer(subscription)
  
      // Request the server to send a notification
      const response = await fetch("https://api.childbehaviorcheck.com/api/notifications/one", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: subscription,
          message: message,
          title: "Test Notification",
        }),
      })
  
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`)
      }
  
      const data = await response.json()
      console.log("Notification sent response:", data)
      return data
    } catch (error) {
      console.error("Error testing notification:", error)
      throw error
    }
  }

  // New function to update subscription with user ID
async function updatePushSubscriptionWithUserId(endpoint, userId) {
    console.log("Updating subscription with user ID...")
  
    try {
      const response = await fetch("https://api.childbehaviorcheck.com/api/subscriptions", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          endpoint: endpoint,
          user_id: userId,
        }),
      })
  
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`)
      }
  
      const data = await response.json()
      console.log("Subscription update response:", data)
      return data
    } catch (error) {
      console.error("Error updating subscription:", error)
      throw error
    }
  }
  
  // Export functions
  export { registerServiceWorkerAndSubscribe, sendSubscriptionToServer, testSendNotification, updatePushSubscriptionWithUserId }
  
  