import { useState, useEffect, useContext } from "react"
import { View, Text, StyleSheet, SafeAreaView, TextInput, TouchableOpacity, ScrollView, Dimensions } from "react-native"
import TopNav from "./TopNav"
import BottomNav from "./BottomNav"
import { AppContext } from "../../App"

const windowWidth = Dimensions.get("window").width
const windowHeight = Dimensions.get("window").height
import { useNavigation } from "@react-navigation/native";
export default function FeelingsScreenTwo() {
  const {recognition, setRecognition, acknowledgment, setAcknowledgment, teaching, setTeaching} = useContext(AppContext)
  const navigation = useNavigation();

  const onNextClicked = () => {
    console.log("Inputs:", recognition, acknowledgment, teaching);
    navigation.navigate('FeelingsChallengingBehaviour');
  };

  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      <View style={styles.mainContainer}>
        <View style={styles.headerSection}>
          <Text style={styles.headerText}>Talking about feelings</Text>
        </View>

        <ScrollView style={styles.scrollContent} showsVerticalScrollIndicator={false}>
          <View style={styles.contentContainer}>
            <Text style={styles.paragraphText}>
              Teach your child about feelings the same way you teach them about colors, animals, or your family's
              expectations!
            </Text>

            <Text style={styles.paragraphText}>
              Label feelings as they arise without adding a value of "good" or "bad".
            </Text>

            <Text style={styles.paragraphText}>
              You don't always have to talk about feelings when they happen though, especially if they are big. It's
              useful talking about big feelings later, after they have calmed down.
            </Text>

            <Text style={styles.paragraphText}>
              Talking, labeling, and giving your child space to do the same are the best ways to teach your children to
              express their feelings.
            </Text>

            <Text style={styles.questionText}>How do YOU recognize what you are feeling?</Text>
            <TextInput style={styles.input} value={recognition} onChangeText={setRecognition} placeholder="" />

            <Text style={styles.questionText}>
              How can you acknowledge your feelings without adding value to the feeling?
            </Text>
            <TextInput style={styles.input} value={acknowledgment} onChangeText={setAcknowledgment} placeholder="" />

            <Text style={styles.questionText}>How can you tell your kid to do the same?</Text>
            <TextInput style={styles.input} value={teaching} onChangeText={setTeaching} placeholder="" />

            <TouchableOpacity 
              style={styles.nextButton} 
              onPress={onNextClicked}
            >
              <Text style={styles.nextButtonText}>Next</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    
        <BottomNav />
     
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  mainContainer: {
    flex: 1,
  },
  headerSection: {
    backgroundColor: "#F0F0F0",
    paddingVertical: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
  },
  headerText: {
    fontSize: 20,
    color: "#666",
    fontFamily: "chewy-regular",
  },
  scrollContent: {
    flex: 1,
  },
  contentContainer: {
    padding: 20,
    paddingHorizontal: 40,
    paddingBottom: 40,
  },
  paragraphText: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 25,
    lineHeight: 28,
    color: "#000",
  },
  questionText: {
    fontSize: 16,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 15,
    color: "#000",
    lineHeight: 28,
  },
  input: {
    height: 47,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 8,
    paddingHorizontal: 15,
    fontSize: 18,
    backgroundColor: "#fff",
    marginBottom: 25,
  },
  nextButton: {
    backgroundColor: "#64B5F6",
    width: windowWidth * 0.35,
    height: 50,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 20,
  },
  nextButtonText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "400",
  }
})

