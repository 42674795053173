import React, {useState, useEffect, useContext} from 'react';
import { useNavigation } from '@react-navigation/native';
import ChallengingBehaviorScreen from './ChallengingBehaviorScreen'; // Import the reusable component
import { AppContext } from '../../App';


const SelfLoveChallengingBehaviorScreen = () => {
    const navigation = useNavigation();
    const {
      user, 
      selectedChild,     
      gratitudeInput,
      supportTeamInput,
      inviteSupport,
      setSelfLoveId,
      setGratitudeInput,
      setSupportTeamInput
    } = useContext(AppContext);
    const handleYesPress = () => {
      // Handle yes press logic here
      createSelfLove("Yes")
    };
  
    const handleNoPress = () => {
      // Handle no press logic here
      createSelfLove("No")
    };

    const createSelfLove = async (option) => {
      // Make API call to create selflove
      try {
        const response = await fetch("https://api.childbehaviorcheck.com/api/selfLove", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "userid": user,
          },
          body: JSON.stringify({
            user_id: user,
            grateful_for: gratitudeInput,
            support_team: supportTeamInput,
            invite_support_team_or_not: inviteSupport,
            child: `${selectedChild}`,
            challenging_behavior_or_not: option
          }),
        });
    
        if (response.status !== 201) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("self love response:", data);
        setSelfLoveId(data.self_love_id)
        setGratitudeInput("")
        setSupportTeamInput("")
    
        if (option === "Yes"){
          navigation.navigate('SelfLoveDataBehaviour');
        } else if (option === "No") {
          navigation.navigate('Support');
        }
      } catch (error) {
        console.error("Error creating self love:", error);
      }
    }
  
    return (
      <ChallengingBehaviorScreen onYesPress={handleYesPress} onNoPress={handleNoPress} />
    );
  };
  
export default SelfLoveChallengingBehaviorScreen;