// import React, { useState, useContext, useEffect } from 'react';
// import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import TopNav from './TopNav';
// import BottomNav from './BottomNav';
// import { useNavigation } from "@react-navigation/native";
// import { AppContext } from '../../App';

// const CustomCheckbox = ({ checked }) => (
//   <Icon 
//     name={checked ? "checkbox-marked" : "checkbox-blank-outline"} 
//     size={24} 
//     color={checked ? "#64B5F6" : "#e0e0e0"}
//   />
// );

// export default function BehaviorScreen() {
//   const navigation = useNavigation();
//   const { selectedBehaviors, setSelectedBehaviors} = useContext(AppContext)
//   const [behaviors, setBehaviors] = useState([
//     { id: '1', label: 'Hurting others', checked: false },
//     { id: '2', label: 'Hurting self', checked: false },
//     { id: '3', label: 'Throwing/damaging objects or property', checked: false },
//     { id: '4', label: 'Inappropriate communication', checked: false },
//     { id: '5', label: 'Tantrums', checked: false },
//     { id: '6', label: 'Refusing to follow directions', checked: false },
//     { id: '7', label: 'Other', checked: false },
//   ]);

//     // Initialize behaviors based on selectedBehaviors
//     useEffect(() => {
//       if (selectedBehaviors.length > 0) {
//         const updatedBehaviors = behaviors.map(behavior => ({
//           ...behavior,
//           checked: selectedBehaviors.some(selected => selected.id === behavior.id),
//         }));
//         setBehaviors(updatedBehaviors);
//       }
//     }, []); // Run only once on component mount

//       // Update selectedBehaviors whenever behaviors change
//   useEffect(() => {
//     const newSelectedBehaviors = behaviors.filter(behavior => behavior.checked);
//     setSelectedBehaviors(newSelectedBehaviors);
//   }, [behaviors]);

//   const toggleCheckbox = (id) => {
//     setBehaviors(behaviors.map(item =>
//       item.id === id ? { ...item, checked: !item.checked } : item
//     ));
//   };

//   const handleSubmit = () => {
//     const selectedBehaviors = behaviors.filter(b => b.checked);
//     console.log('Selected behaviors:', selectedBehaviors);
//     navigation.navigate('DataBehavior')
//   };

//   return (
//     <SafeAreaView style={styles.container}>
//       <TopNav />
      
//       <View style={styles.headerContainer}>
//         <Text style={styles.headerText}>Behavior</Text>
//       </View>

//       <ScrollView style={styles.content}>
//         <Text style={styles.instruction}>
//           Select one or two options, then hit submit
//         </Text>

//         {behaviors.map((item) => (
//           <TouchableOpacity
//             key={item.id}
//             style={styles.checkboxContainer}
//             onPress={() => toggleCheckbox(item.id)}
//           >
//             <CustomCheckbox checked={item.checked} />
//             <Text style={styles.checkboxLabel}>{item.label}</Text>
//           </TouchableOpacity>
//         ))}
        
//         <TouchableOpacity 
//           style={styles.submitButton}
//           onPress={handleSubmit}
//         >
//           <Text onPress={() => navigation.goBack()} style={styles.submitButtonText}>Submit</Text>
//         </TouchableOpacity>
//       </ScrollView>

//       <BottomNav />
//     </SafeAreaView>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//   },
//   headerContainer: {
//     backgroundColor: '#e5e5e5',
//     padding: 15,
//     alignItems: 'center',
//     borderBottomWidth: 1,
//     borderBottomColor: '#e0e0e0',
//   },
//   headerText: {
//     fontSize: 20,
//     color: '#666',
//     fontFamily: 'chewy-regular',

//   },
//   content: {
//     flex: 1,
//     padding: 20,
//   },
//   instruction: {
//     fontSize: 16,
//     color: '#333',
//     marginBottom: 20,
//     fontFamily: 'System',
//   },
//   checkboxContainer: {
//     flexDirection: 'row',
//     alignItems: 'center',
//     padding: 15,
//     backgroundColor: '#f5f5f5',
//     borderRadius: 8,
//     marginBottom: 10,
//     borderWidth: 1,
//     borderColor: '#e0e0e0',
//   },
//   checkboxLabel: {
//     flex: 1,
//     marginLeft: 10,
//     fontSize: 16,
//     color: '#333',
//     fontFamily: 'System',
//   },
//   submitButton: {
//     backgroundColor: '#64B5F6',
//     padding: 15,
//     borderRadius: 8,
//     alignItems: 'center',
//     marginVertical: 20,
//     width: "20%",
//     alignSelf: "center"
//   },
//   submitButtonText: {
//     color: '#fff',
//     fontSize: 16,
//     fontWeight: '600',
//     fontFamily: 'System',
//   },
// });


import React, { useState, useContext, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, SafeAreaView } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useNavigation } from "@react-navigation/native";
import { AppContext } from '../../App';

const CustomCheckbox = ({ checked, disabled }) => (
  <Icon 
    name={checked ? "checkbox-marked" : "checkbox-blank-outline"} 
    size={24} 
    color={disabled && !checked ? "#cccccc" : checked ? "#64B5F6" : "#e0e0e0"}
  />
);

export default function BehaviorScreen() {
  const navigation = useNavigation();
  const { selectedBehaviors, setSelectedBehaviors } = useContext(AppContext);
  const [totalSelected, setTotalSelected] = useState(0);
  
  const [behaviors, setBehaviors] = useState([
    { id: '1', label: 'Hurting others', checked: false },
    { id: '2', label: 'Hurting self', checked: false },
    { id: '3', label: 'Throwing/damaging objects or property', checked: false },
    { id: '4', label: 'Inappropriate communication', checked: false },
    { id: '5', label: 'Tantrums', checked: false },
    { id: '6', label: 'Refusing to follow directions', checked: false },
    { id: '7', label: 'Other', checked: false },
  ]);

  // Initialize behaviors based on selectedBehaviors
  useEffect(() => {
    if (selectedBehaviors.length > 0) {
      let count = 0;
      const updatedBehaviors = behaviors.map(behavior => {
        const isChecked = selectedBehaviors.some(selected => selected.id === behavior.id);
        if (isChecked) count++;
        return {
          ...behavior,
          checked: isChecked,
        };
      });
      setBehaviors(updatedBehaviors);
      setTotalSelected(count);
    }
  }, []); // Run only once on component mount

  // Update selectedBehaviors whenever behaviors change
  useEffect(() => {
    const newSelectedBehaviors = behaviors.filter(behavior => behavior.checked);
    setSelectedBehaviors(newSelectedBehaviors);
  }, [behaviors]);

  const toggleCheckbox = (id) => {
    // Find the behavior being toggled
    const behavior = behaviors.find(b => b.id === id);
    
    // If behavior is already checked, we're unchecking it
    if (behavior.checked) {
      setBehaviors(behaviors.map(item =>
        item.id === id ? { ...item, checked: false } : item
      ));
      setTotalSelected(totalSelected - 1);
    } 
    // If behavior is not checked and we haven't reached the limit, check it
    else if (totalSelected < 2) {
      setBehaviors(behaviors.map(item =>
        item.id === id ? { ...item, checked: true } : item
      ));
      setTotalSelected(totalSelected + 1);
    }
    // If we've reached the limit and trying to check a new behavior, don't allow it
  };

  const handleSubmit = () => {
    const selectedBehaviors = behaviors.filter(b => b.checked);
    console.log('Selected behaviors:', selectedBehaviors);
    navigation.navigate('DataBehavior');
  };

  return (
    <SafeAreaView style={styles.container}>
      <TopNav />
      
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>Behavior</Text>
      </View>

      <ScrollView style={styles.content}>
        <Text style={styles.instruction}>
          Select up to two options, then hit submit
        </Text>

        {behaviors.map((item) => {
          const isDisabled = totalSelected >= 2 && !item.checked;
          
          return (
            <TouchableOpacity
              key={item.id}
              style={[
                styles.checkboxContainer,
                isDisabled && styles.disabledCheckbox
              ]}
              onPress={() => !isDisabled && toggleCheckbox(item.id)}
              disabled={isDisabled}
            >
              <CustomCheckbox checked={item.checked} disabled={isDisabled} />
              <Text style={[
                styles.checkboxLabel,
                isDisabled && styles.disabledText
              ]}>
                {item.label}
              </Text>
            </TouchableOpacity>
          );
        })}
        
        <TouchableOpacity 
          style={styles.submitButton}
          onPress={handleSubmit}
        >
          <Text onPress={() => navigation.goBack()} style={styles.submitButtonText}>Submit</Text>
        </TouchableOpacity>
      </ScrollView>

      <BottomNav />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  headerContainer: {
    backgroundColor: '#e5e5e5',
    padding: 15,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerText: {
    fontSize: 20,
    color: '#666',
    fontFamily: 'chewy-regular',
  },
  content: {
    flex: 1,
    padding: 20,
  },
  instruction: {
    fontSize: 16,
    color: '#333',
    marginBottom: 20,
    fontFamily: 'System',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#e0e0e0',
  },
  disabledCheckbox: {
    backgroundColor: '#e0e0e0',
    opacity: 0.7,
  },
  checkboxLabel: {
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
    color: '#333',
    fontFamily: 'System',
  },
  disabledText: {
    color: '#999',
  },
  submitButton: {
    backgroundColor: '#64B5F6',
    padding: 15,
    borderRadius: 8,
    alignItems: 'center',
    marginVertical: 20,
    width: "20%",
    alignSelf: "center"
  },
  submitButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
    fontFamily: 'System',
  },
});