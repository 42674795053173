import React from 'react';
import ContentPage from './PracticeScreenComponent';
import { useNavigation } from "@react-navigation/native";

const StayPositiveScreenOne = () => {
  const navigation = useNavigation();
  const headerText = "Focusing on the Positive";
  const paragraphs = [
    "Children want and value your attention – even when they don't know how to tell you.",
    "Spending quality time with your child for even a few minutes every day can really strengthen your relationship!",
    "You can play with toys, read a book, go for a walk, play with the dog, sing a song… anything you both enjoy!",
    "You can also express your affection in small words or gestures throughout the day. Tell your child how much you love them or that you're proud of them. Ask for a hug, or give them a high five.",
    "Now that you have some rules in place, it’s also a great time to try to catch your child following the rules.",
    "Telling your child what they are doing well helps them know what to do in the future! You will impact their behavior and also set the standard for how they will talk to themselves.",
    "Your positive attention and encouragement increases their confidence and resilience!",
    "Here are some resources to guide you in focusing on the positive:"
  ];
  const buttons = {
    "Keeping it Positive": "selfCareLink",
    "Positive Descriptive Feedback": "hotButtonsLink",
    "Quality Time": "stayCalmLink",
     "Importance of Language": "stayCalmLink"
  };
  const onPracticeButtonPress = () => {
    navigation.navigate("StayPositiveScreenTwo")
    // Navigate to the next screen or perform an action
    console.log("Practice button pressed");
  };

  return (
    <ContentPage
      headerText={headerText}
      paragraphs={paragraphs}
      buttons={buttons}
      onPracticeButtonPress={onPracticeButtonPress}
    />
  );
};

export default StayPositiveScreenOne;